import { Box, Container, Paper, Typography, useTheme } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell, Legend,
  Pie,
  PieChart, Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { capitalizeFirstLetter } from "../../common/util/formatter";

const pieChartSize = 360;

export const StatusPieChart = ({ devices }) => {
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState([]);

  useLayoutEffect(() => {
    setData(Object.values(devices));
  }, [devices]);

  useLayoutEffect(() => {
    setConnectionStatus(
      [
        {
          name: "Online",
          value: data.filter((device) => device.status == "online").length,
          fill: theme.palette.colors.chartsOnline,
        },
        {
          name: "Offline",
          value: data.filter((device) => device.status == "offline").length,
          fill: theme.palette.colors.chartsOffline,
        },
      ].sort((a, b) => b.value - a.value)
    );
  }, [data]);

  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Container fixed>
        <Typography variant="h6" gutterBottom={false} mb={0} component="div">
          Connection State
        </Typography>
        <PieChart width={pieChartSize} height={pieChartSize}>
          <Pie
            dataKey="value"
            data={connectionStatus}
            cx="50%"
            cy="50%"
            label={true}
          >
            <Cell key="cell-Online" />
            <Cell key="cell-Offline" />
          </Pie>
          <Tooltip />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            horizontalAlign="right"
            payload={connectionStatus.map((item, index) => ({
              id: item.name,
              type: "square",
              value: `${item.name} (${item.value})`,
              color: item.fill,
            }))}
          />
        </PieChart>
      </Container>
    </Box>
  );
};

export const MotionPieChart = ({ devices }) => {
  const theme = useTheme();

  const positionItems = useSelector((state) => state.positions.items);

  const [motions, setMotions] = useState([]);

  useLayoutEffect(() => {
    const positions = Object.values(devices).map(
      (item) => positionItems[item.id]
    );

    const inMotion = {
      name: "Moving",
      value: Object.values(positions).filter(
        (position) =>
          position &&
          position.attributes.hasOwnProperty("motion") &&
          position.attributes.motion
      ).length,
      fill: theme.palette.colors.positive,
    };

    const standby = {
      name: "Standby",
      value: Object.values(positions).filter(
        (position) =>
          position &&
          position.attributes.hasOwnProperty("motion") &&
          !position.attributes.motion
      ).length,
      fill: theme.palette.colors.neutral,
    };

    const unknown = {
      name: "Unknown",
      value: Object.values(positions).length - (inMotion.value + standby.value),
      fill: theme.palette.colors.negative,
    };
    setMotions([inMotion, standby, unknown].sort((a, b) => b.value - a.value));
  }, [positionItems]);

  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Container fixed>
        <Typography variant="h6" gutterBottom={false} mb={0} component="div">
          Motion
        </Typography>
        <PieChart width={pieChartSize} height={pieChartSize - 40}>
          <Pie dataKey="value" data={motions} cx="50%" cy="50%" label={true}>
            <Cell key="cell-moving" />
            <Cell key="cell-standby" />
            <Cell key="cell-unknown" />
          </Pie>
          <Tooltip />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            horizontalAlign="right"
            payload={motions.map((item, index) => ({
              id: item.name,
              type: "square",
              value: `${item.name} (${item.value})`,
              color: item.fill,
            }))}
          />
        </PieChart>
      </Container>
    </Box>
  );
};

export const DevicesByCategoriesPieChart = ({ devices }) => {
  const [colors] = useState([
    "#8884d8",
    "#83a6ed",
    "#8dd1e1",
    "#82ca9d",
    "#a4de6c",
    "#d0ed57",
    "#ffc658",
  ]);
  const [deviceCount, setDeviceCount] = useState([]);
  const [total, setTotal] = useState(0);

  useLayoutEffect(() => {
    const tempCount = {};
    let total = 0;
    Object.values(devices).map((device) => {
      const category = device.category ? device.category : "unknown";
      let count = tempCount[category];
      total += 1;
      if (count) {
        count += 1;
      } else {
        count = 1;
      }

      tempCount[category] = count;
      const bundleArray = [];
      Object.keys(tempCount).map((key, i) =>
        bundleArray.push({
          name: `${capitalizeFirstLetter(key)}`,
          value: tempCount[key],
          fill: colors[i % colors.length],
        })
      );
      setTotal(total);
      setDeviceCount([...bundleArray.sort((a, b) => b.value - a.value)]);
    });
  }, [devices]);

  return (
    <Box sx={{ p: 1 }}>
      <Container fixed>
        <Typography variant="h6" gutterBottom={false} mb={0} component="div">
          Installed Asset Categories
        </Typography>
        <PieChart width={pieChartSize} height={pieChartSize}>
          <Pie
            dataKey="value"
            data={deviceCount}
            cx="45%"
            cy="50%"
            paddingAngle={1}
            // fill="#8884d8"
            outerRadius={110}
            label
          />
          <Tooltip />
          <Legend
            payload={[...deviceCount, { name: "Total", value: total }].map(
              (item, index) => ({
                id: item.name,
                type: "circle",
                value: `${item.name} (${item.value})`,
                color: item.fill,
              })
            )}
            layout="vertical"
            align="right"
            verticalAlign="middle"
            horizontalAlign="right"
          />
        </PieChart>
      </Container>
    </Box>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper variant="outlined" sx={{mx: 2}}>
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </Paper>
    );
  }

  return null;
};

export const DeviceByGroupsPieChart = ({ devices, groups }) => {
  const [colors] = useState([
    "#8884d8",
    "#83a6ed",
    "#8dd1e1",
    "#82ca9d",
    "#a4de6c",
    "#d0ed57",
    "#ffc658",
  ]);
  const [deviceCount, setDeviceCount] = useState([]);
  const [total, setTotal] = useState(0);

  useLayoutEffect(() => {
    const tempCount = {};
    let total = 0;
    Object.values(devices).map((device) => {
      const groupId = device.groupId ? device.groupId : -1;
      let count = tempCount[groupId];
      total += 1;
      if (count) {
        count += 1;
      } else {
        count = 1;
      }

      tempCount[groupId] = count;
      const bundleArray = [];
      Object.keys(tempCount).map((key, i) =>
        bundleArray.push({
          name: groups && groups[key] ? groups[key].name : "Unknown",
          value: tempCount[key],
          fill: colors[i % colors.length],
        })
      );
      setTotal(total);
      setDeviceCount([...bundleArray.sort((a, b) => b.value - a.value)]);
    });
  }, [devices]);

  return (
    <Box sx={{ p: 1 }}>
      <Container fixed>
        <Typography variant="h6" gutterBottom={false} mb={0} component="div">
          Devices By Zones
        </Typography>
        <BarChart
          width={pieChartSize * 2}
          height={pieChartSize}
          data={deviceCount}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" hide />
          <Bar dataKey="value" />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            payload={[...deviceCount].map(
              (item, index) => ({
                id: item.name,
                type: "circle",
                value: `${item.name} (${item.value})`,
                color: item.fill,
              })
            )}
            layout="vertical"
            align="right"
            verticalAlign="middle"
            horizontalAlign="right"
          />
        </BarChart>
      </Container>
    </Box>
  );
};

export const DeviceByGroupsPieChartVertical = ({ devices, groups }) => {
  const [colors] = useState([
    "#8884d8",
    "#83a6ed",
    "#8dd1e1",
    "#82ca9d",
    "#a4de6c",
    "#d0ed57",
    "#ffc658",
  ]);
  const [deviceCount, setDeviceCount] = useState([]);
  const [total, setTotal] = useState(0);

  useLayoutEffect(() => {
    const tempCount = {};
    let total = 0;
    Object.values(devices).map((device) => {
      const groupId = device.groupId ? device.groupId : -1;
      let count = tempCount[groupId];
      total += 1;
      if (count) {
        count += 1;
      } else {
        count = 1;
      }

      tempCount[groupId] = count;
      const bundleArray = [];
      Object.keys(tempCount).map((key, i) =>
        bundleArray.push({
          name: groups && groups[key] ? groups[key].name : "Unknown",
          value: tempCount[key],
          fill: colors[i % colors.length],
        })
      );
      setTotal(total);
      setDeviceCount([...bundleArray.sort((a, b) => b.value - a.value)]);
    });
  }, [devices]);

  return (
    <Box sx={{ p: 1 }}>
      <Container fixed>
        <Typography variant="h6" gutterBottom={false} mb={0} component="div">
          Number of Installations By Zone
        </Typography>
        <BarChart
          width={pieChartSize * 2}
          height={pieChartSize}
          data={deviceCount}
          layout="horizontal"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="category" dataKey="name" hide />
          <YAxis type="number" dataKey="value" />
          <Bar dataKey="value" />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            payload={[...deviceCount].map(
              (item, index) => ({
                id: item.name,
                type: "circle",
                value: `${item.name} (${item.value})`,
                color: item.fill,
              })
            )}
            layout="vertical"
            align="right"
            verticalAlign="middle"
            horizontalAlign="right"
          />
        </BarChart>
      </Container>
    </Box>
  );
};

import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Typography,
  Container,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffectAsync } from "../reactHelper";
import { prefixString } from "../common/util/stringUtils";
import { useTranslation } from "../common/components/LocalizationProvider";
import PositionValue from "../common/components/PositionValue";
import useMapStyles from "../map/core/useMapStyles";
import MapView from "../map/core/MapView";
import MapRoutePath from "../map/MapRoutePath";
import MapPositions from "../map/MapPositions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    overflow: "auto",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  
  MuiMenu:{
    defaultProps:{
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'center',
      },
    },
    display: 'none'
  }
}));

const MapPopUp = ({ }) => {
  const classes = useStyles();
  const mapClasses = useMapStyles();

  const { id } = useParams();

  const [item, setItem] = useState();
  const [windowSize, setWindowSize] = useState(400);

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/positions?id=${id}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          setItem(positions[0]);
        }
      } else {
        throw Error(await response.text());
      }
    }
  }, [id]);

  return (
    <div className={classes.content}>
      {item && (
        <Container
          classes={mapClasses.containerMap}
          maxWidth="sm"
          sx={{ height: windowSize, width: windowSize }}
        >
          <Paper sx={{ height: windowSize }}>
            <MapView>
              <MapRoutePath positions={[item]} />
              <MapPositions positions={[item]} />
            </MapView>
          </Paper>
        </Container>
      )}
    </div>
  );
};

export default MapPopUp;

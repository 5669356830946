import { LinearProgress, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import CachingController from "./CachingController";
import BottomMenu from "./common/components/BottomMenu";
import { PaginationProvider } from "./common/context/PaginationContext";
import theme from "./common/theme";
import SocketController from "./SocketController";
import { sessionActions } from "./store";

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: "auto",
  },
  menu: {
    zIndex: 1204,
  },
}));

const App = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  var popup = false;

  if (window.opener && window.opener !== window) {
    popup = true;
  }

  const initialized = useSelector((state) => !!state.session.user);
  const [maxIdleTime, setMaxIdleTime] = useState(5); // minutes

  const logout = async () => {
    await fetch("/api/session", { method: "DELETE" });
    navigate("/login");
    dispatch(sessionActions.updateUser(null));
  };

  const onIdle = () => {
    logout();
  };

  // useIdleTimer({ onIdle, timeout: 1000 * 60 * maxIdleTime });

  return (
    <>
      <SocketController />
      <CachingController />
      {!initialized ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.page}>
            <PaginationProvider>
              <Outlet />
            </PaginationProvider>
          </div>
          {!desktop && !popup && (
            <div className={classes.menu}>
              <BottomMenu />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default App;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  Card,
  Grid,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { positionsActions, devicesActions, sessionActions } from "./store";
import { useEffectAsync } from "./reactHelper";
import { useTranslation } from "./common/components/LocalizationProvider";
import { snackBarDurationLongMs } from "./common/util/duration";
import usePersistedState from "./common/util/usePersistedState";

import alarm from "./resources/alarm.mp3";
import { eventsActions } from "./store/events";
import useFeatures from "./common/util/useFeatures";
import { prefixString } from "./common/util/stringUtils";
import theme from "./common/theme";
import { Container } from "@mui/system";
import { NotificationsNoneOutlined } from "@mui/icons-material";

const SocketController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const authenticated = useSelector((state) => !!state.session.user);
  const devices = useSelector((state) => state.devices.items);

  const socketRef = useRef();

  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [soundEvents] = usePersistedState("soundEvents", []);
  const [soundAlarms] = usePersistedState("soundAlarms", ["sos"]);

  const features = useFeatures();

  const connectSocket = () => {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const socket = new WebSocket(
      `${protocol}//${window.location.host}/api/socket`
    );
    socketRef.current = socket;

    socket.onopen = () => {
      dispatch(sessionActions.updateSocket(true));
    };

    socket.onerror = async () => {
      dispatch(sessionActions.updateSocket(false));
      const devicesResponse = await fetch("/api/devices");
      if (devicesResponse.ok) {
        dispatch(devicesActions.update(await devicesResponse.json()));
      }
      const positionsResponse = await fetch("/api/positions", {
        headers: { "Content-Type": "application/json" },
      });
      if (positionsResponse.ok) {
        dispatch(positionsActions.update(await positionsResponse.json()));
      }
      setTimeout(() => connectSocket(), 60000);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.devices) {
        dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        dispatch(positionsActions.update(data.positions));
      }
      if (data.events) {
        if (!features.disableEvents) {
          dispatch(eventsActions.add(data.events));
        }
        setEvents(data.events);
      }
    };
  };

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch("/api/devices");
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close();
        }
      };
    }
    const response = await fetch("/api/session");
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
    } else {
      navigate("/login");
    }
    return null;
  }, [authenticated]);

  useEffect(() => {
    setNotifications(
      events.map((event) => ({
        id: event.id,
        deviceName: devices[event.deviceId] ? devices[event.deviceId].name : "",
        message: `${t(prefixString("event", event.type))}`,
        show: true,
      }))
    );
  }, [events, devices, t]);

  useEffect(() => {
    events.forEach((event) => {
      if (
        soundEvents.includes(event.type) ||
        (event.type === "alarm" && soundAlarms.includes(event.attributes.alarm))
      ) {
        new Audio(alarm).play();
      }
    });
  }, [events, soundEvents, soundAlarms]);

  return (
    <>
      {notifications.map((notification) => {
        if (desktop) {
          return (
            <Snackbar
              key={notification.id}
              open={notification.show}
              autoHideDuration={snackBarDurationLongMs}
              onClose={() =>
                setEvents(events.filter((e) => e.id !== notification.id))
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Paper
                sx={{
                  backgroundColor: "#424242",
                  color: "#ffffff",
                  borderRadius: 2,
                }}
              >
                <Container sx={{ p: 2, pb: 1 }}>
                  <Grid container>
                    <Grid sx={{ pr: 1 }}>
                      <NotificationsNoneOutlined />
                    </Grid>
                    <Grid>
                      <Typography>
                        {notification.deviceName
                          ? `${notification.deviceName}: `
                          : ""}
                        {notification.message}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Snackbar>
          );
        } else {
          return (
            <Snackbar
              key={notification.id}
              open={notification.show}
              message={`${notification.deviceName}: ${notification.message}`}
              autoHideDuration={snackBarDurationLongMs}
              onClose={() =>
                setEvents(events.filter((e) => e.id !== notification.id))
              }
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          );
        }
      })}
    </>
  );
};

export default connect()(SocketController);

import React, { createContext, useState } from "react";

export const PaginationContext = createContext({
  refetch: false,
  setRefetch: new Function(),
  limit: 100,
  setLimit: () => {},
  pagineable: false,
  setPagineable: () => {},
  pageSize: 10,
  setPageSize: () => {},
  maximumPages: 1,
  setMaximumPages: () => {},
});

export const PaginationProvider = ({ children }) => {
  const [refetch, setRefetch] = useState(false);
  const [limit, setLimit] = useState(100);
  const [pageSize, setPageSize] = useState(10);
  const [maximumPages, setMaximumPages] = useState(1);
  const [pagineable, setPagineable] = useState(false);
  return (
    <PaginationContext.Provider
      value={{
        refetch,
        setRefetch,
        limit,
        setLimit,
        pagineable,
        setPagineable,
        pageSize,
        setPageSize,
        maximumPages,
        setMaximumPages,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

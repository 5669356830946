import React, { useMemo } from "react";
import useCommonPositionAttributes from "./useCommonPositionAttributes";
import {
  AirlineStopsOutlined,
  DirectionsWalkOutlined,
  ExploreOutlined,
  GpsFixed,
  PersonOutline,
  ScheduleOutlined,
  SpeedOutlined,
  TimelineOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";

export default (t) =>
  useMemo(
    () => ({
      ...useCommonPositionAttributes(t),
      speed: {
        name: t("positionSpeed"),
        type: "number",
        dataType: "speed",
        property: true,
        icon: <SpeedOutlined />,
      },
      course: {
        name: t("positionCourse"),
        type: "number",
        property: true,
        icon: <ExploreOutlined />,
      },
      hours: {
        name: t("positionHours"),
        type: "number",
        dataType: "hours",
        icon: <ScheduleOutlined />,
      },
      steps: {
        name: t("positionSteps"),
        type: "number",
        icon: <DirectionsWalkOutlined />,
      },
      fuelConsumptionKmPerLitre: {
        name: t("positionFuelConsumptionKmPerLitre"),
        type: "number",
      },
      mileage: {
        name: t("positionMileage"),
        type: "number",
        dataType: "distance",
        icon: <TimelineOutlined />,
      },
      distance: {
        name: t("positionDistance"),
        type: "number",
        dataType: "distance",
        icon: <TimelineOutlined />,
      },
      totalDistance: {
        name: t("deviceTotalDistance"),
        type: "number",
        dataType: "distance",
        icon: <AirlineStopsOutlined />,
      },
      throttle: {
        name: t("positionThrottle"),
        type: "number",
      },
      motion: {
        name: t("positionMotion"),
        type: "boolean",
        icon: <TrendingUpOutlined />,
      },
      acceleration: {
        name: t("positionAcceleration"),
        type: "number",
      },
      obdSpeed: {
        name: t("positionObdSpeed"),
        type: "number",
        dataType: "speed",
      },
      obdOdometer: {
        name: t("positionObdOdometer"),
        type: "number",
        dataType: "distance",
      },
      driverUniqueId: {
        name: t("positionDriverUniqueId"),
        type: "string",
        icon: <PersonOutline />,
      },
      gpsDataAccurate: {
        name: t("positionGpsDataAccurate"),
        type: "boolean",
        icon: <GpsFixed />,
      },
    }),
    [t]
  );

import React, { useEffect, useState } from "react";
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CreateIcon from "@mui/icons-material/Create";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FolderIcon from "@mui/icons-material/Folder";
import PersonIcon from "@mui/icons-material/Person";
import StorageIcon from "@mui/icons-material/Storage";
import BuildIcon from "@mui/icons-material/Build";
import PeopleIcon from "@mui/icons-material/People";
import TodayIcon from "@mui/icons-material/Today";
import PublishIcon from "@mui/icons-material/Publish";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "../../common/components/LocalizationProvider";
import {
  useAdministrator,
  useManager,
  useReadonly,
} from "../../common/util/permissions";
import useFeatures from "../../common/util/useFeatures";
import {
  CandlestickChart,
  Category,
  ExpandLess,
  ExpandMore,
  LocalGasStation,
  Power,
} from "@mui/icons-material";

const MenuItem = ({ children, title, link, icon, selected }) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
    {children}
  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const readonly = useReadonly();
  const admin = useAdministrator();
  const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (
      location.pathname.includes("/port") ||
      location.pathname.includes("fuel-sensors/calibration") ||
      location.pathname.includes("fuel-sensors/reading")
    ) {
      setOpen(true);
    }
  }, [open]);

  return (
    <>
      <List>
        <MenuItem
          title={t("sharedPreferences")}
          link="/settings/preferences"
          icon={<SettingsIcon />}
          selected={location.pathname === "/settings/preferences"}
        />
        {!readonly && (
          <>
            <MenuItem
              title={t("sharedNotifications")}
              link="/settings/notifications"
              icon={<NotificationsIcon />}
              selected={location.pathname.startsWith("/settings/notification")}
            />
            <MenuItem
              title={t("settingsUser")}
              link={`/settings/user/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/settings/user/${userId}`}
            />
            <MenuItem
              title={t("sharedGeofences")}
              link="/geofences"
              icon={<CreateIcon />}
              selected={location.pathname.startsWith("/settings/geofence")}
            />
            {!features.disableGroups && (
              <MenuItem
                title={t("settingsGroups")}
                link="/settings/groups"
                icon={<FolderIcon />}
                selected={location.pathname.startsWith("/settings/group")}
              />
            )}
            <MenuItem
                title={t("sharedDeviceClasses")}
                link="/settings/device-classes"
                icon={<Category />}
                selected={location.pathname.startsWith("/settings/device-classes")}
              />
          </>
        )}
        {/* {!features.disableDrivers && (
          <MenuItem
            title={t("sharedDrivers")}
            link="/settings/drivers"
            icon={<PersonIcon />}
            selected={location.pathname.startsWith("/settings/driver")}
          />
        )}
        {!features.disableCalendars && (
          <MenuItem
            title={t("sharedCalendars")}
            link="/settings/calendars"
            icon={<TodayIcon />}
            selected={location.pathname.startsWith("/settings/calendar")}
          />
        )} */}
        {!readonly && (
          <>
            {!features.disableComputedAttributes && (
              <MenuItem
                title={t("sharedComputedAttributes")}
                link="/settings/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith("/settings/attribute")}
              />
            )}
            {/* {!features.disableMaintenance && (
              <MenuItem
                title={t("sharedMaintenance")}
                link="/settings/maintenances"
                icon={<BuildIcon />}
                selected={location.pathname.startsWith("/settings/maintenance")}
              />
            )} */}
            {admin && (
              <div>
                <MenuItem
                  title={t("sharedSensors")}
                  link="/settings/fuel-sensors"
                  icon={<LocalGasStation />}
                  selected={location.pathname.startsWith(
                    "/settings/fuel-sensor"
                  )}
                >
                  <IconButton onClick={handleClick}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </MenuItem>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ pl: 4 }}>
                  <MenuItem
                    title={t("sharedPorts")}
                    link="/settings/fuel-sensors/ports"
                    icon={<Power />}
                    selected={location.pathname.startsWith(
                      "/settings/fuel-sensors/port"
                    )}
                  />
                  <MenuItem
                    title={t("sharedReadings")}
                    link="/settings/fuel-sensors/readings"
                    icon={<CandlestickChart />}
                    selected={location.pathname.startsWith(
                      "/settings/fuel-sensors/reading"
                    )}
                  />
                </Collapse>
              </div>
            )}
            <MenuItem
              title={t("sharedSavedCommands")}
              link="/settings/commands"
              icon={<PublishIcon />}
              selected={location.pathname.startsWith("/settings/command")}
            />
          </>
        )}
      </List>
      {manager && (
        <>
          <Divider />
          <List>
            {admin && (
              <MenuItem
                title={t("settingsServer")}
                link="/settings/server"
                icon={<StorageIcon />}
                selected={location.pathname === "/settings/server"}
              />
            )}
            <MenuItem
              title={t("settingsUsers")}
              link="/settings/users"
              icon={<PeopleIcon />}
              selected={
                location.pathname.startsWith("/settings/user") &&
                location.pathname !== `/settings/user/${userId}`
              }
            />
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;

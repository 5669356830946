import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Card,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffectAsync } from "../reactHelper";
import { prefixString } from "../common/util/stringUtils";
import { formatBoolean } from "../common/util/formatter";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import SettingsMenu from "./components/SettingsMenu";
import CollectionFab from "./components/CollectionFab";
import CollectionActions from "./components/CollectionActions";
import TableShimmer from "../common/components/TableShimmer";

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: "1%",
    paddingRight: theme.spacing(1),
  },
}));

const NotificationsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/notifications");
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const formatList = (prefix, value) => {
    if (value) {
      return value
        .split(/[, ]+/)
        .filter(Boolean)
        .map((it) => t(prefixString(prefix, it)))
        .join(", ");
    }
    return "";
  };

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={["settingsTitle", "sharedNotifications"]}
    >
      <Card sx={{ m: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("notificationType")}</TableCell>
              <TableCell>{t("notificationAlways")}</TableCell>
              <TableCell>{t("sharedAlarms")}</TableCell>
              <TableCell>{t("notificationNotificators")}</TableCell>
              <TableCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{t(prefixString("event", item.type))}</TableCell>
                  <TableCell>{formatBoolean(item.always, t)}</TableCell>
                  <TableCell>
                    {formatList("alarm", item.attributes.alarms)}
                  </TableCell>
                  <TableCell>
                    {formatList("notificator", item.notificators)}
                  </TableCell>
                  <TableCell className={classes.columnAction} padding="none">
                    <CollectionActions
                      itemId={item.id}
                      editPath="/settings/notification"
                      endpoint="notifications"
                      setTimestamp={setTimestamp}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableShimmer columns={5} endAction />
            )}
          </TableBody>
        </Table>
      </Card>
      <CollectionFab editPath="/settings/notification" />
    </PageLayout>
  );
};

export default NotificationsPage;

import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useQuery from "../../common/attributes/useQuery";
import { useTranslation } from "../../common/components/LocalizationProvider";
import { PaginationContext } from "../../common/context/PaginationContext";
import useReportStyles from "../common/useReportStyles";

const ReportFilter = ({
  children,
  handleSubmit,
  showOnly,
  ignoreDevice,
  multiDevice,
  noReport,
  isFetching,
  noPeriod,
}) => {
  const classes = useReportStyles();
  const t = useTranslation();
  const query = useQuery();
  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const [deviceIds, setDeviceIds] = useState(
    selectedDeviceId ? [selectedDeviceId] : []
  );
  const [groupIds, setGroupIds] = useState([]);
  const [period, setPeriod] = useState("");
  const [from, setFrom] = useState(moment().subtract(1, "hour"));
  const [to, setTo] = useState(moment());

  let { refetch, setRefetch, pagineable } = useContext(PaginationContext);

  const disabled =
    !ignoreDevice && !deviceId && !deviceIds.length && !groupIds.length;

  const handleClick = (mail, json) => {
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case "today":
        selectedFrom = moment().startOf("day");
        selectedTo = moment().endOf("day");
        break;
      case "yesterday":
        selectedFrom = moment().subtract(1, "day").startOf("day");
        selectedTo = moment().subtract(1, "day").endOf("day");
        break;
      case "thisWeek":
        selectedFrom = moment().startOf("isoWeek");
        selectedTo = moment().endOf("isoWeek");
        break;
      case "previousWeek":
        selectedFrom = moment().subtract(1, "week").startOf("isoWeek");
        selectedTo = moment().subtract(1, "week").endOf("isoWeek");
        break;
      case "thisMonth":
        selectedFrom = moment().startOf("month");
        selectedTo = moment().endOf("month");
        break;
      case "previousMonth":
        selectedFrom = moment().subtract(1, "month").startOf("month");
        selectedTo = moment().subtract(1, "month").endOf("month");
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }

    const accept = json
      ? "application/json"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    handleSubmit({
      deviceId,
      deviceIds,
      groupIds,
      from: selectedFrom.toISOString(true),
      to: selectedTo.toISOString(true),
      mail,
      headers: { Accept: accept },
    });
  };

  useEffect(() => {
    if (multiDevice) {
      setDeviceIds([deviceId]);
    }
  }, [deviceId]);

  useEffect(() => {
    if (pagineable && refetch) {
      handleClick(false, true);
      setRefetch(false);
    }
  }, [refetch, setRefetch]);

  useEffect(() => {
    const period = query.get("period");
    setPeriod(period);
  }, [query]);

  useEffect(() => {
    const prefetch = JSON.parse(query.get("prefetch"));
    if (period !== "" && prefetch && deviceId) {
      handleClick(false, true);
    }
  }, [period, setPeriod]);

  return (
    <div className={classes.filter}>
      {!ignoreDevice && (
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <Autocomplete
              id="device-autocomplete"
              options={Object.values(devices)}
              key={(option) => option.id}
              getOptionLabel={(option) => option.name || option.contact}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, selectedDevice) => {
                if (selectedDevice) {
                  setDeviceId(selectedDevice.id);
                } else {
                  setDeviceId(0);
                }
              }}
              defaultValue={() => devices[selectedDeviceId]}
              renderInput={(params) => (
                <TextField {...params} label={t("sharedSearchDevices")} />
              )}
            />
          </FormControl>
        </div>
      )}
      {multiDevice && (
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <InputLabel>{t("settingsGroups")}</InputLabel>
            <Select
              label={t("settingsGroups")}
              value={groupIds}
              onChange={(e) => setGroupIds(e.target.value)}
              multiple
            >
              {Object.values(groups).map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      {!noPeriod && (
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <InputLabel>{t("reportPeriod")}</InputLabel>
            <Select
              label={t("reportPeriod")}
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
            >
              <MenuItem value="today">{t("reportToday")}</MenuItem>
              <MenuItem value="yesterday">{t("reportYesterday")}</MenuItem>
              <MenuItem value="thisWeek">{t("reportThisWeek")}</MenuItem>
              <MenuItem value="previousWeek">
                {t("reportPreviousWeek")}
              </MenuItem>
              <MenuItem value="thisMonth">{t("reportThisMonth")}</MenuItem>
              <MenuItem value="previousMonth">
                {t("reportPreviousMonth")}
              </MenuItem>
              <MenuItem value="custom">{t("reportCustom")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {period === "custom" && (
        <div className={classes.filterItem}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={t("reportFrom")}
              // type="datetime-local"
              value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
              onChange={(value) =>
                setFrom(moment(value, moment.HTML5_FMT.DATETIME_LOCAL))
              }
              renderInput={(params) => <TextField {...params} />}
              fullWidth
            />
          </LocalizationProvider>
        </div>
      )}
      {period === "custom" && (
        <div className={classes.filterItem}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={t("reportTo")}
              // type="datetime-local"
              value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
              onChange={(value) =>
                setTo(moment(value, moment.HTML5_FMT.DATETIME_LOCAL))
              }
              renderInput={(params) => <TextField {...params} />}
              fullWidth
            />
          </LocalizationProvider>
        </div>
      )}
      {children}
      <div className={classes.filterButtons}>
        <LoadingButton
          onClick={() => handleClick(false, true)}
          variant="outlined"
          color="secondary"
          className={classes.filterButton}
          disabled={disabled}
          loading={isFetching}
        >
          {t("reportShow")}
        </LoadingButton>
        {!showOnly && !noReport && (
          <Button
            onClick={() => handleClick(false, false)}
            variant="outlined"
            color="secondary"
            className={classes.filterButton}
            disabled={disabled}
          >
            {t("reportExport")}
          </Button>
        )}
        {!showOnly && !noReport && (
          <Button
            onClick={() => handleClick(true, false)}
            variant="outlined"
            color="secondary"
            className={classes.filterButton}
            disabled={disabled}
          >
            <Typography variant="button" noWrap>
              {t("reportEmail")}
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReportFilter;

import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Card,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffectAsync } from "../reactHelper";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import SettingsMenu from "./components/SettingsMenu";
import CollectionFab from "./components/CollectionFab";
import CollectionActions from "./components/CollectionActions";

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const FuelSensorsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch("/api/fuel-sensors");
    if (response.ok) {
      setItems(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, [timestamp]);

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={["settingsTitle", "sharedDrivers"]}
    >
      <Card sx={{ m: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("sensorName")}</TableCell>
              <TableCell>{t("sensorDescription")}</TableCell>
              {/* <TableCell>{t("sharedReadingType")}</TableCell> */}
              <TableCell>{t("sharedFuelConsumedPort")}</TableCell>
              <TableCell>{t("sharedFuelLevelPort")}</TableCell>
              <TableCell>{t("sharedFuelRatePort")}</TableCell>
              <TableCell>{t("sensorCalibrated")}</TableCell>
              <TableCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                {/* <TableCell>{item.readingTypeId}</TableCell> */}
                <TableCell>
                  {item.fuelConsumedPort == "" ? "" : item.fuelConsumedPort}
                </TableCell>
                <TableCell>{item.fuelLevelPort}</TableCell>
                <TableCell>
                  {item.fuelRatePort == "" ? "" : item.fuelRatePort}
                </TableCell>
                <TableCell>{item.calibrated ? "Yes" : "No"}</TableCell>
                <TableCell className={classes.columnAction} padding="none">
                  <CollectionActions
                    itemId={item.id}
                    editPath="/settings/fuel-sensor"
                    endpoint="fuel-sensors"
                    setTimestamp={setTimestamp}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <CollectionFab editPath="/settings/fuel-sensor" />
    </PageLayout>
  );
};

export default FuelSensorsPage;

import {
  Button,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close, CloseRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import React, { forwardRef, useEffect, useState } from "react";

import { AttachFile } from "@mui/icons-material";
import { DropzoneDialog } from "material-ui-dropzone";
import MaterialTable from "material-table";
import dimensions from "../../common/theme/dimensions";
import { useTranslation } from "../../common/components/LocalizationProvider";
import { useEffectAsync } from "../../reactHelper";
import { useDispatch, useSelector } from "react-redux";
import { errorsActions } from "../../store";
import Papa from "papaparse";
import FuelCalibrationGraph from "./FuelCalibrationGraph";
import tableIcons from "../../main/MateriaTableIcons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: 800,
  p: 2,
};

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  filter: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2, 2),
  },
  item: {
    flex: `1 1 ${dimensions.filterFormWidth}`,
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    flex: `1 1 ${dimensions.filterFormWidth}`,
  },
  button: {
    flexGrow: 1,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      bottom: dimensions.bottomBarHeight + theme.spacing(2),
    },
  },
}));

const FuelCalibrationModal = ({
  open,
  setOpen,
  onClose,
  deviceId,
  sensor,
  setSensor,
  index,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devices.items);

  const [selectedTab, setSelectedTab] = useState(1);
  const [oldCalibrationId, setOldCalibrationId] = useState(0);
  const [calibration, setCalibration] = useState({ calibrationEntries: [] });
  const [calibrationEntries, setCalibrationEntries] = useState([]);
  const [calibrationsUpdated, setCalibrationsUpdated] = useState(false);
  const [hiddenColumn, setHiddenColumn] = useState(true);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const columns = [
    {
      field: "fuel",
      title: "Fuel",
      type: "numeric",
      width: 40,
      sorting: true,
      align: "center",
    },
    {
      field: "voltage",
      title: "Voltage",
      type: "numeric",
      width: 40,
      sorting: true,
      align: "center",
    },
    {
      field: "slope",
      title: "m",
      type: "numeric",
      width: 40,
      sorting: false,
      align: "center",
      export: false,
      hidden: hiddenColumn,
    },
    {
      field: "intercept",
      title: "c",
      type: "numeric",
      width: 40,
      sorting: false,
      align: "center",
      export: false,
      hidden: hiddenColumn,
    },
  ];

  const handleTabSwitch = (event, value) => {
    setSelectedTab(value);
  };

  const entryExists = (calibrations, entry, property) =>
    calibrations.some((obj) => obj[property] === entry[property]);
  const checkDuplicateEntries = (currentCalibrations, calibration) => {
    if (entryExists(currentCalibrations, calibration, "voltage")) {
      throw Error(
        `Voltage = ${calibration.voltage}. Voltage entries should not be repeated.`
      );
    }

    if (entryExists(currentCalibrations, calibration, "fuel")) {
      throw Error(
        `Fuel = ${calibration.fuel}. Fuel entries should not be repeated.`
      );
    }
  };

  const handleCSVUpload = (file) => {
    Papa.parse(file[0], {
      complete: (parsedData) => {
        const newCalibrations = [];
        const fuelIndex = 0;
        const voltageIndex = 1;
        var skipFirst = true;
        try {
          parsedData.data.map((data) => {
            if (!skipFirst) {
              if (
                !Number.isNaN(data[fuelIndex]) ||
                !Number.isNaN(data[voltageIndex])
              ) {
                const calibration = {
                  voltage: parseFloat(data[voltageIndex]),
                  fuel: parseFloat(data[fuelIndex]),
                };

                checkDuplicateEntries(newCalibrations, calibration);
                if (
                  calibration &&
                  calibration.voltage >= 0 &&
                  calibration.fuel >= 0
                ) {
                  newCalibrations.push(calibration);
                }
              } else {
                throw Error(
                  "Your CSV File has an error. Only header fields can be strings!"
                );
              }

              return;
            } else {
              var fuelColumnName = String(data[fuelIndex]);
              var voltageColumnName = String(data[voltageIndex]);
              if (
                !(
                  fuelColumnName.toLowerCase() == "fuel" &&
                  voltageColumnName.toLowerCase() == "voltage"
                )
              ) {
                throw Error(
                  `Incorrect data Heading. Correct heading is "Fuel, Voltage"`
                );
              }

              skipFirst = false;
            }
          });
          setCalibrationEntries([...newCalibrations]);
          setCalibrationsUpdated(true);

          if (sensor.calibrationId) {
            setOldCalibrationId(sensor.calibrationId);
          } else {
            setOldCalibrationId(0);
          }

          // deleteOldCalibration();
        } catch (error) {
          dispatch(errorsActions.push(error.message));
        }
        setUploadModalOpen(false);
        setSensor(0);
      },
      error: (error) => dispatch(errorsActions.push(error.message)),
      delimiter: ",",
      skipEmptyLines: true,
    });
  };

  const validCalibration = () =>
    sensor && sensor.calibrationId && sensor.calibrationId != 0;

  const fetchCalibrations = async () => {
    const response = await fetch(`/api/calibrations/${sensor.calibrationId}`);
    if (response.ok) {
      const data = await response.json();
      setCalibration(data);
      setCalibrationEntries(data.calibrationEntries);
    } else {
      dispatch(errorsActions.push(await response.text()));
    }
  };

  useEffect(() => {
    if (validCalibration()) {
      setHiddenColumn(false);
    }
  });

  useEffect(() => {
    if (validCalibration()) {
      fetchCalibrations();
    }
  }, [sensor]);

  const saveCalibrationChange = async () => {
    let url = `/api/calibrations/`;
    let method = "POST";
    
    const entries = [];
    calibrationEntries.map((entry) =>
      entries.push({ voltage: entry.voltage, fuel: entry.fuel })
    );
    const changedCalibration = {
      deviceId: deviceId,
      sensorId: sensor.id,
      calibrationEntries: entries,
    };

    if (calibration && calibration.id && calibration.id > 0) {
      changedCalibration["id"] = sensor.calibrationId;
      url += calibration.id;
      method = "PUT";
    }

    const response = await fetch(url, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(changedCalibration),
    });

    if (response.ok) {
      const data = await response.json();
      setCalibration(data);
      setCalibrationEntries(data.calibrationEntries);

      if (method == "POST") {
        setSensor(data.id);
      }
    } else {
      dispatch(errorsActions.push(await response.text()));
    }
    setCalibrationsUpdated(false);
  };

  const deleteOldCalibration = async () => {
    if (oldCalibrationId === 0) {
      return;
    }

    let url = `/api/calibrations/${oldCalibrationId}`;
    let method = "DELETE";

    const response = await fetch(url, {
      method: method,
    });

    if (response.ok) {
      setOldCalibrationId(0);
    } else {
      dispatch(errorsActions.push(await response.text()));
    }
  };

  return (
    (devices[deviceId] && (
      <Modal open={open} onClose={onClose}>
        <div>
          <Box sx={style}>
            <Box
              alignItems="flex-start"
              justifyContent="space-between"
              display="flex"
            >
              <Box>
                <Button
                  sx={{ mr: 2 }}
                  disabled={!calibrationsUpdated}
                  onClick={() => saveCalibrationChange()}
                  variant="contained"
                >
                  {t("sharedSave")}
                </Button>
                <Button
                  variant={"outlined"}
                  startIcon={<AttachFile />}
                  onClick={() => setUploadModalOpen(true)}
                >
                  {t("sharedReadFromCSVFile")}
                </Button>
              </Box>
              <IconButton onClick={() => setOpen(false)}>
                <CloseRounded />
              </IconButton>
            </Box>
            <Tabs value={selectedTab} onChange={handleTabSwitch}>
              <Tab value={1} label={"Calibration Entries"} />

              {validCalibration() && <Tab value={3} label={"Graph"} />}
            </Tabs>

            {(selectedTab == 1 && (
              <Box>
                {
                  <MaterialTable
                    style={{
                      padding: 2,
                    }}
                    icons={tableIcons}
                    title={`${devices[deviceId].name}`}
                    editable={{
                      onBulkUpdate: (changes) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            console.log(changes);
                            const tempCalibrationEntries = [
                              ...calibrationEntries,
                            ];
                            Object.keys(changes).map((k) => {
                              try {
                                tempCalibrationEntries[k] = {
                                  voltage: -1,
                                  fuel: -1,
                                };
                                const updatedEntry = changes[k].newData;
                                checkDuplicateEntries(
                                  tempCalibrationEntries,
                                  updatedEntry
                                );
                                tempCalibrationEntries[k] = {
                                  voltage: updatedEntry.voltage,
                                  fuel: updatedEntry.fuel,
                                };
                              } catch (error) {
                                dispatch(errorsActions.push(error.message));
                              }
                            });
                            setCalibrationEntries(tempCalibrationEntries);
                            setCalibrationsUpdated(true);
                            resolve();
                          }, 1000);
                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                          const entry = {
                            voltage: newData.voltage,
                            fuel: newData.fuel,
                          };
                          try {
                            checkDuplicateEntries(calibrationEntries, entry);
                            setCalibrationEntries([
                              ...calibrationEntries,
                              entry,
                            ]);
                            setCalibrationsUpdated(true);
                          } catch (error) {
                            dispatch(errorsActions.push(error.message));
                            reject();
                          }
                          resolve();
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          try {
                            const deletionIndex = calibrationEntries.findIndex(
                              (obj) => obj.voltage === oldData.voltage
                            );
                            if (deletionIndex > -1) {
                              const updatedEntries = [...calibrationEntries];
                              updatedEntries.splice(deletionIndex, 1);
                              setCalibrationEntries(updatedEntries);
                              setCalibrationsUpdated(true);
                            }
                          } catch (error) {
                            dispatch(errorsActions.push(error.message()));
                            reject();
                          }
                          resolve();
                        }),
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportAllData: true,
                      maxBodyHeight: 500,
                      exportFileName: `${devices[deviceId].name}`,
                    }}
                    columns={columns}
                    data={calibrationEntries}
                  />
                }
              </Box>
            )) || (
              <Box width={750} height={400}>
                <FuelCalibrationGraph calibrationEntries={calibrationEntries} />
              </Box>
            )}
          </Box>
          <DropzoneDialog
            open={uploadModalOpen}
            acceptedFiles={["text/csv"]}
            filesLimit={1}
            showPreviews={true}
            submitButtonText={t("sharedUpload")}
            onSave={handleCSVUpload}
            onClose={() => setUploadModalOpen(false)}
          />
        </div>
      </Modal>
    )) || <Typography></Typography>
  );
};

export default FuelCalibrationModal;

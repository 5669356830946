import React, { useState } from "react";

import {
  Assessment,
  AssistantDirection,
  BarChart,
  Language,
  Logout,
  Menu,
  Person,
  PersonOutline,
  Settings,
  Terrain,
  Workspaces,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ScopedCssBaseline,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "./LocalizationProvider";

const useStyles = makeStyles((theme) => ({
  drawer: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    /* width: `calc(${theme.spacing(0)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    }, */
  },
  drawerCollapsed: {
    width: drawerMenuWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
}));

const drawerMenuWidth = 240;

const openedMixin = (theme) => ({
  width: drawerMenuWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 100,
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-left",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomListItem = ({ item, open, navigate, selected }) => {
  return (
    <Tooltip title={item.title} placement={"right"}>
      <ListItem
        key={item.title}
        disablePadding
        sx={{ display: "block" }}
        onClick={() => (item?.action ? item?.action() : navigate(item.url))}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          selected={selected}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>        
          <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} secondary={item?.subtitle} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

const MENU_ITEMS = [
  {
    title: "Dashboard",
    url: "/",
    icon: <BarChart />,
    readonly: false,
  },
  {
    title: "Monitoring",
    url: "/monitoring",
    icon: <Language />,
    readonly: false,
  },
  {
    title: "Tracks",
    url: "/replay",
    icon: <AssistantDirection />,
    readonly: false,
  },
  {
    title: "Reports",
    url: "/reports/summary",
    icon: <Assessment />,
    readonly: false,
  },
  {
    title: "Geofences",
    url: "/geofences",
    icon: <Terrain />,
    readonly: false,
  },
  {
    title: "Users",
    url: "/settings/users",
    icon: <Person />,
    readonly: true,
  },
  {
    title: "Zones",
    url: "/settings/groups",
    icon: <Workspaces />,
    readonly: true,
  },
];

const CollapsibleDrawer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const t = useTranslation();

  const user = useSelector((state) => state.session.user);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAccount = () => {
    setAnchorEl(null);
    navigate(`/settings/user/${user?.id}`);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await fetch("/api/session", { method: "DELETE" });
    navigate("/login");
    dispatch(sessionActions.updateUser(null));
  };

  const handleDrawerToggle = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const selected = (url) => {
    if (url === "/") {
      return location.pathname === "/";
    } else {
      return location.pathname.startsWith(url);
    }
  };

  const ACCOUNT_ITEMS = [
    {
      title: "Settings",
      url: "/settings/preferences",
      icon: <Settings />,
      readonly: false,
    },
    {
      title: `${user?.name}`,
      action: handleAccount,
      icon: <PersonOutline />,
      readonly: true,
      subtitle: "Account"
    },
    {
      title: "Logout",
      action: handleLogout,
      icon: <Logout />,
      readonly: false,
    },
  ];

  return (
    <ScopedCssBaseline>
      <CustomDrawer
        variant="permanent"
        open={open}
        anchor="left"
        theme={theme}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DrawerHeader>
          <ListItem
            key={"headerButton"}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Menu />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="h6">TPDF</Typography>}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </DrawerHeader>
        <Box>
          <Divider />
          <List>
            {MENU_ITEMS.map(
              (item, index) =>
                !item.readonly && (
                  <CustomListItem
                    item={item}
                    open={open}
                    key={index}
                    navigate={navigate}
                    selected={selected(item.url)}
                  />
                )
            )}
            <Divider />
            {ACCOUNT_ITEMS.map((item, index) => (
              <CustomListItem
                item={item}
                navigate={navigate}
                open={open}
                key={index}
              />
            ))}
          </List>
        </Box>
      </CustomDrawer>
    </ScopedCssBaseline>
  );
};

export default CollapsibleDrawer;

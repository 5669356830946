import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { map } from "./core/MapView";
import { getDeviceStatus, isGPSPositionValid } from "../common/util/formatter";
import usePersistedState from "../common/util/usePersistedState";

const MapPositions = ({ positions, onClick, showStatus }) => {
  const id = "positions";
  const clusters = `${id}-clusters`;
  const direction = `${id}-direction`;

  const devices = useSelector((state) => state.devices.items);

  const [mapCluster] = usePersistedState("mapCluster", true);

  const [timeDiffLimit] = usePersistedState("timeDiffLimit", 5);

  const createFeature = (devices, position) => {
    const device = devices[position.deviceId];
    const status = getDeviceStatus(device, timeDiffLimit);

    return {
      id: position.id,
      deviceId: position.deviceId,
      name: device.name,
      category: device.category || "default",
      color: showStatus ? status.color : "neutral",
      rotation: position.course,
      direction: true,
    };
  };

  const onMouseEnter = () => (map.getCanvas().style.cursor = "pointer");
  const onMouseLeave = () => (map.getCanvas().style.cursor = "");

  const onMarkerClick = useCallback(
    (event) => {
      const feature = event.features[0];
      if (onClick) {
        onClick(feature.properties.id, feature.properties.deviceId);
      }
    },
    [onClick]
  );

  const onClusterClick = useCallback(
    (event) => {
      const features = map.queryRenderedFeatures(event.point, {
        layers: [clusters],
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (!error) {
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        }
      });
    },
    [clusters]
  );

  useEffect(() => {
    map.addSource(id, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: mapCluster,
      clusterMaxZoom: 24,
      clusterRadius: 50,
    });
    map.addLayer({
      id,
      type: "symbol",
      source: id,
      filter: ["!", ["has", "point_count"]],
      layout: {
        "icon-image": "{category}-{color}",
        "icon-allow-overlap": false,
        "text-field": "{name}",
        "text-allow-overlap": false,
        "text-anchor": "bottom",
        "text-offset": [0, -2],
        "text-font": ["Roboto Regular"],
        "text-size": 12,
      },
      paint: {
        "text-halo-color": "white",
        "text-halo-width": 1,
      },
    });
    map.addLayer({
      id: clusters,
      type: "symbol",
      source: id,
      filter: ["has", "point_count"],
      layout: {
        "icon-image": "background",
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Roboto Regular"],
        "text-size": 14,
      },
    });
    // map.addLayer({
    //   id: direction,
    //   type: 'symbol',
    //   source: id,
    //   filter: [
    //     'all',
    //     ['!has', 'point_count'],
    //     ['==', 'direction', true],
    //   ],
    //   layout: {
    //     'icon-image': 'direction',
    //     'icon-size': 1 /* iconScale */,
    //     'icon-allow-overlap': true,
    //     'icon-rotate': ['get', 'rotation'],
    //     'icon-rotation-alignment': 'map',
    //   },
    // });

    map.on("mouseenter", id, onMouseEnter);
    map.on("mouseleave", id, onMouseLeave);
    map.on("mouseenter", clusters, onMouseEnter);
    map.on("mouseleave", clusters, onMouseLeave);
    map.on("click", id, onMarkerClick);
    map.on("click", clusters, onClusterClick);

    return () => {
      map.off("mouseenter", id, onMouseEnter);
      map.off("mouseleave", id, onMouseLeave);
      map.off("mouseenter", clusters, onMouseEnter);
      map.off("mouseleave", clusters, onMouseLeave);
      map.off("click", id, onMarkerClick);
      map.off("click", clusters, onClusterClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(clusters)) {
        map.removeLayer(clusters);
      }
      // if (map.getLayer(direction)) {
      //   map.removeLayer(direction);
      // }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [mapCluster, clusters, direction, onMarkerClick, onClusterClick]);

  useEffect(() => {
    map.getSource(id).setData({
      type: "FeatureCollection",
      features: positions
        .filter(
          (it) => devices.hasOwnProperty(it.deviceId) && isGPSPositionValid(it)
        )
        .map((position) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [position.longitude, position.latitude],
          },
          properties: createFeature(devices, position),
        })),
    });
  }, [devices, positions]);

  return null;
};

export default MapPositions;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Toolbar,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListIcon from "@mui/icons-material/ViewList";
import TuneIcon from "@mui/icons-material/Tune";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MapView from "../map/core/MapView";
import MapSelectedDevice from "../map/main/MapSelectedDevice";
import MapAccuracy from "../map/main/MapAccuracy";
import MapGeofence from "../map/main/MapGeofence";
import MapCurrentLocation from "../map/MapCurrentLocation";
import BottomMenu from "../common/components/BottomMenu";
import { useTranslation } from "../common/components/LocalizationProvider";
import PoiMap from "../map/main/PoiMap";
import MapPadding from "../map/MapPadding";
import StatusCard from "./StatusCard";
import { devicesActions } from "../store";
import MapDefaultCamera from "../map/main/MapDefaultCamera";
import usePersistedState from "../common/util/usePersistedState";
import MapLiveRoutes from "../map/main/MapLiveRoutes";
import { useDeviceReadonly } from "../common/util/permissions";
import MapPositions from "../map/MapPositions";
import MapDirection from "../map/MapDirection";
import MapOverlay from "../map/overlay/MapOverlay";
import MapGeocoder from "../map/geocoder/MapGeocoder";
import MapScale from "../map/MapScale";
import MapNotification from "../map/notification/MapNotification";
import EventsDrawer from "./EventsDrawer";
import useFeatures from "../common/util/useFeatures";
import TopBar from "../common/components/TopBar";
import DevicesGrid from "./DevicesGrid";
import deviceCategories from "../common/util/deviceCategories";
import CollapsibleDrawer from "../common/components/CollapsibleDrawer";
import useStatusAttributes from "../common/attributes/useStatusAttributes";
import { Clear } from "@mui/icons-material";
import StatusCardTabbed from "./StatusCardTabbed";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  navbar: {
    //backgroundColor:"blue !important",
    minHeight: "40px",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: `calc(${theme.spacing(7)} + 10px)`,
    // left: 10,
    top: 10,
    // top: 50,
    zIndex: 3,
    margin: theme.spacing(0),
    width: theme.dimensions.drawerWidthCustom,
    bottom: 10,
    transition: "transform .5s ease",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
      left: 0,
      top: 0,
      bottom: theme.dimensions.bottomBarHeight,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-(${theme.dimensions.drawerWidthCustom} + 13))`,
    marginLeft: 0,
    // [theme.breakpoints.down("md")]: {
    transform: "translateX(-100vw)",
    // },
  },
  toolbarContainer: {
    zIndex: 4,
  },
  toolbar: {
    display: "flex",
    padding: theme.spacing(0, 1),
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  statusCard: {
    position: "fixed",
    zIndex: 5,
    bottom: theme.spacing(3),
    // width: 500,
    [theme.breakpoints.up("sm")]: {
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
    },
    [theme.breakpoints.down("md")]: {
      left: "50%",
      bottom: theme.spacing(10),
    },
    transform: "translateX(-50%)",
  },
  sidebarToggle: {
    position: "absolute",
    left: `calc(${theme.spacing(7)} + 10px)`,
    // left: 10,
    // top: 50,
    top: 10,
    borderRadius: "0px",
    minWidth: 0,
    [theme.breakpoints.down("md")]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  sidebarToggleBg: {
    backgroundColor: "white",
    color: "#777777",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  bottomMenu: {
    position: "fixed",
    left: theme.spacing(1.5),
    bottom: theme.spacing(5),
    zIndex: 4,
    width: theme.dimensions.drawerWidthCustom,
  },
  filterPanel: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();

  const deviceReadonly = useDeviceReadonly();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const phone = useMediaQuery(theme.breakpoints.down("sm"));

  const statuses = useStatusAttributes(t);

  const features = useFeatures();

  const [mapMapOnSelect] = usePersistedState("mapOnSelect", false);

  const [mapLiveRoutes] = usePersistedState("mapLiveRoutes", false);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.positions.items);
  const [filteredPositions, setFilteredPositions] = useState([]);

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const [filteredDevices, setFilteredDevices] = useState([]);

  const [filterKeyword, setFilterKeyword] = usePersistedState(
    "filterKeyword",
    ""
  );
  const [filterStatuses, setFilterStatuses] = usePersistedState(
    "filterStatus",
    []
  );
  const [filterGroups, setFilterGroups] = usePersistedState("filterGroups", []);
  const [filterCategories, setFilterCategories] = usePersistedState(
    "filterCategories",
    []
  );
  const [filterSort, setFilterSort] = usePersistedState("filterSort", "");
  const [filterMap, setFilterMap] = usePersistedState("filterMap", true);
  const [navFromToken, setNavFromToken] = usePersistedState(
    "navFromToken",
    false
  );

  const filterRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [devicesOpen, setDevicesOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);

  const eventHandler = useCallback(() => setEventsOpen(true), [setEventsOpen]);
  const eventsAvailable = useSelector((state) => !!state.events.items.length);

  const handleClose = () => {
    setDevicesOpen(!devicesOpen);
  };

  useEffect(() => setDevicesOpen(desktop), [desktop]);

  useEffect(() => {
    if (!desktop && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapMapOnSelect, selectedDeviceId]);

  useEffect(() => {
    if (selectedDeviceId) {
      setFilterKeyword(devices[selectedDeviceId]?.name);
    }
  }, [selectedDeviceId]);

  const onClick = useCallback(
    (_, deviceId) => {
      dispatch(devicesActions.select(deviceId));
    },
    [dispatch]
  );

  useEffect(() => {
    const filtered = Object.values(devices)
      .filter(
        (device) =>
          !filterStatuses.length ||
          filterStatuses.find((status) => {
            const filterItem = statuses[status];
            const lastUpdateTime = device?.lastPositionUpdate
              ? device?.lastPositionUpdate
              : device?.lastUpdate;
            const isInRange = filterItem.isInRange(lastUpdateTime);

            return isInRange;
          }) /*  includes(device.status) */
      )
      .filter(
        (device) =>
          !filterGroups.length || filterGroups.includes(device.groupId)
      )
      .filter(
        (device) =>
          !filterCategories.length || filterCategories.includes(device.category)
      )
      .filter((device) =>
        `${device.name} ${device.uniqueId}`
          .toLowerCase()
          .includes(filterKeyword.toLowerCase())
      );
    if (filterSort === "lastUpdate") {
      filtered.sort((device1, device2) => {
        const time1 = device1.lastPositionUpdate
          ? moment(device1.lastPositionUpdate).valueOf()
          : 0;
        const time2 = device2.lastPositionUpdate
          ? moment(device2.lastPositionUpdate).valueOf()
          : 0;
        return time2 - time1;
      });
    } else if (filterSort === "deviceName") {
      filtered.sort((device1, device2) => {
        const name1 = device1.name.toUpperCase();
        const name2 = device2.name.toUpperCase();

        if (name2 < name1) {
          return -1;
        }

        if (name2 > name1) {
          return 1;
        }

        return 0;
      });
    }
    setFilteredDevices(filtered);
    setFilteredPositions(
      filterMap
        ? filtered.map((device) => positions[device.id]).filter(Boolean)
        : Object.values(positions)
    );
  }, [
    devices,
    positions,
    filterKeyword,
    filterStatuses,
    filterGroups,
    filterSort,
    filterMap,
    filterCategories,
  ]);

  useEffect(() => {
    if (
      filteredDevices?.length === 1 &&
      filterKeyword?.length > 0 &&
      navFromToken
    ) {
      setNavFromToken(false);
      dispatch(devicesActions.select(filteredDevices[0]?.id));
    }
  }, [filteredDevices, filterKeyword, navFromToken]);

  return (
    <div className={classes.root}>
      {/* {desktop && <TopBar />} */}
      {desktop && <CollapsibleDrawer />}
      <MapView>
        <MapOverlay />
        <MapGeofence />
        <MapAccuracy />
        {mapLiveRoutes && <MapLiveRoutes />}
        <MapPositions
          positions={filteredPositions}
          onClick={onClick}
          showStatus
        />
        {/* {selectedPosition && selectedPosition.course && (
          <MapDirection position={selectedPosition} />
        )} */}
        <MapDefaultCamera />
        <MapSelectedDevice />
        <PoiMap />
      </MapView>
      <MapScale />
      <MapCurrentLocation />
      <MapGeocoder />
      {!features.disableEvents && (
        <MapNotification enabled={eventsAvailable} onClick={eventHandler} />
      )}
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
      <Button
        variant="contained"
        color={phone ? "secondary" : "primary"}
        classes={{ containedPrimary: classes.sidebarToggleBg }}
        className={classes.sidebarToggle}
        onClick={handleClose}
        disableElevation
      >
        <ListIcon />
        <div className={classes.sidebarToggleText}>{t("deviceTitle")}</div>
      </Button>
      <Paper
        square
        elevation={3}
        className={`${classes.sidebar} ${
          !devicesOpen && classes.sidebarCollapsed
        }`}
      >
        <Paper square elevation={3} className={classes.toolbarContainer}>
          <Toolbar className={classes.toolbar} disableGutters>
            {!desktop && (
              <IconButton edge="start" sx={{ mr: 2 }} onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <OutlinedInput
              ref={filterRef}
              placeholder={t("sharedSearchDevices")}
              value={filterKeyword}
              onChange={(event) => setFilterKeyword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {filterKeyword && (
                    <IconButton
                      size="small"
                      onClick={() => setFilterKeyword("")}
                    >
                      <Clear />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    onClick={() => setFilterAnchorEl(filterRef.current)}
                  >
                    <Badge
                      color="info"
                      variant="dot"
                      invisible={
                        !filterStatuses.length &&
                        !filterGroups.length &&
                        !filterCategories.length
                      }
                    >
                      <TuneIcon fontSize="small" />
                    </Badge>
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
            <Popover
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              onClose={() => setFilterAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes.filterPanel}>
                <FormControl>
                  <InputLabel>{t("deviceLastPositionUpdate")}</InputLabel>
                  <Select
                    label={t("deviceLastPositionUpdate")}
                    value={filterStatuses}
                    onChange={(e) => setFilterStatuses(e.target.value)}
                    multiple
                  >
                    {Object.entries(statuses).map((status, index) => (
                      <MenuItem key={index} value={status[0]}>
                        {status[1].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t("settingsGroups")}</InputLabel>
                  <Select
                    label={t("settingsGroups")}
                    value={filterGroups}
                    onChange={(e) => setFilterGroups(e.target.value)}
                    multiple
                  >
                    {Object.values(groups).map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t("deviceCategory")}</InputLabel>
                  <Select
                    label={t("deviceCategory")}
                    value={filterCategories}
                    onChange={(e) => setFilterCategories(e.target.value)}
                    multiple
                  >
                    {deviceCategories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {t(
                          `category${category.replace(/^\w/, (c) =>
                            c.toUpperCase()
                          )}`
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t("sharedSortBy")}</InputLabel>
                  <Select
                    label={t("sharedSortBy")}
                    value={filterSort}
                    onChange={(e) => setFilterSort(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">{"\u00a0"}</MenuItem>
                    <MenuItem value="lastUpdate">
                      {t("deviceLastPositionUpdate")}
                    </MenuItem>
                    <MenuItem value="deviceName">{t("sharedName")}</MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterMap}
                        onChange={(e) => setFilterMap(e.target.checked)}
                      />
                    }
                    label={t("sharedFilterMap")}
                  />
                </FormGroup>
              </div>
            </Popover>
            <IconButton
              onClick={() => {
                dispatch(devicesActions.copy(null));
                navigate("/settings/device");
              }}
              disabled={deviceReadonly}
            >
              <AddIcon />
            </IconButton>
            {desktop && (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </Paper>
        <div className={classes.deviceList}>
          <DevicesGrid filteredItems={filteredDevices} />
        </div>
      </Paper>
      {!features.disableEvents && (
        <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      )}
      {selectedDeviceId && (
        <div className={classes.statusCard}>
          <StatusCardTabbed
            deviceId={selectedDeviceId}
            onClose={() => dispatch(devicesActions.select(null))}
          />
        </div>
      )}
    </div>
  );
};

export default MainPage;

import { useMemo } from "react";

export default (t) =>
  useMemo(
    () => ({
      "ui.disableLoginLanguage": {
        name: t("attributeUiDisableLoginLanguage"),
        type: "boolean",
      },
      "queues.filteringPeriod": {
        name: t("attributeQueuesFilteringPeriod"),
        type: "number",
      },
      "queues.filteringWindow": {
        name: t("attributeQueuesFilteringWindow"),
        type: "number",
      },
      "report.speedThreshold": {
        name: t("attributeReportSpeedThreshod"),
        type: "number",
      },
    }),
    [t]
  );

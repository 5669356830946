import React, { useState } from 'react';
import {
    FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatDate } from '../../common/util/formatter';
import { useFilterStyles } from './DashboardFilter';
import { prefixString } from '../../common/util/stringUtils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/util/usePersistedState';
import ColumnSelect from '../../reports/components/ColumnSelect';
import { useCatch } from '../../reactHelper';
import DashboardFilter from './DashboardFilter';

const typesArray = [
    ['deviceFuelDrop', 'eventDeviceFuelDrop'],
    ['deviceFuelRefill', 'eventDeviceFuelRefill'],
    ['alarm', 'eventAlarm'],
];
const typesMap = new Map(typesArray);

const columnsArray = [
    ['eventTime', 'positionFixTime'],
    ['type', 'sharedType'],
    ['geofenceId', 'sharedGeofence'],
    ['maintenanceId', 'sharedMaintenance'],
    ['alarm', 'positionAlarm'],
];
const columnsMap = new Map(columnsArray);

const EventsStatus = () => {
    const classes = useFilterStyles();
    const t = useTranslation();

    const geofences = useSelector((state) => state.geofences.items);
    // const deviceIds = useSelector((state) => state.dashboard.alarmsSelectedDeviceIds);
    const [deviceIds, setDeviceIds] = usePersistedState('statusDeviceIds', []);

    const [columns, setColumns] = usePersistedState('eventColumns', ['eventTime', 'type', 'alarm']);
    const [eventTypes, setEventTypes] = useState(['deviceFuelDrop', 'deviceFuelRefill']);
    const [items, setItems] = useState([]);

    const fetchDeviceEvents = async (deviceId, from, to) => {
        if (!deviceId) return;
        
        const query = new URLSearchParams({
            deviceId, from, to, mail: true,
        });
        eventTypes.forEach((it) => query.append('type', it));
        const response = await fetch(`/api/reports/events?${query.toString()}`, { Accept: 'application/json' });
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType) {
                if (contentType === 'application/json') {
                    const events = await response.json();
                    return events;
                } else {
                    window.location.assign(window.URL.createObjectURL(await response.blob()));
                }
            }
        } else {
            throw Error(await response.text());
        }
    };

    const handleSubmit = useCatch(async (deviceIds) => {
        const selectedFrom = moment().subtract(8, 'hours');
        const selectedTo = moment().endOf('day');
        const events = [];
        await deviceIds.map(
            (id) => fetchDeviceEvents(id, selectedFrom.toISOString(), selectedTo.toISOString())
                .then((evs) => console.log(evs)));
        // events.push(...evs)
        setItems(events);
    });

    const formatValue = (item, key) => {
        switch (key) {
            case 'eventTime':
                return formatDate(item[key].fixTime);
            case 'type':
                return t(prefixString('event', item[key]));
            case 'geofenceId':
                if (item[key] > 0) {
                    const geofence = geofences[item[key]];
                    return geofence && geofence.name;
                }
                return null;
            case 'maintenanceId':
                return item[key] > 0 ? item[key] > 0 : null;
            case 'alarm':
                return item.attributes[key] ? t(prefixString('alarm', item.attributes[key])) : null;
            default:
                return item[key];
        }
    };

    return (
        <div>
            <DashboardFilter handleSubmit={handleSubmit} deviceIds={deviceIds} setDeviceIds={setDeviceIds}>
                <div className={classes.item}>
                    <FormControl fullWidth>
                        <InputLabel>{t('reportEventTypes')}</InputLabel>
                        <Select
                            label={t('reportEventTypes')}
                            value={eventTypes}
                            onChange={(event, child) => {
                                let values = event.target.value;
                                const clicked = child.props.value;
                                if (values.includes('allEvents') && values.length > 1) {
                                    values = [clicked];
                                }
                                setEventTypes(values);
                            }}
                            renderValue={(it) => (it.length > 1 ? it.length : it.length > 0 ? t(typesMap.get(it[0])) : it)}
                            multiple
                        >
                            {typesArray.map(([key, string]) => (
                                <MenuItem key={key} value={key}>{t(string)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} />
            </DashboardFilter>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <TableRow key={item.id}>
                                {columns.map((key) => (
                                    <TableCell key={key}>
                                        {formatValue(item, key)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default EventsStatus;

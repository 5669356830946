import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'dashboard',
  initialState: {
    fuelSelectedDevices: [],
    alarmsSelectedDevices: [],
    positions: []
  },
  reducers: {
    selectFuelDevices(state, action) {
      state.fuelSelectedDeviceIds = [...action.payload];
      console.log(state.fuelSelectedDevices);
    },
    selectAlarmDevices(state, action) {
      state.alarmsSelectedDevices = [...action.payload];
    },
    updatePositions(state, action) {
      state.positions = [...action.payload];
    }
  },
});

export { actions as dashboardActions };
export { reducer as dashboardReducer };

import React, { useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  formatAlarm,
  formatBoolean,
  formatCoordinate,
  formatCourse,
  formatDate,
  formatDistance,
  formatNumber,
  formatPercentage,
  formatSpeed,
  formatTime,
  formatVolume,
} from "../common/util/formatter";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import ReportsMenu from "./components/ReportsMenu";
import ReportFilter from "./components/ReportFilter";
import usePersistedState from "../common/util/usePersistedState";
import ColumnSelect from "./components/ColumnSelect";
import { useCatch } from "../reactHelper";
import useReportStyles from "./common/useReportStyles";
import TableShimmer from "../common/components/TableShimmer";
import ReportTable from "./components/ReportTable";
import { getColumnValue } from "../common/util/converter";

const columnsArray = [
  ["captureTime", "statisticsCaptureTime"],
  ["activeUsers", "statisticsActiveUsers"],
  ["activeDevices", "statisticsActiveDevices"],
  ["requests", "statisticsRequests"],
  ["messagesReceived", "statisticsMessagesReceived"],
  ["messagesStored", "statisticsMessagesStored"],
  ["mailSent", "notificatorMail"],
  ["smsSent", "notificatorSms"],
  ["geocoderRequests", "statisticsGeocoder"],
  ["geolocationRequests", "statisticsGeolocation"],
];
const columnsMap = new Map(columnsArray);

const StatisticsPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const [columns, setColumns] = usePersistedState("statisticsColumns", [
    "captureTime",
    "activeUsers",
    "activeDevices",
    "messagesStored",
  ]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = useCatch(async ({ from, to }) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({ from, to });
      const response = await fetch(`/api/statistics?${query.toString()}`, {
        Accept: "application/json",
      });
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  });

  const formatValue = (item, key) => {
    const value = getColumnValue(item, key);

    switch (key) {
      case "fixTime":
      case "deviceTime":
      case "serverTime":
        return formatTime(value);
      case "captureTime":
        return formatDate(value);
      case "latitude":
        return formatCoordinate("latitude", value, coordinateFormat);
      case "longitude":
        return formatCoordinate("longitude", value, coordinateFormat);
      case "speed":
        return formatSpeed(value, speedUnit, t);
      case "course":
        return formatCourse(value);
      case "batteryLevel":
        return formatPercentage(value);
      case "alarm":
        return formatAlarm(value, t);
      case "odometer":
      case "distance":
      case "totalDistance":
        return formatDistance(value, distanceUnit, t);
      case "hours":
        return formatNumber(value / 1000, 1, true);
      case "fuel":
        return formatVolume(value, volumeUnit, t);
      default:
        if (typeof value === "number") {
          return formatNumber(value, 1, true);
        }
        if (typeof value === "boolean") {
          return formatBoolean(value, t);
        }
        return value;
    }
  };

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "statisticsTitle"]}
    >
      <div className={classes.header}>
        <ReportFilter handleSubmit={handleSubmit} showOnly ignoreDevice>
          <ColumnSelect
            columns={columns}
            setColumns={setColumns}
            columnsArray={columnsArray}
          />
        </ReportFilter>
      </div>
      <ReportTable
        title={t("statisticsTitle")}
        columns={columns}
        columnsMap={columnsMap}
        isLoading={loading}
        items={items}
        formatValue={formatValue}
        grouping={false}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
      {/* <Table>
        <TableHead>
          <TableRow>
            {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={item.id}>
              {columns.map((key) => (
                <TableCell key={key}>
                  {key === 'captureTime' ? formatTime(item[key], 'DD-MM-YYYY') : item[key]}
                </TableCell>
              ))}
            </TableRow>
          )) : (<TableShimmer columns={columns.length} />)}
        </TableBody>
      </Table> */}
    </PageLayout>
  );
};

export default StatisticsPage;

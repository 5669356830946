import { useMemo } from "react";

export default (t) =>
  useMemo(
    () => ({
      "web.reportColor": {
        name: t("attributeWebReportColor"),
        type: "string",
        subtype: "color",
      },
      devicePassword: {
        name: t("attributeDevicePassword"),
        type: "string",
      },
      deviceImage: {
        name: t("attributeDeviceImage"),
        type: "string",
      },
      "processing.copyAttributes": {
        name: t("attributeProcessingCopyAttributes"),
        type: "string",
      },
      "decoder.timezone": {
        name: t("sharedTimezone"),
        type: "string",
      },
      deviceInactivityStart: {
        name: t("attributeDeviceInactivityStart"),
        type: "number",
      },
      deviceInactivityPeriod: {
        name: t("attributeDeviceInactivityPeriod"),
        type: "number",
      },
      omnicommRecordNumber: {
        name: t("attributeOmnicommRecordNumber"),
        type: "number",
      },
      omnicommLastIdentificationTime: {
        name: t("attributeOmnicommLastIdentificationTime"),
        type: "string",
      },
      omnicommLastArchiveTime: {
        name: t("attributeOmnicommLastArchiveTime"),
        type: "string",
      },
      omnicommLastDeleteTime: {
        name: t("attributeOmnicommLastDeleteTime"),
        type: "string",
      },
      lastFuelUpdate: {
        name: t("attributeLastFuelUpdate"),
        type: "string",
      },
      lastFuelUpdatePosition: {
        name: t("attributeLastFuelUpdatePosition"),
        type: "string",
      },
      currentFuelSensorsStatus: {
        name: t("attributeCurrentFuelSensorsStatus"),
        type: "string",
      },
      fuelComputationErrors: {
        name: t("attributeFuelComputationErrors"),
        type: "string",
      },
    }),
    [t]
  );

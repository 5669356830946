import {
  amber,
  grey,
  green,
  indigo,
  red,
  common,
  purple,
  teal,
  blue,
  cyan,
  lime,
  deepOrange,
  brown,
  blueGrey,
  yellow,
  lightGreen,
  pink,
  orange,
} from "@mui/material/colors";

export default {
  background: {
    default: grey[50],
  },
  primary: {
    // main: indigo[900],
    // main: '#3e7eab',
    main: "#034001",
  },
  secondary: {
    // main: green[500],
    // main: '#cd4325',
    main: "#034001",
    contrastText: common.white,
  },
  colors: {
    white: common.white,
    positive: green[400],
    medium: amber[700],
    negative: red[400],
    neutral: grey[400],
    warning: orange[800],
    chartsOnline: green[400],
    chartsOffline: red[400],
    geometry: "#3bb2d0",
  },
  chartColors: [
    grey,
    blueGrey,
    lightGreen,
    red,
    purple,
    teal,
    blue,
    indigo,
    yellow,
    pink,
    amber,
    green,
    cyan,
    lime,
    deepOrange,
    brown,
  ],
};

import { alpha, Box, useTheme } from "@mui/material";
import MaterialTable, { MTableBodyRow } from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "../../common/components/LocalizationProvider";
import tableIcons from "../../main/MateriaTableIcons";

import GpsFixed from "@material-ui/icons/GpsFixed";
import LocationSearching from "@material-ui/icons/LocationSearching";
import makeStyles from "@mui/styles/makeStyles";
import usePositionAttributes from "../../common/attributes/usePositionAttributes";
import AddressValue from "../../common/components/AddressValue";
import { PaginationContext } from "../../common/context/PaginationContext";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:hover": { backgroundColor: alpha(theme.palette.primary.main, 0.1) },
  },
}));

const ReportTable = ({
  columns,
  columnsMap,
  isLoading,
  items,
  formatValue,
  grouping,
  showMapAction,
  selectedItem,
  setSelectedItem,
  title,
}) => {
  const t = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const positionAttributes = usePositionAttributes(t);

  const [tableColumns, setTableColumns] = useState([]);
  const [data, setData] = useState([]);
  const { maximumPages, pagineable, setRefetch, setPageSize } =
    useContext(PaginationContext);

  useEffect(() => {
    const tempTableColumns = [];
    const cellStyle = {
      letterSpacing: "normal",
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
      padding: 12,
      fontSize: 13,
    };

    if (columnsMap) {
      columns.map((column) => {
        const columnHeader = {
          field: column,
          title: t(columnsMap.get(column)),
          sorting: true,
          align: "center",
          cellStyle: cellStyle,
        };
        if (column.toLowerCase().includes("address")) {
          columnHeader["render"] = (row) => {
            const addr = row[column];
            return (
              <AddressValue
                latitude={addr.lat}
                longitude={addr.lon}
                originalAddress={addr.origin}
              />
            );
          };
        }
        tempTableColumns.push(columnHeader);
      });
    } else {
      columns.map((column) => {
        tempTableColumns.push({
          field: column,
          title: positionAttributes[column].name,
          sorting: true,
          align: "center",
          cellStyle: cellStyle,
        });
      });
    }

    setTableColumns(tempTableColumns);
  }, [columns]);

  useEffect(() => {
    const tempData = [];
    if (formatValue) {
      items.map((item) => {
        const entry = {};
        if (item.id) {
          entry["id"] = item.id;
        }

        columns.map((column) => (entry[column] = formatValue(item, column)));
        tempData.push(entry);
      });
      setData(tempData);
    } else {
      setData(items);
    }
  }, [items, columns]);

  return (
    <Box sx={{ m: 2 }}>
      <MaterialTable
        style={{
          padding: 2,
          letterSpacing: "normal",
          border: 0,
        }}
        title={title}
        icons={tableIcons}
        options={{
          grouping: grouping,
          exportButton: true,
          exportAllData: true,
          pageSize: 10,
          draggable: true,
          searchFieldVariant: "outlined",
          loadingType: "linear",
          minBodyHeight: "100%",
          maxBodyHeight: "100%",
        }}
        isLoading={isLoading}
        columns={tableColumns}
        data={data}
        onChangeRowsPerPage={(pageSize) => {
          setPageSize(pageSize);
        }}
        onChangePage={(pageNumber, pageSize) => {
          const pageDifference = maximumPages - pageNumber;
          if (pageDifference === 3 && pagineable) {
            setRefetch(true);
          }
        }}
        components={{
          Row: (props) => (
            <MTableBodyRow className={classes.tableRow} {...props} />
          ),
        }}
        actions={[
          showMapAction
            ? (rowData) => ({
                icon:
                  selectedItem && selectedItem.id == rowData.id
                    ? GpsFixed
                    : LocationSearching,
                tooltip: "Show on map",
                onClick: (event, rowData) =>
                  selectedItem && selectedItem.id == rowData.id
                    ? setSelectedItem(null)
                    : setSelectedItem(
                        items.find((item) => item.id == rowData.id)
                      ),
              })
            : null,
        ]}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
            labelRowsPerPage: " Rows per page",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: `No ${title} to display`,
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
      />
    </Box>
  );
};

export default ReportTable;

import moment from "moment";
import { useMemo } from "react";

export default (t) =>
  useMemo(
    () => ({
      withinAnHour: {
        name: t("deviceStatusWithinAnHour"),
        isInRange: (date) => {
          const anHourAgo = moment().subtract(1, "hours");
          const momentDate = moment(date);

          return momentDate.isSameOrAfter(anHourAgo);
        },
      },
      withinADay: {
        name: t("deviceStatusWithinADay"),
        isInRange: (date) => {
          const anHourAgo = moment().subtract(1, "hours");
          const aDayAgo = moment().startOf("day").subtract(1, "days");
          const momentDate = moment(date);

          return momentDate.isSameOrAfter(aDayAgo) && momentDate.isBefore(anHourAgo);
        },
      },
      withinAWeek: {
        name: t("deviceStatusWithinAWeek"),
        isInRange: (date) => {
          const aDayAgo = moment().startOf("day").subtract(1, "days");
          const aWeekAgo = moment().startOf("day").subtract(1, "weeks");
          const momentDate = moment(date);

          return momentDate.isSameOrAfter(aWeekAgo) && momentDate.isBefore(aDayAgo);
        },
      },
      withinAMonth: {
        name: t("deviceStatusWithinAMonth"),
        isInRange: (date) => {
          const aWeekAgo = moment().startOf("day").subtract(1, "weeks");
          const aMonthAgo = moment().startOf("day").subtract(1, "months");
          const momentDate = moment(date);

          return momentDate.isSameOrAfter(aMonthAgo) && momentDate.isBefore(aWeekAgo);
        },
      },
      moreThanAMonthAgo: {
        name: t("deviceStatusMoreThanAMonthAgo"),
        isInRange: (date) => {
          const aMonthAgo = moment().startOf("day").subtract(1, "months");
          const momentDate = moment(date);

          return momentDate.isSameOrBefore(aMonthAgo);
        },
      },
      unknown: {
        name: t("deviceStatusUnknown"),
        isInRange: (date) => {
          if (!date) {
            return true;
          }

          return false;
        },
      },
    }),
    [t]
  );

import React, { useEffect, useState } from "react";

import {
  Add,
  CompassCalibration,
  ExpandMore,
  Remove,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { useTranslation } from "../../common/components/LocalizationProvider";
import SelectField from "../../common/components/SelectField";
import {
  portNameFormatter,
  sensorNameFormatter,
} from "../../common/util/formatter";
import { errorsActions } from "../../store";
import AddSensorDialog from "./AddSensorDialog";
import DeleteSensorDialog from "./DeleteSensorDialog";
import FuelCalibrationModal from "./FuelCalibrationModal";

const useStyles = makeStyles((theme) => ({
  removeButton: {
    marginRight: theme.spacing(1.5),
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const EditSensorsView = ({
  item,
  sensors,
  setSensors,
  addedSensors,
  setAddedSensors,
  updatedSensors,
  setUpdatedSensors,
  deletedSensors,
  setDeletedSensors,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addDialogShown, setAddDialogShown] = useState(false);
  const [calibrationModalShown, setCalibrationModalShown] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sensorCount, setSensorCount] = useState(0);
  const [deleteSensorIdx, setDeleteSensorIdx] = useState(0);

  useEffect(() => {
    if (sensors) {
      const length = sensors.length;
      setSensorCount(length);
    }
  }, [sensors]);

  const valid = (sensor) =>
    sensor && sensor.typeId != 0 && sensor.readingTypeId != 0;

  const addSensor = (sensor) => {
    var tempSensors = [];
    if (sensors) {
      tempSensors = [...sensors];
    }

    if (sensor.name.trim() == "") {
      sensor.name = `Fuel Sensor #${tempSensors.length + 1}`;
    }

    tempSensors.push(sensor);
    setSensors(tempSensors);
    setAddedSensors([...addedSensors, sensor]);
  };

  const updateSensor = (propName, propValue, index, isAttribute) => {
    const sensor = sensors[index];

    if (isAttribute) {
      sensor.attributes[propName] = propValue;
    } else {
      sensor[propName] = propValue;
    }

    if (!valid(sensor)) return;

    const tempSensors = [...sensors];
    tempSensors[index] = sensor;
    setSensors(tempSensors);

    if (sensor.id && !updatedSensors.includes(sensor.id)) {
      setUpdatedSensors([...updatedSensors, sensor.id]);
    }
  };

  const deleteSensor = async (index) => {
    setDeleteDialogOpen(false);

    const sensor = sensors[index];
    const updatedSensors = [...sensors];
    if (sensor.calibrationId) {
      deleteCurrentCalibration(sensor.calibrationId);
    }
    updatedSensors.splice(index, 1);
    setSensors(updatedSensors);

    if (sensor.id && !deletedSensors.includes(sensor.id)) {
      setDeletedSensors([...deletedSensors, sensor.id]);
    }
  };

  const deleteCurrentCalibration = async (id) => {
    if (!id) {
      dispatch(errorsActions.push(`Calibration ID is: ${id}`));
      return;
    }

    let url = `/api/calibrations/${id}`;
    let method = "DELETE";

    const response = await fetch(url, { method: method });

    if (!response.ok) {
      dispatch(errorsActions.push(await response.text()));
    }
  };

  const handleAddResult = (sensor, cancel) => {
    setAddDialogShown(false);
    if (cancel) {
      return;
    }

    if (!valid(sensor)) {
      dispatch(
        errorsActions.push(
          "Invalid sensor settings. Make sure all parameters are correctly filled!"
        )
      );
      return;
    }

    if (sensor) {
      sensor["deviceId"] = item.id;
      addSensor(sensor);
    }
  };

  const showCalibrationDialog = (sensor, index) => {
    setSelectedSensor(sensor);
    setSelectedIndex(index);
    setCalibrationModalShown(true);
  };

  return (
    <>
      {sensors &&
        sensors.map((sensor, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle1">{sensor.name}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={sensor.name}
                  onChange={(event) =>
                    updateSensor("name", event.target.value, index)
                  }
                  label={t("sharedName")}
                />
                <SelectField
                  value={sensor.typeId || 0}
                  onChange={(event) =>
                    updateSensor("typeId", Number(event.target.value), index)
                  }
                  endpoint="/api/fuel-sensors"
                  label={t("sharedSensor")}
                  titleGetter={(sensor) => sensorNameFormatter(sensor)}
                />
                <SelectField
                  value={sensor.readingTypeId || 0}
                  onChange={(event) =>
                    updateSensor(
                      "readingTypeId",
                      Number(event.target.value),
                      index
                    )
                  }
                  endpoint="/api/fuel-sensors/readings"
                  label={t("sharedReadingType")}
                  titleGetter={(readingType) => `${readingType.metricSymbol}`}
                />
                <TextField
                  value={sensor.groupNo}
                  onChange={(event) =>
                    updateSensor("groupNo", Number(event.target.value), index)
                  }
                  label={t("sharedFuelTankNumber")}
                />
                <SelectField
                  value={sensor.fuelPort || ""}
                  onChange={(event) => {
                    updateSensor("fuelPort", event.target.value, index);
                    console.log(event.target.value);
                  }}
                  endpoint="/api/fuel-sensors/ports"
                  label={t("sharedFuelLevelPort")}
                  keyGetter={(port) => port.portName}
                  titleGetter={(port) => portNameFormatter(port)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sensor.isCalibrated}
                      onChange={(event) =>
                        updateSensor(
                          "isCalibrated",
                          event.target.checked,
                          index
                        )
                      }
                    />
                  }
                  label={t("sensorMustCalibrate")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sensor.isDisabled}
                      onChange={(event) =>
                        updateSensor("isDisabled", event.target.checked, index)
                      }
                    />
                  }
                  label={t("sensorIsDisabled")}
                />
                {sensor.id &&
                  sensor.isCalibrated &&
                  ((sensor.calibrationId && sensor.calibrationId != 0 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => showCalibrationDialog(sensor, index)}
                      startIcon={<CompassCalibration />}
                    >
                      {t("sharedShowCalibrations")}
                    </Button>
                  )) || (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => showCalibrationDialog(sensor, index)}
                      startIcon={<Add />}
                    >
                      {t("sharedAddCalibrations")}
                    </Button>
                  ))}
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => {
                    setDeleteSensorIdx(index);
                    setDeleteDialogOpen(true);
                  }}
                  startIcon={<Remove />}
                >
                  {t("sharedRemove")}
                </Button>
              </AccordionDetails>
            </Accordion>
          );
        })}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setAddDialogShown(true)}
        startIcon={<AddIcon />}
      >
        {t("sharedAdd")}
      </Button>
      <AddSensorDialog
        open={addDialogShown}
        onResult={handleAddResult}
        sensors={sensors}
        sensorCount={sensorCount}
      />
      <DeleteSensorDialog
        index={deleteSensorIdx}
        name={sensors[deleteSensorIdx] ? sensors[deleteSensorIdx].name : ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={deleteSensor}
      />
      <FuelCalibrationModal
        deviceId={item.id}
        index={selectedIndex}
        sensor={selectedSensor}
        setSensor={(calibrationId) => {
          updateSensor("calibrationId", calibrationId, selectedIndex);
        }}
        open={calibrationModalShown}
        setOpen={setCalibrationModalShown}
      />
    </>
  );
};

export default EditSensorsView;

/* eslint-disable consistent-return */
import { Box, LinearProgress, Skeleton, Stack } from "@mui/material";
import {
  amber,
  blue,
  cyan,
  green,
  grey,
  indigo,
  lime,
  orange,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import usePositionAttributes from "../../common/attributes/usePositionAttributes";
import { useTranslation } from "../../common/components/LocalizationProvider";
import { distanceFromMeters } from "../../common/util/converter";
import { formatDate, formatNumber } from "../../common/util/formatter";
import usePersistedState from "../../common/util/usePersistedState";
import { useEffectAsync } from "../../reactHelper";
import { errorsActions } from "../../store";
import DashboardFilter from "./DashboardFilter";

const colors = [
  indigo[500],
  purple[500],
  lime[500],
  teal[500],
  red[500],
  blue[500],
  green[500],
  grey[500],
  amber[500],
  cyan[500],
  yellow[500],
  orange[500],
];

const useStyles = makeStyles(() => ({
  chart: {
    flexGrow: 1,
    overflow: "hidden",
  },
}));

const FuelLineChart = ({ items }) => (
  <ResponsiveContainer width="100%" height={365}>
    <LineChart
      margin={{
        top: 10,
        right: 40,
        left: 0,
        bottom: 10,
      }}
    >
      <XAxis
        dataKey="fixTime"
        tickFormatter={(time) => moment(time, "h:mm:ss").fromNow()}
        allowDuplicatedCategory={false}
        interval="preserveStart"
      />
      <YAxis
        type="number"
        tickCount={10}
        domain={["auto", "auto"]}
        dataKey="fuel"
        allowDecimals={false}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip formatter={(value) => [`${formatNumber(value, 2)} litres`]} />
      {items.map((item, index) => (
        <Line
          type="monotone"
          stroke={colors[index % colors.length]}
          activeDot={{ r: 8 }}
          dataKey="fuel"
          data={item.data}
          name={item.deviceId}
          key={item.deviceId}
        />
      ))}
    </LineChart>
  </ResponsiveContainer>
);

const FuelLevelStatus = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [items, setItems] = usePersistedState("positions", []);
  const [deviceIds, setDeviceIds] = usePersistedState(
    "fuelStatusDeviceIds",
    []
  );
  const [loading, setLoading] = useState(false);

  const fetchDeviceReport = async (deviceId, from, to) => {
    setLoading(true);

    if (!deviceId) {
      return;
    }

    const query = new URLSearchParams({
      deviceId,
      from,
      to,
      mail: false,
    });
    const response = await fetch(`/api/reports/route?${query.toString()}`, {
      Accept: "application/json",
    });
    if (response.ok) {
      const positions = await response.json();
      const formattedPositions = positions.map((position) => ({
        id: position.id,
        deviceId: position.deviceId,
        fuel: position.attributes.fuel,
        // odometer: distanceFromMeters(position.attributes.odometer, 'km'),
        fixTime: formatDate(position.fixTime, "HH"),
      }));

      return formattedPositions;
    } else {
      throw Error(await response.text());
    }
  };

  const updateData = async (deviceIds) => {
    const selectedFrom = moment().subtract(8, "hours");
    const selectedTo = moment().endOf("day");

    const positions = [];

    await deviceIds.map((id) =>
      fetchDeviceReport(
        id,
        selectedFrom.toISOString(),
        selectedTo.toISOString()
      ).then((pos) => {
        positions.push({
          deviceId: id,
          data: pos,
        });
        setLoading(false);
      }
      ).catch((e) => dispatch(errorsActions.push(e.message)))
    );

    setItems(positions);
  };

  useEffectAsync(() => updateData(deviceIds), []);

  return (
    <div>
      <DashboardFilter
        handleSubmit={updateData}
        deviceIds={deviceIds}
        setDeviceIds={setDeviceIds}
      />
      {(items.length > 0 && (
        <Stack direction="column" spacing={1}>
          <div className={classes.chart}>
            <FuelLineChart
              items={items}
              // setEmpty={setEmpty}
            />
          </div>
          {/* <OdometerDataGrid items={items} /> */}
        </Stack>
      )) || (
        <Box sx={{ height: "100%", minHeight: "305" }}>
          <Skeleton variant="rectangular" height={305} />
        </Box>
      )}
    </div>
  );
};

export default FuelLevelStatus;

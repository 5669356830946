import { Box, Container, Tooltip } from "@mui/material";
import React from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";

const FuelCalibrationGraph = ({ calibrationEntries }) => {

    return (
        <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={calibrationEntries} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorFuel" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <XAxis dataKey="voltage" />
                <YAxis />
                <CartesianGrid strokeDasharray={"3 3"} />
                {/* <Tooltip /> */}
                <Area type={"monotone"} dataKey="fuel" stroke="#8884d8" fillOpacity={1} fill="url(#colorFuel)" />
            </AreaChart>
        </ResponsiveContainer>

    )
};

export default FuelCalibrationGraph;
import {
  Autocomplete, Button, Chip, FormControl, TextField,
} from '@mui/material';
import {
  amber, blue, cyan, green,
  grey, indigo, lime, orange, purple, red, teal, yellow,
} from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import React from 'react';
import { useTranslation } from '../../common/components/LocalizationProvider';
import dimensions from '../../common/theme/dimensions';

export const useFilterStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2, 2),
  },
  item: {
    flex: `1 1 ${dimensions.filterFormWidth}`,
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
    flex: `1 1 ${dimensions.filterFormWidth}`,
  },
  button: {
    flexGrow: 1,
  },
}));

const colors = [
  indigo[500],
  purple[500],
  lime[500],
  teal[500],
  red[500],
  blue[500],
  green[500],
  grey[500],
  amber[500],
  cyan[500],
  yellow[500],
  orange[500],
];

const DashboardFilter = ({
  children, handleSubmit, ignoreDevice, deviceIds, setDeviceIds
}) => {
  const classes = useFilterStyles();
  const t = useTranslation();

  const devices = Object.values(useSelector((state) => state.devices.items));

  const updateData = () => {
    handleSubmit(
      deviceIds,
    );
  };

  return (
    <div className={classes.filter}>
      {!ignoreDevice && (
        <div className={classes.item}>
          <FormControl fullWidth>
            <Autocomplete
              id="device-autocomplete"
              multiple
              disablePortal
              defaultValue={devices.filter((device) => deviceIds.includes(device.id))}
              options={devices}
              renderTags={(value, getTagProps) => value.map((option, index) => (
                <Chip
                  variant="outlined"
                  sx={{
                    borderColor: colors[index % colors.length],
                    color: colors[index % colors.length],
                  }}
                  label={option && (option.name || option.contact)}
                  {...getTagProps({ index })}
                />
              ))}
              key={(options) => options.map((option) => option.id)}
              getOptionLabel={(option) => option.name || option.contact}
              onChange={(e, selectedDevices) => {
                const ids = [];
                selectedDevices.map((item) => ids.push(item.id));
                setDeviceIds(ids);
              }}
              renderInput={(params) => <TextField {...params} label={t('sharedSearchDevices')} />}
            />
          </FormControl>
        </div>
      )}
      {children}
      <Button
        onClick={updateData}
        variant="outlined"
        color="secondary"
        className={classes.button}
        disabled={!ignoreDevice && deviceIds.length < 0}
      >
        Update
      </Button>
    </div>
  );
};

export default DashboardFilter;

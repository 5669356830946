import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  CardContent,
  Grow,
  IconButton,
  Paper,
  Slider,
  Table,
  TableBody,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TuneIcon from "@mui/icons-material/Tune";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MapView from "../map/core/MapView";
import MapRoutePath from "../map/MapRoutePath";
import MapPositions from "../map/MapPositions";
import { formatTime } from "../common/util/formatter";
import ReportFilter from "../reports/components/ReportFilter";
import { useTranslation } from "../common/components/LocalizationProvider";
import { useCatch } from "../reactHelper";
import TopBar from "../common/components/TopBar";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import usePersistedState from "../common/util/usePersistedState";
import StatusCard, { CustomTableRow } from "../main/StatusCard";
import PositionValue from "../common/components/PositionValue";
import useKeypress from "react-use-keypress";
import CollapsibleDrawer from "../common/components/CollapsibleDrawer";
import { useTheme } from "@mui/styles";
import useQuery from "../common/attributes/useQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 3,
    left: `calc(${theme.spacing(7)} + 10px)`,
    top: 10,
    // left: 0,
    // top: 50,
    // margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
      left: 0,
      top: 0,
    },
  },
  status: {
    marginTop: 10,
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: "100%",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formControlLabel: {
    height: "100%",
    width: "100%",
    paddingRight: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

function CurrentPositionStatusCard(props) {
  return (
    <Card elevation={3} className={classes.card}>
      <CardContent>
        <Table
          size="small"
          classes={{
            root: props.table,
          }}
        >
          <TableBody>
            {props.positionItems
              .filter(
                (key) =>
                  props.currentPosition.hasOwnProperty(key) ||
                  props.currentPosition.attributes.hasOwnProperty(key)
              )
              .map((key) => (
                <CustomTableRow
                  key={key}
                  icon={props.positionAttributes[key].icon}
                  name={props.positionAttributes[key].name}
                  content={
                    <PositionValue
                      position={props.currentPosition}
                      property={
                        props.currentPosition.hasOwnProperty(key) ? key : null
                      }
                      attribute={
                        props.currentPosition.hasOwnProperty(key) ? null : key
                      }
                    />
                  }
                />
              ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

const ReplayPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const timerRef = useRef();
  const theme = useTheme();
  const query = useQuery();

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const defaultDeviceId = useSelector((state) => state.devices.selectedId);
  const device = useSelector((state) => {
    if (defaultDeviceId) {
      const device = state.devices.items[defaultDeviceId];
      if (device) {
        return device;
      }
    }
    return null;
  });

  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [expanded, setExpanded] = useState(true);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (index) {
      setCurrentPosition(positions[index]);
    }
  }, [index]);

  const onClick = useCallback(
    (positionId) => {
      navigate(`/position/${positionId}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (playing && positions.length > 0) {
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, 500);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  useKeypress(["Space"], (event) => {
    if (event.key === "Space") {
      setPlaying(!playing);
    }
  });

  const handleSubmit = useCatch(async ({ deviceId, from, to, headers }) => {
    try {
      setLoading(true);
      setFrom(from);
      setTo(to);
      const query = new URLSearchParams({ deviceId, from, to });
      const response = await fetch(`/api/positions?${query.toString()}`, {
        headers,
      });
      if (response.ok) {
        setIndex(0);
        const positions = await response.json();
        setPositions(positions);
        if (positions.length) {
          setExpanded(false);
        } else {
          throw Error(t("sharedNoData"));
        }
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  });

  const handleDownload = useCatch(async () => {
    const query = new URLSearchParams({ deviceId: defaultDeviceId, from, to });
    const response = await fetch(`/api/positions/kml?${query.toString()}`);
    if (response.ok) {
      window.location.assign(window.URL.createObjectURL(await response.blob()));
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <div className={classes.root}>
      {/* {desktop && <TopBar />} */}
      {desktop && <CollapsibleDrawer />}
      <MapView>
        <MapRoutePath positions={positions} />
        {index < positions.length && (
          <MapPositions positions={[positions[index]]} onClick={onClick} />
        )}
      </MapView>
      <div className={classes.sidebar}>
        <Paper elevation={3} square>
          <Toolbar>
            <IconButton
              edge="start"
              sx={{ mr: 2 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("reportTracks")}
            </Typography>
            {!expanded && (
              <>
                <IconButton onClick={handleDownload}>
                  <DownloadIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => setExpanded(true)}>
                  <TuneIcon />
                </IconButton>
              </>
            )}
          </Toolbar>
        </Paper>
        <Paper className={classes.content} square>
          {!expanded ? (
            <>
              <Typography variant="subtitle1" align="center">
                {device?.name}
              </Typography>
              <Slider
                className={classes.slider}
                max={positions?.length - 1}
                step={null}
                marks={positions?.map((_, index) => ({ value: index }))}
                value={index}
                onChange={(_, index) => setIndex(index)}
              />
              <div className={classes.controls}>
                {`${index + 1}/${positions?.length}`}
                <IconButton
                  onClick={() => setIndex((index) => index - 1)}
                  disabled={playing || index <= 0}
                >
                  <FastRewindIcon />
                </IconButton>
                <IconButton
                  onClick={() => setPlaying(!playing)}
                  disabled={index >= positions.length - 1}
                >
                  {playing ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                <IconButton
                  onClick={() => setIndex((index) => index + 1)}
                  disabled={playing || index >= positions?.length - 1}
                >
                  <FastForwardIcon />
                </IconButton>
                {formatTime(positions[index]?.fixTime)}
              </div>
            </>
          ) : (
            <ReportFilter
              handleSubmit={handleSubmit}
              isFetching={loading}
              fullScreen
              showOnly
            />
          )}
        </Paper>
        <div className={classes.status}>
          {currentPosition && (
            // <Grow>
            <StatusCard
              deviceId={defaultDeviceId}
              currentPosition={currentPosition}
              isReplay
            />
            // </Grow>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReplayPage;

import React, { useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
  formatDistance,
  formatHours,
  formatVolume,
  formatTime,
} from "../common/util/formatter";
import ReportFilter from "./components/ReportFilter";
import { useAttributePreference } from "../common/util/preferences";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import ReportsMenu from "./components/ReportsMenu";
import ColumnSelect from "./components/ColumnSelect";
import usePersistedState from "../common/util/usePersistedState";
import { useCatch } from "../reactHelper";
import useReportStyles from "./common/useReportStyles";
import MapPositions from "../map/MapPositions";
import MapView from "../map/core/MapView";
import MapCamera from "../map/MapCamera";
import AddressValue from "../common/components/AddressValue";
import TableShimmer from "../common/components/TableShimmer";
import ReportTable from "./components/ReportTable";
import { getColumnValue } from "../common/util/converter";

const columnsArray = [
  ["startTime", "reportStartTime"],
  ["startOdometer", "positionOdometer"],
  ["address", "positionAddress"],
  ["endTime", "reportEndTime"],
  ["duration", "reportDuration"],
  ["engineHours", "reportEngineHours"],
  ["spentFuel", "reportSpentFuel"],
];
const columnsMap = new Map(columnsArray);

const StopReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const distanceUnit = useAttributePreference("distanceUnit");
  const volumeUnit = useAttributePreference("volumeUnit");

  const [columns, setColumns] = usePersistedState("stopColumns", [
    "startTime",
    "endTime",
    "startOdometer",
    "address",
  ]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmit = useCatch(
    async ({ deviceId, from, to, mail, headers }) => {
      setLoading(true);
      try {
        const query = new URLSearchParams({ deviceId, from, to, mail });
        const response = await fetch(`/api/reports/stops?${query.toString()}`, {
          headers,
        });
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType) {
            if (contentType === "application/json") {
              var tempItems = await response.json();
              tempItems.forEach((item, i) => {
                item.id = i + 1;
              });
              setItems(tempItems);
            } else {
              window.location.assign(
                window.URL.createObjectURL(await response.blob())
              );
            }
          }
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  );

  const formatValue = (item, key) => {
    const value = getColumnValue(item, key);
    
    switch (key) {
      case "startTime":
      case "endTime":
        return formatTime(value, "YYYY-MM-DD HH:mm");
      case "startOdometer":
        return formatDistance(value, distanceUnit, t);
      case "duration":
        return formatHours(value);
      case "engineHours":
        return formatHours(value);
      case "spentFuel":
        return formatVolume(value, volumeUnit, t);
      case "address":
        return {
          lat: item.startLat,
          lon: item.startLon,
          origin: value,
        };
      default:
        return value;
    }
  };

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "reportStops"]}
    >
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapPositions
                positions={[
                  {
                    deviceId: selectedItem.deviceId,
                    latitude: selectedItem.latitude,
                    longitude: selectedItem.longitude,
                  },
                ]}
              />
            </MapView>
            <MapCamera
              latitude={selectedItem.latitude}
              longitude={selectedItem.longitude}
            />
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit} noReport>
              <ColumnSelect
                columns={columns}
                setColumns={setColumns}
                columnsArray={columnsArray}
              />
            </ReportFilter>
          </div>
          <ReportTable
            columns={columns}
            columnsMap={columnsMap}
            isLoading={loading}
            items={items}
            formatValue={formatValue}
            title={t("reportStops")}
            showMapAction
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
          {/* <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnAction} />
                {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? items.map((item) => (
                <TableRow key={item.positionId}>
                  <TableCell className={classes.columnAction} padding="none">
                    {selectedItem === item ? (
                      <IconButton size="small" onClick={() => setSelectedItem(null)}>
                        <GpsFixedIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton size="small" onClick={() => setSelectedItem(item)}>
                        <LocationSearchingIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                  {columns.map((key) => (
                    <TableCell key={key}>
                      {formatValue(item, key)}
                    </TableCell>
                  ))}
                </TableRow>
              )) : (<TableShimmer columns={columns.length + 1} startAction />)}
            </TableBody>
          </Table> */}
        </div>
      </div>
    </PageLayout>
  );
};

export default StopReportPage;

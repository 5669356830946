import { Box, Container, Typography } from "@mui/material";
import {
  amber,
  brown,
  deepOrange,
  green,
  lime,
  orange,
  red,
  yellow,
} from "@mui/material/colors";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Legend, Pie, PieChart, Tooltip } from "recharts";
import useStatusAttributes from "../../common/attributes/useStatusAttributes";
import { useTranslation } from "../../common/components/LocalizationProvider";
import usePersistedState from "../../common/util/usePersistedState";

const getPieColor = (key) => {
  switch (key) {
    case "withinAnHour":
      return green;
    case "withinADay":
      return lime;
    case "withinAWeek":
      return yellow;
    case "withinAMonth":
      return orange;
    case "moreThanAMonthAgo":
      return brown;
    default:
      return red;
  }
};

const LastUpdatePieChart = ({ devices }) => {
  const t = useTranslation();
  const statuses = useStatusAttributes(t);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [lastUpdateStatus, setLastUpdateStatus] = useState([]);
  const [filterStatuses, setFilterStatuses] = usePersistedState(
    "filterStatus",
    []
  );

  const hueLevel = 500;

  useLayoutEffect(() => {
    setData(Object.values(devices));
  }, [devices]);

  useLayoutEffect(() => {
    setLastUpdateStatus(
      Object.entries(statuses).map((entry, index) => {
        const key = entry[0];
        const value = entry[1];
        return {
          key: key,
          name: value.name,
          type: "square",
          value: data.filter((device) => value.isInRange(device.lastPositionUpdate ? device?.lastPositionUpdate : device?.lastUpdate))
            .length,
          fill: getPieColor(key)[hueLevel],
        };
      })
      /* .sort((a, b) => -(a.value - b.value)) */
    );
  }, [data]);

  return (
    <Box sx={{ width: "100%", height: "100%", p: 1 }}>
      <Container fixed>
        <Typography variant="h6" gutterBottom={false} mb={0} component="div">
          Last Update Summary
        </Typography>
        <PieChart height={360} width={360}>
          <Pie
            dataKey={"value"}
            startAngle={90}
            endAngle={270}
            data={lastUpdateStatus}
            width={15}
            cx="280%"
            cy="50%"
            innerRadius={120}
            outerRadius={150}
            fill="#8884d8"
            // label
          />
          <Tooltip />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            chartWidth={100}
            horizontalAlign="right"
            // wrapperStyle={{ fontSize: 12 }}
            payload={lastUpdateStatus.map((item, index) => ({
              id: item.key,
              type: "circle",
              value: `${item.name} (${item.value})`,
              color: getPieColor(item.key)[hueLevel + 200],
            }))}
            onClick={(e) => {
              setFilterStatuses([e.id]);
              navigate("/monitoring");
            }}
          />
        </PieChart>
      </Container>
    </Box>
  );
};

export default LastUpdatePieChart;

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Edit,
  ErrorOutlineOutlined,
  Fingerprint,
  Publish,
} from "@material-ui/icons";
import {
  AccessTime,
  Adjust,
  Category,
  DateRange,
  Delete,
  DeviceHub,
  Google,
  Info,
  LocalGasStation,
  LocationSearching,
  Park,
  Route,
  Settings,
  Summarize,
  Timeline,
} from "@mui/icons-material";
import { Box, alpha } from "@mui/system";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import { useTranslation } from "../common/components/LocalizationProvider";
import PositionValue from "../common/components/PositionValue";
import RemoveDialog from "../common/components/RemoveDialog";
import dimensions from "../common/theme/dimensions";
import {
  capitalizeFirstLetter,
  createDeviceCopy,
  formatTime,
} from "../common/util/formatter";
import {
  useAdministrator,
  useDeviceReadonly,
  useReadonly,
} from "../common/util/permissions";
import { useCatch } from "../reactHelper";
import { devicesActions } from "../store";

const useStyles = makeStyles((theme) => ({
  card: {
    width: dimensions.popupMaxWidth,
    height: 356,
    [theme.breakpoints.down("md")]: {
      width: dimensions.popupMinWidth,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 1, 1, 2),
  },
  content: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: "25px",
    height: "25px",
    filter: "brightness(0) invert(1)",
  },
  table: {
    "& .MuiTableCell-sizeSmall": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: "none",
  },
  actions: {
    justifyContent: "space-between",
  },
  tabPanel: {
    transition: "transform .5s ease",
  },
  cardButton: {
    ":hover": {
      bgcolor: "green",
      color: "white",
      cursor: "pointer",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TabPanel(props) {
  const { children, index, currentIndex } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={currentIndex !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {currentIndex === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export const StatusRow = ({ icon, name, content }) => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCell className={classes.cell}>
        <Icon color="primary">{icon ? icon : ""}</Icon>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DeviceActionButtons(props) {
  const {
    t,
    readonly,
    deviceReadonly,
    deviceId,
    navigate,
    negative,
    createCopy,
    setRemoving,
    position,
  } = props;

  return (
    <Box sx={{ p: 1 }}>
      <Stack
        direction={"row"}
        spacing={4}
        alignItems={"center"}
        justifyContent={"space-evenly"}
      >
        <Tooltip title={`Google Maps`}>
          <IconButton
            href={`https://www.google.com/maps/search/?api=1&query=${position?.latitude}%2C${position?.longitude}`}
            target={"_blank"}
          >
            <Google />
          </IconButton>
        </Tooltip>
        <Tooltip title={`${t("sharedDeviceAccumulators")}`}>
          <IconButton
            onClick={() => navigate(`/settings/accumulators/${deviceId}`)}
            disabled={readonly}
          >
            <Settings />
          </IconButton>
        </Tooltip>
        <Tooltip title={`${t("commandSend")} ${t("commandTitle")}`}>
          <IconButton
            onClick={() => navigate(`/settings/command-send/${deviceId}`)}
            disabled={readonly}
          >
            <Publish />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("sharedEdit")}>
          <IconButton
            onClick={() => navigate(`/settings/device/${deviceId}`)}
            disabled={deviceReadonly}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("sharedRemove")}>
          <IconButton
            onClick={() => setRemoving(true)}
            disabled={deviceReadonly}
            className={negative}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}

export const CustomTableRow = ({ icon, name, content }) => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCell className={classes.cell} align={"right"} sx={{ pr: 2 }}>
        <Icon color="primary">{icon ? icon : ""}</Icon>
      </TableCell>
      {name && (
        <TableCell className={classes.cell}>
          <Typography sx={{ pl: 2 }} variant="body2">
            {name}
          </Typography>
        </TableCell>
      )}
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};

export const SensorStatusTableRow = ({ t, hasError, status }) => {
  const classes = useStyles();
  const color = hasError ? "error" : "primary";

  return (
    <StyledTableRow>
      <TableCell className={classes.cell} align={"right"} sx={{ pr: 2 }}>
        <Icon color={color}>
          <ErrorOutlineOutlined />{" "}
        </Icon>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography color={color} sx={{ pl: 2 }} variant="body2">
          {t("attributeCurrentFuelSensorsStatus")}
        </Typography>
      </TableCell>

      <TableCell className={classes.cell}>
        <Typography variant="body2" color={color}>
          {status}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};

const generateReportList = (device) => [
  {
    link: "#/reports/chart?type=fuel&prefetch=true&period=today",
    title: "sharedFuelCharts",
    icon: <LocalGasStation />,
  },
  {
    link: "#/replay?period=today&prefetch=false",
    title: "reportReplay",
    icon: <Route />,
  },
  {
    link: "#/reports/logs?period=today&prefetch=true",
    title: "sharedMovementReport",
    icon: <Timeline />,
  },
  {
    link: "#/reports/summary?period=thisWeek&prefetch=true&daily=true",
    title: "sharedWeeklySummary",
    icon: <DateRange />,
  },
  {
    link: "#/reports/summary?period=thisMonth&prefetch=true&daily=true",
    title: "sharedMonthlySummary",
    icon: <DateRange />,
  },
  {
    link: `#/reports/device/${device?.id}`,
    title: "sharedDeviceSummary",
    icon: <DeviceHub />,
  },
];

const StatusCardTabbed = ({ deviceId, onClose, currentPosition }) => {
  //#region
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const admin = useAdministrator();

  const readonly = useReadonly();
  const deviceReadonly = useDeviceReadonly();
  const theme = useTheme();

  const device = useSelector((state) => state.devices.items[deviceId]);
  const groups = useSelector((state) => state.groups.items);
  const deviceClasses = useSelector((state) => state.deviceClasses.items);
  const position = currentPosition
    ? currentPosition
    : useSelector((state) => state.positions.items[deviceId]);

  const positionAttributes = usePositionAttributes(t);
  const commonPositionItems = [
    "deviceTime",
    "fuel",
    "fuelTank0",
    "fuelTank1",
    "gpsDataAccurate",
  ];
  const [staticPositionItems] = useState([...commonPositionItems, "hours"]);
  const [dynamicPositionItems] = useState([...commonPositionItems, "speed"]);
  const [positionItems, setPositionItems] = useState([]);

  const [activeTab, setActiveTab] = useState(1);

  const [removing, setRemoving] = useState(false);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch("/api/devices");
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  useEffect(() => {
    if (device) {
      updatePositionAttributes();
    }
  });

  const updatePositionAttributes = () => {
    if (
      device.attributes.hasOwnProperty("isStatic") &&
      device.attributes.isStatic
    ) {
      setPositionItems(staticPositionItems);
    } else {
      setPositionItems(dynamicPositionItems);
    }
  };

  const createCopy = () => {
    dispatch(devicesActions.copy(createDeviceCopy(device)));
  };
  //#endregion

  return (
    <>
      {device && (
        <Paper elevation={4} className={classes.card}>
          <Box>
            <Stack direction={"column"}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", px: 3 }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-evenly"}
                >
                  <Typography variant="h6">{device.name}</Typography>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    scrollButtons="auto"
                    aria-label="Status tabs"
                  >
                    <Tab
                      sx={{ width: 32, height: 32 }}
                      icon={<Info fontSize="small" />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ width: 32, height: 32 }}
                      icon={<LocationSearching fontSize="small" />}
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{ width: 32, height: 32 }}
                      icon={<Summarize fontSize="small" />}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                  <IconButton size="small" onClick={onClose} color="primary">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>
              <Box>
                <TabPanel index={0} currentIndex={activeTab}>
                  <Stack
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Table size="small" classes={{ root: classes.table }}>
                      <TableBody>
                        <CustomTableRow
                          key="zone"
                          name={t("groupParent")}
                          content={groups[device?.groupId]?.name}
                          icon={<Park />}
                        />
                        <CustomTableRow
                          key="identifier"
                          name={t("deviceIdentifier")}
                          content={device?.uniqueId}
                          icon={<Fingerprint />}
                        />
                        <CustomTableRow
                          key="category"
                          name={t("deviceCategory")}
                          content={t(
                            `category${capitalizeFirstLetter(device?.category)}`
                          )}
                          icon={<Category />}
                        />
                        <CustomTableRow
                          key="lastUpdate"
                          name={t("deviceLastUpdate")}
                          content={formatTime(device?.lastUpdate)}
                          icon={<AccessTime />}
                        />
                        <CustomTableRow
                          key="lastPositionUpdate"
                          name={t("deviceLastPositionUpdate")}
                          content={formatTime(device?.lastPositionUpdate)}
                          icon={<LocationSearching />}
                        />
                        <SensorStatusTableRow
                          t={t}
                          hasError={device?.hasFuelComputationErrors}
                          status={device?.currentFuelSensorsStatus}
                        />
                      </TableBody>
                    </Table>
                    {/* {admin && ( */}
                    <DeviceActionButtons
                      key={"actions"}
                      negative={classes.negative}
                      navigate={navigate}
                      t={t}
                      position={position}
                      readonly={readonly}
                      deviceReadonly={deviceReadonly}
                      setRemoving={setRemoving}
                      createCopy={createCopy}
                      deviceId={deviceId}
                    />
                    {/* )} */}
                  </Stack>
                </TabPanel>
                <TabPanel index={1} currentIndex={activeTab}>
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Table size="small" classes={{ root: classes.table }}>
                      <TableBody>
                        <>
                          {positionItems.map((key) => (
                            <CustomTableRow
                              key={key}
                              icon={positionAttributes[key].icon || <Adjust />}
                              name={positionAttributes[key].name}
                              content={
                                <PositionValue
                                  position={position}
                                  property={
                                    position?.hasOwnProperty(key) ? key : null
                                  }
                                  attribute={
                                    position?.hasOwnProperty(key) ? null : key
                                  }
                                />
                              }
                            />
                          ))}
                        </>
                      </TableBody>
                    </Table>

                    <Box sx={{ p: 1 }}>
                      <Button
                        href={`#/position/${position?.id}`}
                        disabled={!position}
                      >
                        {t("sharedShowDetails")}
                      </Button>
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel index={2} currentIndex={activeTab}>
                  <Stack
                    sx={{ p: 2 }}
                    direction={"column"}
                    justifyContent={"space-evenly"}
                    alignItems={"center"}
                  >
                    <Grid container spacing={2}>
                      {generateReportList(device).map((item) => (
                        <Grid item xs={4}>
                          <Paper
                            elevation={3}
                            sx={{
                              py: 2,
                              px: 1,
                              ":hover": {
                                bgcolor: alpha(theme.palette.primary.main, 0.5),
                                color: "white",
                              },
                            }}
                          >
                            <Link
                              sx={{
                                ":hover": {
                                  color: "white",
                                },
                              }}
                              href={item.link}
                            >
                              <Stack
                                direction={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                spacing={1}
                                sx={{
                                  m: 0,
                                  ":hover": {
                                    color: "white",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.icon}
                                <Typography align="center" variant="body2">
                                  {t(item.title)}
                                </Typography>
                              </Stack>
                            </Link>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                    <Box sx={{ p: 3 }}>
                      <Button href={`#/reports/summary`}>
                        {t("reportGoToAllReports")}
                      </Button>
                    </Box>
                  </Stack>
                </TabPanel>
              </Box>
            </Stack>
          </Box>
        </Paper>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCardTabbed;

import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'events',
  initialState: {
    items: [],
    selectedId: null,
  },
  reducers: {
    add(state, action) {
      state.items.unshift(...action.payload);
    },
    delete(state, action) {
      const event = action.payload;
      state.items = state.items.filter((item) => item.id !== event.id);
    },
    deleteAll(state) {
      state.items = [];
    },
    select(state, action) {
      state.selectedId = action.payload;
    }
  },
});

export { actions as eventsActions };
export { reducer as eventsReducer };

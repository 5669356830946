import { red } from "@material-ui/core/colors";
import { AccessTime, Adjust, LocationOn } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import useQuery from "../common/attributes/useQuery";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import PositionValue from "../common/components/PositionValue";
import {
  distanceFromMeters,
  speedFromKnots,
  volumeFromLiters
} from "../common/util/converter";
import {
  formatNumber,
  formatPercentage,
  formatTime,
  getBatteryIcon
} from "../common/util/formatter";
import { useAttributePreference } from "../common/util/preferences";
import { CustomTableRow } from "../main/StatusCard";
import { useCatch } from "../reactHelper";
import { errorsActions } from "../store";
import useReportStyles from "./common/useReportStyles";
import ReportFilter from "./components/ReportFilter";
import ReportsMenu from "./components/ReportsMenu";

const getAxisYDomain = (data, from, to, ref, offset) => {
  const dateFormat = "MMM Do HH:mm";
  const refData = data.filter((d) => {
    var time = moment(d.fixTime, dateFormat);
    var start = moment(from, dateFormat);
    var end = moment(to, dateFormat);
    return time.isBetween(start, end);
  });
  let [bottom, top] = [refData[0][ref], refData[0][ref]];
  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref];
    if (d[ref] < bottom) bottom = d[ref];
  });

  return [(bottom | 0) - offset, (top | 0) + offset];
};

const CustomTooltip = ({ active, payload, label }) => {
  const t = useTranslation();
  const positionAttributes = usePositionAttributes(t);

  const positionItems = [
    // "type",
    "fuel",
    // "fuelNormalized",
    "ignition",
    // "speed",
    "fuelTank0",
    "fuelTank1",
  ];

  const [data, setData] = useState(null);

  useEffect(() => {
    if (active && payload && payload.length) {
      setData(payload?.[0].payload);
    }
  }, [payload]);

  if (data) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Table size="small">
            <TableBody>
              <CustomTableRow
                icon={<AccessTime />}
                name={t("positionFixTime")}
                content={data.fixTime}
              />
              {/* <CustomTableRow
                icon={getBatteryIcon(data.batteryLevel)}
                name={t("positionBatteryLevel")}
                content={formatPercentage(data.batteryLevel)}
              /> */}
              {positionItems.map((key) => (
                <CustomTableRow
                  key={key}
                  icon={positionAttributes[key].icon || <Adjust />}
                  name={positionAttributes[key].name}
                  content={
                    <PositionValue
                      position={data}
                      property={data.hasOwnProperty(key) ? key : null}
                    />
                  }
                />
              ))}
              <CustomTableRow
                icon={<LocationOn />}
                name={t("sharedLocation")}
                content={`${formatNumber(data.latitude, 2)}, ${formatNumber(
                  data.longitude,
                  2
                )}`}
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return null;
};

const ChartReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const query = useQuery();

  const positionAttributes = usePositionAttributes(t);

  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const volumeUnit = useAttributePreference("volumeUnit");

  const [items, setItems] = useState([]);
  const [type, setType] = useState("fuel");
  const [loading, setLoading] = useState(false);
  const [dataMax, setDataMax] = useState(0);
  const [abortController, setAbortController] = useState(new AbortController());

  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");

  const handleSubmit = useCatch(
    async ({ deviceId, from, to, mail, headers }) => {
      try {
        if (loading) {
          abortController.abort();
        }

        setLoading(true);

        const query = new URLSearchParams({ deviceId, from, to, mail });
        const response = await fetch(`/api/reports/route?${query.toString()}`, {
          headers,
          signal: abortController.signal,
        });

        if (response.ok) {
          const positions = await response.json();
          const formattedPositions = positions.map((position) => {
            const data = { ...position, ...position.attributes };
            const formatted = {};
            // formatted.fixTime = formatTime(position.fixTime, "MMM Do HH:mm");

            Object.keys(data).forEach((key) => {
              const value = data[key];

              const definition = positionAttributes[key] || {};
              switch (key) {
                case "fixTime":
                  formatted[key] = formatTime(value, "MMM Do HH:mm");
                  break;
                case "speed":
                  formatted[key] = speedFromKnots(value, speedUnit).toFixed(2);
                  break;
                case "distance":
                  formatted[key] = distanceFromMeters(
                    value,
                    distanceUnit
                  ).toFixed(2);
                  break;
                case "volume":
                  formatted[key] = volumeFromLiters(value, volumeUnit).toFixed(
                    2
                  );
                  break;
                case "hours":
                  formatted[key] = (value / 1000).toFixed(2);
                  break;
                case "ignition":
                case "type":
                default:
                  formatted[key] = value;
                  break;
              }
            });

            return formatted;
          });
          setItems(formattedPositions);
        } else {
          throw Error(await response.text());
        }
      } catch (e) {
        dispatch(errorsActions.push(e.message));
      } finally {
        setLoading(false);
      }
    }
  );

  useLayoutEffect(() => {
    let maximum = 0;
    items.map((it) => (maximum = it[type] > maximum ? it[type] : maximum));
    maximum += 10;
    setDataMax(maximum);
  });

  useEffect(() => {
    const type = query.get("type");
    if (type) {
      setType(type);
    }
  }, [query]);

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "reportChart"]}
    >
      <ReportFilter handleSubmit={handleSubmit} isFetching={loading} showOnly>
        <div className={classes.filterItem}>
          <FormControl fullWidth>
            <InputLabel>{t("reportChartType")}</InputLabel>
            <Select
              label={t("reportChartType")}
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {Object.keys(positionAttributes)
                .filter((key) => positionAttributes[key].type === "number")
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {positionAttributes[key].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </ReportFilter>
      {(!loading && items.length > 0 && (
        <div className={classes.chart}>
          <ResponsiveContainer>
            <LineChart
              data={items}
              margin={{
                top: 10,
                right: 40,
                left: 0,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="fixTime"
                allowDataOverflow
                minTickGap={10}
                domain={[left, right]}
              />
              <YAxis
                type="number"
                tickFormatter={(value) => value.toFixed(2)}
                domain={[0, "dataMax+20"]}
                allowDataOverflow
                scale="sqrt"
                yAxisId="1"
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                yAxisId="1"
                type="basis"
                stroke="#82ca9d"
                dataKey={type}
                dot={false}
                connectNulls
                animationDuration={300}
              />
              {type === "fuel" && (
                <Line
                  yAxisId="1"
                  stroke={red[500]}
                  type="basis"
                  dataKey={"fuelNormalized"}
                  dot={false}
                  connectNulls
                  animationDuration={300}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )) ||
        (loading && (
          <Box
            sx={{ height: "100%", minHeight: "400" }}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress sx={{ alignSelf: "center" }} size={100} />
          </Box>
        ))}
    </PageLayout>
  );
};

export default ChartReportPage;

import { Battery50, Battery80, BatteryAlert } from "@mui/icons-material";
import React from "react";
import moment from "moment";
import {
  distanceFromMeters,
  distanceUnitString,
  speedFromKnots,
  speedUnitString,
  volumeFromLiters,
  volumeUnitString,
} from "./converter";
import { prefixString } from "./stringUtils";

export const formatBoolean = (value, t) =>
  value ? t("sharedYes") : t("sharedNo");

export const formatNumber = (value, precision = 1, separate = false) => {
  const number = Number(value.toFixed(precision));
  if (separate) {
    return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number;
  }
};

export const formatPercentage = (value) => `${value}%`;

export const formatDate = (value, format = "DD/MM/YY") =>
  moment(value).format(format);
export const formatTime = (value, format = "DD/MM/YY HH:mm:ss") => {
  if (value) {
    return moment(value).format(format);
  }

  return "-";
};

export const formatStatus = (value, t) =>
  t(prefixString("deviceStatus", value));
export const formatAlarm = (value, t) => t(prefixString("alarm", value));

export const formatCourse = (value) => {
  const courseValues = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
  return courseValues[Math.floor(value / 45)];
};

export const formatCourseToName = (value) => {
  const courseNames = {
    N: "North",
    NE: "North East",
    E: "East",
    SE: "South East",
    S: "South",
    SW: "South West",
    W: "West",
    NW: "North West",
  };
  return courseNames[value];
};

export const formatDistance = (value, unit, t) =>
  `${distanceFromMeters(value, unit)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${distanceUnitString(unit, t)}`;

export const formatSpeed = (value, unit, t) =>
  `${speedFromKnots(value, unit).toFixed(2)} ${speedUnitString(unit, t)}`;

export const formatVolume = (value, unit, t) =>
  `${volumeFromLiters(value, unit).toFixed(2)} ${capitalizeFirstLetter(volumeUnitString(unit, t))}`;

export const formatHours = (value) => moment.duration(value).humanize();

export const formatCoordinate = (key, value, unit) => {
  let hemisphere;
  let degrees;
  let minutes;
  let seconds;

  if (key === "latitude") {
    hemisphere = value >= 0 ? "N" : "S";
  } else {
    hemisphere = value >= 0 ? "E" : "W";
  }

  switch (unit) {
    case "ddm":
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = (value - degrees) * 60;
      return `${degrees}° ${minutes.toFixed(6)}' ${hemisphere}`;
    case "dms":
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = Math.floor((value - degrees) * 60);
      seconds = Math.round((value - degrees - minutes / 60) * 3600);
      return `${degrees}° ${minutes}' ${seconds}" ${hemisphere}`;
    default:
      return `${value.toFixed(6)}°`;
  }
};

export const getDeviceStatus = (device, timeDiffLimit) => {
  let lastUpdate = device?.lastUpdate;
  let lastPositionUpdate = device?.lastPositionUpdate;

  const THIRTY_MINUTES_AGO = 30 * 60 * 1000;
  const status = {
    message: "Unknown",
    color: "negative",
  };

  if (lastUpdate) {
    lastUpdate = new Date(lastUpdate);
  } else {
    return status;
  }

  if (lastPositionUpdate) {
    lastPositionUpdate = new Date(lastPositionUpdate);
  } else {
    lastPositionUpdate = new Date(
      lastUpdate.getTime() - (THIRTY_MINUTES_AGO + 5)
    );
  }

  const diff = lastUpdate - lastPositionUpdate;
  let color = "neutral";
  let message = "";

  if (diff > THIRTY_MINUTES_AGO) {
    message = `Last connected ${moment(lastUpdate).fromNow()}`;
  } else {
    message = `Last updated ${moment(lastPositionUpdate).fromNow()}`;
    color = getStatusColor(lastPositionUpdate, timeDiffLimit * 60 * 1000);
  }

  return {
    message,
    color,
  };
};

export const getStatusColor = (updateTime, timeDiffLimit) => {
  const time = moment(updateTime);
  const pastBaseTime = moment(Date.now() - timeDiffLimit);

  if (!time.isAfter(pastBaseTime)) {
    return "neutral";
  } else {
    return "positive";
  }
};

export const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 70) {
    return "positive";
  }
  if (batteryLevel > 30) {
    return "medium";
  }
  return "negative";
};

export const getBatteryIcon = (batteryLevel) => {
  if (batteryLevel >= 80) {
    return <Battery80 color="positive" />;
  } else if (batteryLevel >= 50) {
    return <Battery50 color="warning" />;
  } else {
    return <BatteryAlert color="negative" />;
  }
};

export const portNameFormatter = (port) => {
  if (port.description && port.description.trim().length > 0) {
    return `${port.description} - ${port.portName}`;
  }

  return `${port.portName}`;
};

export const sensorNameFormatter = (sensor) => {
  if (sensor.name && sensor.name.trim().length > 0) {
    return `${sensor.name}${
      sensor.calibrated ? " (Should be calibrated)" : ""
    }`;
  }

  return "";
};

export const createDeviceCopy = (device) => {
  return {
    name: `Copy of ${device.name}`,
    groupId: device.groupId,
    phone: device.phone,
    model: device.model,
    contact: device.contact,
    category: device.category,
    disabled: device.disabled,
    attributes: device.attributes,
  };
};

export const capitalizeFirstLetter = (word) => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1)/* .toLowerCase() */;
  } else {
    return "";
  }
};

export const truncate = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + "…" : str;
};

export const isGPSPositionValid = (position) => {
  if (position?.attributes?.hasOwnProperty("gpsDataAccurate")) {
    return position.attributes.gpsDataAccurate;
  } else {
    return true;
  }
};

import { ArrowBack, Close, CloseOutlined } from "@mui/icons-material";
import { Drawer, Grid, IconButton, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import theme from "../common/theme";
import EventsList from "./EventsList";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  header: {
    flexGrow: 1,
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "white" },
  },
}));

const EventsDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      // hideBackdrop
      PaperProps={{
        elevation: 0,
        sx: {
          background: "transparent",
        },
      }}
    >
      <Grid container>
        <Grid item xs={4} sx={{ pl: 1, pt: 2 }}>
          <IconButton
            color="warning"
            variant="outlined"
            size="small"
            onClick={() => onClose()}
            className={classes.customHoverFocus}
            sx={{
              background: "white",
              position: "absolute",
              top: "1%",
              left: "0.1%",
              zIndex: "tooltip",
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Grid>
        <Grid item xs={8}></Grid>
      </Grid>
      <EventsList pageSize={desktop ? 10 : 8} />
    </Drawer>
  );
};

export default EventsDrawer;

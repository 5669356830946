import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../common/attributes/useQuery";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import TableShimmer from "../common/components/TableShimmer";
import { getColumnValue } from "../common/util/converter";
import {
  formatAlarm,
  formatBoolean,
  formatCoordinate,
  formatCourse,
  formatDate,
  formatDistance,
  formatHours,
  formatNumber,
  formatPercentage,
  formatSpeed,
  formatTime,
  formatVolume,
} from "../common/util/formatter";
import {
  useAttributePreference,
  usePreference,
} from "../common/util/preferences";
import usePersistedState from "../common/util/usePersistedState";
import { useCatch } from "../reactHelper";
import { errorsActions } from "../store";
import useReportStyles from "./common/useReportStyles";
import ColumnSelect from "./components/ColumnSelect";
import ReportFilter from "./components/ReportFilter";
import ReportsMenu from "./components/ReportsMenu";
import ReportTable from "./components/ReportTable";

const columnsArray = [
  // ["deviceId", "deviceIdentifier"],
  ["deviceName", "reportRegistrationNumber"],
  ["model", "deviceModel"],
  ["zone", "reportGroup"],
  ["lastUpdate", "deviceLastUpdate"],
  ["lastPositionUpdate", "deviceLastPositionUpdate"],
  ["noOfTanks", "deviceFuelTanks"],
  ["noOfSensors", "sharedSensors"],
  // ["totalDistance", "deviceTotalDistance"],
  // ["totalFuelSpent", "reportTotalSpentFuel"],
  // ["totalFuelRefilled", "reportTotalRefilledFuel"],
];

const columnsMap = new Map(columnsArray);

const AssetsReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();

  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const coordinateFormat = usePreference("coordinateFormat");
  const volumeUnit = usePreference("volumeUnit");

  const [columns, setColumns] = useState([
    // "deviceId",
    "deviceName",
    "model",
    "zone",
    "lastUpdate",
    "lastPositionUpdate",
    "noOfTanks",
    "noOfSensors",
    // "totalDistance",
    // "totalFuelSpent",
    // "totalFuelRefilled",
  ]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const daily = query.get("daily");
    if (daily) {
      setDaily(true);
    }
  }, [query]);

  const handleSubmit = useCatch(
    async ({ deviceIds, groupIds, from, to, mail, headers }) => {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/assets`, { headers });
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType) {
            if (contentType === "application/json") {
              setItems(await response.json());
            }
          }
        } else {
          throw Error(await response.text());
        }
      } catch (e) {
        dispatch(errorsActions.push(e.message));
      } finally {
        setLoading(false);
      }
    }
  );

  const formatValue = (item, key) => {
    const value = getColumnValue(item, key);

    switch (key) {
      case "fixTime":
      case "deviceTime":
      case "serverTime":
      case "lastUpdate":
      case "lastPositionUpdate":
        return formatTime(value);
      case "captureTime":
        return formatDate(value);
      case "latitude":
        return formatCoordinate("latitude", value, coordinateFormat);
      case "longitude":
        return formatCoordinate("longitude", value, coordinateFormat);
      case "speed":
        return formatSpeed(value, speedUnit, t);
      case "course":
        return formatCourse(value);
      case "batteryLevel":
        return formatPercentage(value);
      case "alarm":
        return formatAlarm(value, t);
      case "odometer":
      case "distance":
      case "totalDistance":
        return formatDistance(value, distanceUnit, t);
      case "hours":
        return formatNumber(value / 1000, 1, true);
      case "fuel":
        return formatVolume(value, volumeUnit, t);
      default:
        if (!value) {
          return "-";
        }
        if (typeof value === "number") {
          return formatNumber(value, 1, true);
        }
        if (typeof value === "boolean") {
          return formatBoolean(value, t);
        }
        return value;
    }
  };

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "sharedAssets"]}
    >
      <div className={classes.header}>
        <ReportFilter
          handleSubmit={handleSubmit}
          noReport={true}
          isFetching={loading}
          ignoreDevice
          noPeriod={true}
        >
          <ColumnSelect
            columns={columns}
            setColumns={setColumns}
            columnsArray={columnsArray}
          />
        </ReportFilter>
      </div>
      <ReportTable
        columns={columns}
        columnsMap={columnsMap}
        isLoading={loading}
        items={items}
        formatValue={formatValue}
        grouping={false}
        title={t("sharedAssets")}
      />
      {/* <Table>
        <TableHead>
          <TableRow>
            {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={(`${item.deviceId}_${Date.parse(item.startTime)}`)}>
              {columns.map((key) => (
                <TableCell key={key}>
                  {formatValue(item, key)}
                </TableCell>
              ))}
            </TableRow>
          )) : (<TableShimmer columns={columns.length} />)}
        </TableBody>
      </Table> */}
    </PageLayout>
  );
};

export default AssetsReportPage;

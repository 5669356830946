import React from "react";
import { Box, Grid, Paper, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TopBar from "../common/components/TopBar";

import { useSelector } from "react-redux";
import EventsStatus from "./components/EventsStatus";
import FuelLevelStatus from "./components/FuelLevelStatus";
import {
  DeviceByGroupsPieChart,
  DeviceByGroupsPieChartVertical,
  DevicesByCategoriesPieChart,
  DevicesPieChart,
  MotionPieChart,
  StatusPieChart,
} from "./components/StatusPieCharts";
import EventsList from "../main/EventsList";
import CollapsibleDrawer from "../common/components/CollapsibleDrawer";
import { useTheme } from "@mui/styles";
import LastUpdatePieChart from "./Dashboard/LastUpdatePieChart";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    backgroundColor: "gray",
  },
  main: {
    position: "absolute",
    // left: 0,
    left: `calc(${theme.spacing(7)} + 10px)`,
    top: 50,
    width: "98.5%",
  },
}));

const DashboardPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className={classes.root}>
      {/* {desktop && <TopBar />} */}
      {desktop && <CollapsibleDrawer />}
      <Box
        // className={classes.main}
        sx={{
          mt: desktop ? 2 : 2,
          mx: 2,
          ml: desktop ? 10 : 2,
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {/* <Grid item xs={4} sm={8} md={6}>
            <Paper>
              <FuelLevelStatus />
            </Paper>
          </Grid> */}

          <Grid item xs={4} sm={8} md={6}>
            <Grid container spacing={1} columns={{ xs: 2, sm: 2, md: 4 }}>
              <Grid item xs={2} sm={2} md={2}>
                <Paper>
                  <DevicesByCategoriesPieChart devices={devices} />
                </Paper>
              </Grid>

              <Grid item xs={2} sm={2} md={2}>
                <Paper>
                  {/* <StatusPieChart devices={devices} /> */}
                  <LastUpdatePieChart devices={devices} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <Paper>
              <DeviceByGroupsPieChartVertical
                devices={devices}
                groups={groups}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12}>
            <EventsList pageSize={5} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DashboardPage;

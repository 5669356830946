import {
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import { getColumnValue } from "../common/util/converter";
import {
  formatAlarm,
  formatBoolean,
  formatCoordinate,
  formatCourse,
  formatDate,
  formatDistance,
  formatNumber,
  formatPercentage,
  formatSpeed,
  formatTime,
  formatVolume,
} from "../common/util/formatter";
import {
  useAttributePreference,
  usePreference,
} from "../common/util/preferences";
import { useCatch, useEffectAsync } from "../reactHelper";
import ReportsMenu from "./components/ReportsMenu";

const columnsArray = [
  ["captureTime", "statisticsCaptureTime"],
  ["activeUsers", "statisticsActiveUsers"],
  ["activeDevices", "statisticsActiveDevices"],
  ["requests", "statisticsRequests"],
  ["messagesReceived", "statisticsMessagesReceived"],
  ["messagesStored", "statisticsMessagesStored"],
  ["mailSent", "notificatorMail"],
  ["smsSent", "notificatorSms"],
  ["geocoderRequests", "statisticsGeocoder"],
  ["geolocationRequests", "statisticsGeolocation"],
];
const columnsMap = new Map(columnsArray);

const DeviceSummaryPage = () => {
  const t = useTranslation();
  const { id } = useParams();

  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const coordinateFormat = usePreference("coordinateFormat");
  const volumeUnit = usePreference("volumeUnit");

  const [device, setDevice] = useState(null);
  const [group, setGroup] = useState(null);
  const [position, setPosition] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [noOfTanks, setNoOfTanks] = useState(0);

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/devices/${id}`);
      if (response.ok) {
        setDevice(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [id]);

  useEffectAsync(async () => {
    // const positionId = device && id ? device.positionId : null;
    const response = await fetch(`/api/positions?deviceId=${id}`);
    if (response.ok) {
      setPosition((await response.json())[0]);
    } else {
      throw Error(await response.text());
    }
  }, [id]);

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/devices/${id}/sensors/`);
      if (response.ok) {
        setSensors(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [id]);

  useEffectAsync(async () => {
    const groupId = device && id ? device.groupId : null;
    if (groupId) {
      const response = await fetch(`/api/groups/${groupId}`);
      if (response.ok) {
        setGroup(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [device]);

  useEffect(() => {
    const countMap = {};
    if (sensors && sensors.length > 0) {
      sensors.map((sensor) => countMap[sensor.groupNo]++);
    }

    setNoOfTanks(Object.keys(countMap).length);
  }, [sensors]);

  const formatValue = (item, key) => {
    const value = getColumnValue(item, key);

    switch (key) {
      case "fixTime":
      case "deviceTime":
      case "serverTime":
      case "lastUpdate":
      case "lastPositionUpdate":
        return formatTime(value);
      case "captureTime":
        return formatDate(value);
      case "latitude":
        return formatCoordinate("latitude", value, coordinateFormat);
      case "longitude":
        return formatCoordinate("longitude", value, coordinateFormat);
      case "speed":
        return formatSpeed(value, speedUnit, t);
      case "course":
        return formatCourse(value);
      case "batteryLevel":
        return formatPercentage(value);
      case "alarm":
        return formatAlarm(value, t);
      case "odometer":
      case "distance":
      case "totalDistance":
        return formatDistance(value, distanceUnit, t);
      case "hours":
        return formatNumber(value / 1000, 1, true);
      case "fuel":
        return formatVolume(value, volumeUnit, t);
      default:
        if (!value) {
          return "-";
        }
        if (typeof value === "number") {
          return formatNumber(value, 1, true);
        }
        if (typeof value === "boolean") {
          return formatBoolean(value, t);
        }
        return value;
    }
  };

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "statisticsTitle"]}
    >
      <Container sx={{ my: 4 }}>
        <Paper sx={{ p: 4 }}>
          <Stack spacing={2}>
            <Grid container>
              <Grid item>
                <Typography variant="h4">{device?.name}</Typography>
              </Grid>
            </Grid>
            <Table>
              <TableRow>
                <TableCell>
                  <b>Model</b>
                </TableCell>
                <TableCell>{formatValue(device, "model")}</TableCell>
                <TableCell>
                  <b>Last Update</b>
                </TableCell>
                <TableCell>
                  {formatValue(device, "lastPositionUpdate")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Zone</b>
                </TableCell>
                <TableCell>{group?.name}</TableCell>
                <TableCell>
                  <b>Last Connection</b>
                </TableCell>
                <TableCell>{formatValue(device, "lastUpdate")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Number of Tanks</b>
                </TableCell>
                <TableCell>{noOfTanks}</TableCell>
                <TableCell>
                  <b>Installed Sensors</b>
                </TableCell>
                <TableCell>{sensors.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Total Distance Travelled</b>
                </TableCell>
                <TableCell>{formatValue(position, "totalDistance")}</TableCell>
                <TableCell>
                  <b>Current Battery Level</b>
                </TableCell>
                <TableCell>{formatValue(position, "batteryLevel")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Total Fuel Spent</b>
                </TableCell>
                <TableCell>{formatValue(position, "totalFuelSpent")}</TableCell>
                <TableCell>
                  <b>Total Fuel Refilled</b>
                </TableCell>
                <TableCell>
                  {formatValue(position, "totalFuelRefilled")}
                </TableCell>
              </TableRow>
            </Table>
          </Stack>
        </Paper>
      </Container>
    </PageLayout>
  );
};

export default DeviceSummaryPage;

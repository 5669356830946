import React from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  formatAlarm,
  formatBoolean,
  formatCoordinate,
  formatCourse,
  formatDistance,
  formatNumber,
  formatPercentage,
  formatSpeed,
  formatTime,
  formatVolume,
} from "../util/formatter";
import { useAttributePreference, usePreference } from "../util/preferences";
import { useTranslation } from "./LocalizationProvider";
import { useAdministrator } from "../util/permissions";
import AddressValue from "./AddressValue";

const PositionValue = ({ position, property, attribute }) => {
  if (!(attribute || property)) {
    return "-";
  }

  const t = useTranslation();

  const admin = useAdministrator();

  const key = property || attribute;
  const value = property  && position ? position[property] : position?.attributes[attribute];

  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const coordinateFormat = usePreference("coordinateFormat");

  const formatValue = (value) => {
    switch (key) {
      case "fixTime":
      case "deviceTime":
      case "serverTime":
        return formatTime(value);
      case "latitude":
        return formatCoordinate("latitude", value, coordinateFormat);
      case "longitude":
        return formatCoordinate("longitude", value, coordinateFormat);
      case "speed":
        return formatSpeed(value, speedUnit, t);
      case "course":
        return formatCourse(value);
      case "batteryLevel":
        return formatPercentage(value);
      case "alarm":
        return formatAlarm(value, t);
      case "odometer":
      case "distance":
      case "totalDistance":
        return formatDistance(value, distanceUnit, t);
      case "hours":
        return formatNumber(value / 1000);
      case "fuel":
      case "fuelTank0":
      case "fuelTank1":
      case "fuelTank2":
      case "fuelTank3":
        return value ? formatVolume(value, "ltr", t) : "-";
      default:
        if (typeof value === "number") {
          return formatNumber(value);
        }
        if (typeof value === "boolean") {
          return formatBoolean(value, t);
        }
        if (typeof value === "object") {
          return JSON.stringify(value, null, " ");
        }
        return value || "";
    }
  };

  switch (key) {
    case "totalDistance":
    case "hours":
      return (
        <>
          {formatValue(value)}
          &nbsp;&nbsp;
          {admin && (
            <Link
              component={RouterLink}
              to={`/settings/accumulators/${position?.deviceId}`}
            >
              &#9881;
            </Link>
          )}
        </>
      );
    case "address":
      return (
        <AddressValue
          latitude={position?.latitude}
          longitude={position?.longitude}
          originalAddress={value}
        />
      );
    case "network":
      if (value) {
        return (
          <Link component={RouterLink} to={`/network/${position?.id}`}>
            {t("sharedInfoTitle")}
          </Link>
        );
      }
      return "";
    default:
      return formatValue(value);
  }
};

export default PositionValue;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AltRoute from "@mui/icons-material/AltRoute";
import BarChart from "@mui/icons-material/BarChart";
import Language from "@mui/icons-material/TrackChanges";
import AssistantDirection from "@mui/icons-material/AssistantDirection";
import Mail from "@mui/icons-material/Mail";
import MapIcon from "@mui/icons-material/Map";
import Assessment from "@mui/icons-material/Assessment";
import Terrain from "@mui/icons-material/Terrain";
import CarRental from "@mui/icons-material/CarRental";
import Workspaces from "@mui/icons-material/Workspaces";
import AdbIcon from "@mui/icons-material/Alarm";
import Notifications from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import { height } from "@mui/system";
import Person from "@mui/icons-material/Person";

import makeStyles from "@mui/styles/makeStyles";
import Settings from "@mui/icons-material/Settings";
import { useTranslation } from "./LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import { sessionActions } from "../../store";
import { useAdministrator, useReadonly } from "../util/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  navbar: {
    minHeight: "40px",
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const title = "TPDF";
  const t = useTranslation();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.session.user?.id);
  const admin = useAdministrator();
  const readonly = useReadonly();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAccount = () => {
    setAnchorEl(null);
    navigate(`/settings/user/${userId}`);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await fetch("/api/session", { method: "DELETE" });
    navigate("/login");
    dispatch(sessionActions.updateUser(null));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <AppBar className={classes.navbar}>
      <Toolbar className={classes.navbar} variant="dense">
        <Typography variant="h6" noWrap>
          {title}
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
          <IconButton
            sx={{ ml: 10 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/")}
          >
            <BarChart />
            <Typography ml={0.5}>Dashboard</Typography>
          </IconButton>

          <IconButton
            sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/monitoring")}
          >
            <Language />
            <Typography ml={0.5}>Monitoring</Typography>
          </IconButton>

          <IconButton
            sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/replay")}
          >
            <AssistantDirection />
            <Typography ml={0.5}>Tracks</Typography>
          </IconButton>

          <IconButton
            sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/reports/summary")}
          >
            <Assessment />
            <Typography ml={0.5}>Reports</Typography>
          </IconButton>

          <IconButton
            sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/geofences")}
          >
            <Terrain />
            <Typography ml={0.5}>Geofences</Typography>
          </IconButton>

          <IconButton
            sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/settings/drivers")}
          >
            <CarRental />
            <Typography ml={0.5}>Drivers</Typography>
          </IconButton>

          {!readonly && (
            <>
              <IconButton
                sx={{ ml: 2 }}
                color="inherit"
                edge="end"
                onClick={() => navigate("/settings/users")}
              >
                <Person />
                <Typography ml={0.5}>Users</Typography>
              </IconButton>

              <IconButton
                sx={{ ml: 2 }}
                color="inherit"
                edge="end"
                onClick={() => navigate("/settings/groups")}
              >
                <Workspaces />
                <Typography ml={0.5}>Zones</Typography>
              </IconButton>
            </>
          )}

          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}

          {/* <IconButton
              sx={{ ml: "auto" }}
              color="inherit"
              edge="end"
              onClick={() => navigate("/reports/event")}
            >
              <Notifications />
            </IconButton> */}
          <IconButton
            sx={{ ml: "auto" }}
            // sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={() => navigate("/settings/preferences")}
          >
            <Settings />
          </IconButton>

          <IconButton
            sx={{ ml: 2 }}
            color="inherit"
            edge="end"
            onClick={handleClick}
            aria-controls={anchorEl ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleAccount}>
              <Typography color="textPrimary">{t("settingsUser")}</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography color="error">{t("loginLogout")}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { calibrationsReducer as calibrations } from "./calibrations";
import { dashboardReducer as dashboard } from "./dashboard";
import { devicesReducer as devices } from "./devices";
import { driversReducer as drivers } from "./drivers";
import { errorsReducer as errors } from "./errors";
import { eventsReducer as events } from "./events";
import { geofencesReducer as geofences } from "./geofences";
import { groupsReducer as groups } from "./groups";
import { maintenancesReducer as maintenances } from "./maintenances";
import { positionsReducer as positions } from "./positions";
import { readingTypesReducer as readingTypes } from "./readingTypes";
import { sensorsReducer as sensors } from "./sensors";
import { sessionReducer as session } from "./session";
import { deviceClassesReducer as deviceClasses } from "./deviceClasses";
import throttleMiddleware from "./throttleMiddleware";

const reducer = combineReducers({
  errors,
  session,
  devices,
  positions,
  events,
  geofences,
  groups,
  drivers,
  maintenances,
  calibrations,
  sensors,
  readingTypes,
  dashboard,
  deviceClasses,
});

export { calibrationsActions } from "./calibrations";
export { dashboardActions } from "./dashboard";
export { devicesActions } from "./devices";
export { driversActions } from "./drivers";
export { errorsActions } from "./errors";
export { eventsActions } from "./events";
export { geofencesActions } from "./geofences";
export { groupsActions } from "./groups";
export { maintenancesActions } from "./maintenances";
export { positionsActions } from "./positions";
export { readingTypesActions } from "./readingTypes";
export { sensorsActions } from "./sensors";
export { sessionActions } from "./session";
export { deviceClassesActions } from "./deviceClasses";

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(throttleMiddleware),
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
  Icon,
  Tooltip,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TableHead,
  Link,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import { useTranslation } from "../common/components/LocalizationProvider";
import RemoveDialog from "../common/components/RemoveDialog";
import PositionValue from "../common/components/PositionValue";
import dimensions from "../common/theme/dimensions";
import {
  useAdministrator,
  useDeviceReadonly,
  useReadonly,
} from "../common/util/permissions";
import usePersistedState from "../common/util/usePersistedState";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import { devicesActions } from "../store";
import { useCatch } from "../reactHelper";
import {
  Adjust,
  ContentCopy,
  Google,
  Settings,
  Timeline,
} from "@mui/icons-material";
import {
  capitalizeFirstLetter,
  createDeviceCopy,
  formatDate,
  formatTime,
} from "../common/util/formatter";
import styled from "@emotion/styled";

const useStyles = makeStyles((theme) => ({
  card: {
    width: dimensions.popupMaxWidth,
  },
  replay: {
    width: 288,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 1, 1, 2),
  },
  content: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: "25px",
    height: "25px",
    filter: "brightness(0) invert(1)",
  },
  table: {
    "& .MuiTableCell-sizeSmall": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    // borderBottom: "none",
  },
  actions: {
    justifyContent: "space-between",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const CustomTableRow = ({ icon, name, content }) => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCell className={classes.cell}>
        <Icon color="primary">{icon ? icon : ""}</Icon>
      </TableCell>
      {name && (
        <TableCell className={classes.cell}>
          <Typography variant="body2">{name}</Typography>
        </TableCell>
      )}
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
};

function DeviceActionButtons(props) {
  const {
    t,
    readonly,
    deviceReadonly,
    deviceId,
    navigate,
    negative,
    createCopy,
    setRemoving,
    position,
  } = props;

  return (
    <CustomTableRow
      content={
        <Stack direction={"row"} spacing={0.5}>
          <Tooltip title={`Google Maps`}>
            <IconButton
              href={`https://www.google.com/maps/search/?api=1&query=${position?.latitude}%2C${position?.longitude}`}
              target={"_blank"}
            >
              <Google />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t("sharedDeviceAccumulators")}`}>
            <IconButton
              onClick={() => navigate(`/settings/accumulators/${deviceId}`)}
              disabled={readonly}
            >
              <Settings />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t("commandSend")} ${t("commandTitle")}`}>
            <IconButton
              onClick={() => navigate(`/settings/command-send/${deviceId}`)}
              disabled={readonly}
            >
              <PublishIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("sharedEdit")}>
            <IconButton
              onClick={() => navigate(`/settings/device/${deviceId}`)}
              disabled={deviceReadonly}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={t("sharedClone")}>
            <IconButton
              disabled={deviceReadonly}
              onClick={() => {
                createCopy();
                navigate("/settings/device/");
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip> */}
          <Tooltip title={t("sharedRemove")}>
            <IconButton
              onClick={() => setRemoving(true)}
              disabled={deviceReadonly}
              className={negative}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    />
  );
}

const StatusCard = ({ deviceId, onClose, currentPosition, isReplay }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const admin = useAdministrator();

  const readonly = useReadonly();
  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);
  const position = currentPosition
    ? currentPosition
    : useSelector((state) => state.positions.items[deviceId]);

  const positionAttributes = usePositionAttributes(t);
  const commonPositionItems = [
    "deviceTime",
    "fuel",
    "fuelTank0",
    "fuelTank1",
    "gpsDataAccurate",
  ];
  const [staticPositionItems] = useState([...commonPositionItems, "hours"]);
  const [dynamicPositionItems] = useState([...commonPositionItems, "speed"]);
  const [positionItems, setPositionItems] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch("/api/devices");
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  useEffect(() => {
    if (device) {
      updatePositionAttributes();
    }
  });

  const updatePositionAttributes = () => {
    if (
      device.attributes.hasOwnProperty("isStatic") &&
      device.attributes.isStatic
    ) {
      setPositionItems(staticPositionItems);
    } else {
      setPositionItems(dynamicPositionItems);
    }
  };

  const createCopy = () => {
    dispatch(devicesActions.copy(createDeviceCopy(device)));
  };

  return (
    <>
      {device && (
        <Card
          elevation={3}
          className={isReplay ? classes.replay : classes.card}
        >
          <div className={classes.header}>
            <Grid container spacing={0.5}>
              <Grid xs={isReplay ? 12 : 5} item>
                <Typography variant="h6">
                  {device.name} <i>({device?.uniqueId})</i>
                </Typography>
              </Grid>
              <Grid xs={5} item></Grid>
              {!isReplay && (
                <Grid xs={2} item>
                  <Typography variant="h6">Reports</Typography>
                </Grid>
              )}
            </Grid>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <CardContent className={classes.content}>
            <Grid container spacing={2}>
              {!isReplay && (
                <Grid xs={5} item>
                  <Table size="small" classes={{ root: classes.table }}>
                    <TableBody>
                      <CustomTableRow
                        key="category"
                        name={t("deviceCategory")}
                        content={t(
                          `category${capitalizeFirstLetter(device?.category)}`
                        )}
                      />
                      <CustomTableRow
                        key="model"
                        name={t("deviceModel")}
                        content={device?.model}
                      />
                      <CustomTableRow
                        key="lastUpdate"
                        name={t("deviceLastUpdate")}
                        content={formatTime(device?.lastUpdate)}
                      />
                      <CustomTableRow
                        key="lastPositionUpdate"
                        name={t("deviceLastPositionUpdate")}
                        content={formatTime(device?.lastPositionUpdate)}
                      />
                      {admin && (
                        <DeviceActionButtons
                          key={"actions"}
                          negative={classes.negative}
                          navigate={navigate}
                          t={t}
                          position={position}
                          readonly={readonly}
                          deviceReadonly={deviceReadonly}
                          setRemoving={setRemoving}
                          createCopy={createCopy}
                          deviceId={deviceId}
                        />
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              <Grid xs={isReplay ? 12 : 4} item>
                <Table size="small" classes={{ root: classes.table }}>
                  <TableBody>
                    {/* {position && ( */}
                    <>
                      {positionItems.map((key) => (
                        <CustomTableRow
                          key={key}
                          icon={positionAttributes[key].icon || <Adjust />}
                          name={positionAttributes[key].name}
                          content={
                            <PositionValue
                              position={position}
                              property={
                                position?.hasOwnProperty(key) ? key : null
                              }
                              attribute={
                                position?.hasOwnProperty(key) ? null : key
                              }
                            />
                          }
                        />
                      ))}
                      {!isReplay && position && (
                        <CustomTableRow
                          key={"moreDetails"}
                          content={
                            <Link href={`#/position/${position.id}`}>
                              {t("sharedShowDetails")}
                            </Link>
                          }
                        />
                      )}
                    </>
                    {/* )} */}
                  </TableBody>
                </Table>
              </Grid>
              {!isReplay && (
                <Grid xs={3} item>
                  <Table size="small" classes={{ root: classes.table }}>
                    <TableBody>
                      <CustomTableRow
                        content={
                          <Link href="#/reports/chart?type=fuel&prefetch=true&period=today">
                            {t("sharedFuelCharts")}
                          </Link>
                        }
                      />
                      <CustomTableRow
                        content={
                          <Link href="#/replay?period=today&prefetch=false">
                            {t("reportReplay")}
                          </Link>
                        }
                      />
                      <CustomTableRow
                        content={
                          <Link href="#/reports/logs?period=today&prefetch=true">
                            {t("sharedMovementReport")}
                          </Link>
                        }
                      />
                      <CustomTableRow
                        content={
                          <Link href="#/reports/summary?period=thisWeek&prefetch=true&daily=true">
                            {t("sharedWeeklySummary")}
                          </Link>
                        }
                      />
                      <CustomTableRow
                        content={
                          <Link href="#/reports/summary?period=thisMonth&prefetch=true&daily=true">
                            {t("sharedMonthlySummary")}
                          </Link>
                        }
                      />
                      <CustomTableRow
                        content={
                          <Link href={`#/reports/device/${device?.id}`}>
                            {t("sharedDeviceSummary")}
                          </Link>
                        }
                      />
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </CardContent>
          {(!currentPosition && (
            <CardActions
              classes={{ root: classes.actions }}
              disableSpacing
            ></CardActions>
          )) ||
            (admin && (
              <CardActions>
                <Button
                  size="small"
                  onClick={() =>
                    window.open(`#/position/${currentPosition.id}`, "_blank")
                  }
                >
                  {t("sharedShowDetails")}
                </Button>
              </CardActions>
            ))}
        </Card>
      )}
      {position && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}>
            <Typography color="secondary">{t("sharedShowDetails")}</Typography>
          </MenuItem>
          <MenuItem
            component="a"
            target="_blank"
            navigate
            href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}
          >
            {t("linkGoogleMaps")}
          </MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCard;

import { IconButton, Paper, Toolbar, Typography } from "@mui/material";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideNav from "../common/components/SideNav";
import TopBar from "../common/components/TopBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "../common/components/LocalizationProvider";
import { useEffectAsync } from "../reactHelper";
import CollectionFab from "../settings/components/CollectionFab";
import { Add } from "@mui/icons-material";
import CollectionActions from "../settings/components/CollectionActions";
import CollapsibleDrawer from "../common/components/CollapsibleDrawer";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: 3,
    left: 0,
    top: 40,
    margin: theme.spacing(1.5),
    // width: theme.dimensions.drawerWidthDesktop,
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const TripsPage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch("/trips");
    if (response.ok) {
      setItems(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, [timestamp]);

  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      <CollapsibleDrawer />
      <div className={classes.sidebar}>
        <Paper elevation={3} square>
          <Toolbar>
            <IconButton onClick={() => navigate("/")}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("sharedTrips")}
            </Typography>
            <IconButton onClick={() => navigate("/trip")}>
              <Add />
            </IconButton>
          </Toolbar>
        </Paper>
        <Paper className={classes.content} square>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnAction} />
                  <TableCell>{t("sharedDeviceName")}</TableCell>
                  <TableCell>{t("sharedTripName")}</TableCell>
                  <TableCell>{t("sharedDescription")}</TableCell>
                  <TableCell>{t("sharedFrom")}</TableCell>
                  <TableCell>{t("sharedTo")}</TableCell>
                  <TableCell>{t("sharedGeofence")}</TableCell>
                  <TableCell>{t("sharedDriver")}</TableCell>
                  <TableCell>{t("sharesCalendar")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.columnAction} padding="none">
                      <CollectionActions
                        itemId={item.id}
                        editPath="/settings/fuel-sensors/port"
                        endpoint="trips"
                        setTimestamp={setTimestamp}
                      />
                    </TableCell>
                    <TableCell>{item.deviceId}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      {item.startingLatitude + "&&" + item.finalLatitude}
                    </TableCell>
                    <TableCell>
                      {item.startingLongitude + "&&" + item.finalLongitude}
                    </TableCell>
                    <TableCell>{item.geofenceId}</TableCell>
                    <TableCell>{item.driverId}</TableCell>
                    <TableCell>{item.calendarId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default TripsPage;

import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

import { AccordionActions } from "@material-ui/core";
import { ContentCopy, RestartAlt } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useCommonDeviceAttributes from "../common/attributes/useCommonDeviceAttributes";
import useDeviceAttributes from "../common/attributes/useDeviceAttributes";
import LinkField from "../common/components/LinkField";
import { useTranslation } from "../common/components/LocalizationProvider";
import SelectField from "../common/components/SelectField";
import deviceCategories from "../common/util/deviceCategories";
import { createDeviceCopy } from "../common/util/formatter";
import { useAdministrator } from "../common/util/permissions";
import { prefixString } from "../common/util/stringUtils";
import useFeatures from "../common/util/useFeatures";
import { devicesActions, errorsActions } from "../store";
import EditAttributesView from "./components/EditAttributesView";
import EditItemView from "./components/EditItemView";
import EditSensorsView from "./components/EditSensorsView";
import SettingsMenu from "./components/SettingsMenu";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();

  const admin = useAdministrator();

  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);
  const dispatch = useDispatch();

  const features = useFeatures();
  const { id } = useParams();

  const deviceCopy = useSelector((state) => state.devices.deviceCopy);

  const [item, setItem] = useState();
  const [sensorsUrl, setSensorsUrl] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [addedSensors, setAddedSensors] = useState([]);
  const [updatedSensors, setUpdatedSensors] = useState([]);
  const [deletedSensors, setDeletedSensors] = useState([]);
  const [headers, setHeaders] = useState({
    "Content-Type": "application/json",
  });

  useEffect(() => {
    if (deviceCopy && !id) {
      setItem(deviceCopy);
      setSensors([]);
    }
  }, [id]);

  const createCopy = () => {
    dispatch(devicesActions.copy(createDeviceCopy(item)));
  };

  const validate = () => item && item.name && item.uniqueId;

  const resetDevice = async () => {
    try {
      const response = await fetch(`/api/devices/${id}/reset`, {
        method: "POST",
        headers,
      });

      if (response.ok) {
        const deviceItems = await response.json();
        dispatch(devicesActions.refresh(deviceItems));
        navigate(-1);
      } else {
        throw new Error(await response.text());
      }
    } catch (e) {
      dispatch(errorsActions.push(e.message));
    }
  };

  const fetchSensors = async (method, data) => {
    try {
      const response = await fetch(sensorsUrl, {
        method: method,
        headers: headers,
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSensors(await response.json());
      } else {
        throw new Error(await response.text());
      }
    } catch (e) {
      dispatch(errorsActions.push(e.message));
    }
  };

  const saveSensors = async () => {
    try {
      if (addedSensors.length > 0) {
        fetchSensors("POST", addedSensors);
      }

      if (updatedSensors.length > 0) {
        const changedSensors = sensors.filter((s) =>
          updatedSensors.includes(s.id)
        );
        fetchSensors("PUT", changedSensors);
      }

      if (deletedSensors.length > 0) {
        fetchSensors("DELETE", deletedSensors);
      }
    } catch (e) {
      dispatch(errorsActions.push(e.message));
    }
  };

  useEffect(() => {
    if (item && item.id) {
      setSensorsUrl(`/api/devices/${item.id}/sensors/`);
    } else {
      setSensorsUrl(null);
    }
  }, [item]);

  useEffect(() => {
    if (sensorsUrl && item) {
      fetchSensors("GET");
    }
  }, [sensorsUrl]);

  return (
    <EditItemView
      endpoint="devices"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={["sharedDevice"]}
      onItemSaved={() => {
        saveSensors();
        // navigate(`/settings/device/${item.id}`);
      }}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                label={t("sharedName")}
              />
              <TextField
                value={item.uniqueId || ""}
                onChange={(event) =>
                  setItem({ ...item, uniqueId: event.target.value })
                }
                label={t("deviceIdentifier")}
              />
            </AccordionDetails>
            {item.id && (
              <AccordionActions>
                <IconButton
                  color="warning"
                  title={t("sharedResetDevice")}
                  onClick={() => {
                    resetDevice();
                  }}
                >
                  <RestartAlt />
                </IconButton>
                <IconButton
                  color="warning"
                  title={t("sharedClone")}
                  onClick={() => {
                    createCopy();
                    navigate("/settings/device/");
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </AccordionActions>
            )}
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedExtra")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.externalIdentifier || ""}
                onChange={(event) =>
                  setItem({ ...item, externalIdentifier: event.target.value })
                }
                label={t("sharedExternalIdentifier")}
              />
              <SelectField
                value={item.classId || 0}
                onChange={(event) =>
                  setItem({ ...item, classId: Number(event.target.value) })
                }
                endpoint="/api/device-classes"
                label={t("sharedDeviceClass")}
              />
              <SelectField
                value={item.groupId || 0}
                onChange={(event) =>
                  setItem({ ...item, groupId: Number(event.target.value) })
                }
                endpoint="/api/groups"
                label={t("groupParent")}
              />
              <TextField
                value={item.phone || ""}
                onChange={(event) =>
                  setItem({ ...item, phone: event.target.value })
                }
                label={t("sharedPhone")}
              />
              <TextField
                value={item.model || ""}
                onChange={(event) =>
                  setItem({ ...item, model: event.target.value })
                }
                label={t("deviceModel")}
              />
              <TextField
                value={item.contact || ""}
                onChange={(event) =>
                  setItem({ ...item, contact: event.target.value })
                }
                label={t("deviceContact")}
              />
              <SelectField
                value={item.category || "default"}
                emptyValue={null}
                onChange={(event) =>
                  setItem({ ...item, category: event.target.value })
                }
                data={deviceCategories.map((category) => ({
                  id: category,
                  name: t(
                    `category${category.replace(/^\w/, (c) => c.toUpperCase())}`
                  ),
                }))}
                label={t("deviceCategory")}
              />

              {admin && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.disabled}
                      onChange={(event) =>
                        setItem({ ...item, disabled: event.target.checked })
                      }
                    />
                  }
                  label={t("sharedDisabled")}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t("sharedAttributes")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <EditAttributesView
                attributes={item.attributes}
                setAttributes={(attributes) => setItem({ ...item, attributes })}
                definitions={{ ...commonDeviceAttributes, ...deviceAttributes }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedSensors")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <EditSensorsView
                item={item}
                sensors={sensors}
                setSensors={setSensors}
                addedSensors={addedSensors}
                setAddedSensors={setAddedSensors}
                updatedSensors={updatedSensors}
                setUpdatedSensors={setUpdatedSensors}
                deletedSensors={deletedSensors}
                setDeletedSensors={setDeletedSensors}
              />
            </AccordionDetails>
          </Accordion>
          {item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t("sharedConnections")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  endpointAll="/api/geofences"
                  endpointLinked={`/api/geofences?deviceId=${item.id}`}
                  baseId={item.id}
                  keyBase="deviceId"
                  keyLink="geofenceId"
                  label={t("sharedGeofences")}
                />
                <LinkField
                  endpointAll="/api/notifications"
                  endpointLinked={`/api/notifications?deviceId=${item.id}`}
                  baseId={item.id}
                  keyBase="deviceId"
                  keyLink="notificationId"
                  titleGetter={(it) => t(prefixString("event", it.type))}
                  label={t("sharedNotifications")}
                />
                {!features.disableDrivers && (
                  <LinkField
                    endpointAll="/api/drivers"
                    endpointLinked={`/api/drivers?deviceId=${item.id}`}
                    baseId={item.id}
                    keyBase="deviceId"
                    keyLink="driverId"
                    label={t("sharedDrivers")}
                  />
                )}
                {!features.disableComputedAttributes && (
                  <LinkField
                    endpointAll="/api/attributes/computed"
                    endpointLinked={`/api/attributes/computed?deviceId=${item.id}`}
                    baseId={item.id}
                    keyBase="deviceId"
                    keyLink="attributeId"
                    titleGetter={(it) => it.description}
                    label={t("sharedComputedAttributes")}
                  />
                )}
                <LinkField
                  endpointAll="/api/commands"
                  endpointLinked={`/api/commands?deviceId=${item.id}`}
                  baseId={item.id}
                  keyBase="deviceId"
                  keyLink="commandId"
                  titleGetter={(it) => it.description}
                  label={t("sharedSavedCommands")}
                />
                {!features.disableMaintenance && (
                  <LinkField
                    endpointAll="/api/maintenance"
                    endpointLinked={`/api/maintenance?deviceId=${item.id}`}
                    baseId={item.id}
                    keyBase="deviceId"
                    keyLink="maintenanceId"
                    label={t("sharedMaintenance")}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </EditItemView>
  );
};

export default DevicePage;

import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "../common/components/LocalizationProvider";
import {
  capitalizeFirstLetter,
  formatBoolean,
  formatCourse,
  formatNumber,
  formatVolume,
  getDeviceStatus,
} from "../common/util/formatter";
import { devicesActions } from "../store";

import {
  East,
  Error,
  HorizontalRule,
  North,
  NorthEast,
  NorthWest,
  South,
  SouthEast,
  SouthWest,
  West,
} from "@mui/icons-material";
import AlarmOnOutlined from "@mui/icons-material/AlarmOnOutlined";
import FlashOff from "@mui/icons-material/FlashOff";
import FlashOn from "@mui/icons-material/FlashOn";
import FlashOnOutlined from "@mui/icons-material/FlashOnOutlined";
import LocalGasStationOutlined from "@mui/icons-material/LocalGasStationOutlined";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import LockOpenOutlined from "@mui/icons-material/LockOpenOutlined";
import Moving from "@mui/icons-material/Moving";
import MovingOutlined from "@mui/icons-material/MovingOutlined";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import {
  Avatar,
  Badge,
  IconButton,
  ListItemAvatar,
  ListItemText,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridAutoSizer } from "@mui/x-data-grid";
import usePersistedState from "../common/util/usePersistedState";
import { mapIcons } from "../map/core/preloadImages";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "25px",
    height: "25px",
    filter: "brightness(0)",
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  warning: {
    color: theme.palette.colors.warning,
  },
  indicators: {
    lineHeight: 1,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // backgroundColor: "#44b700",
    // color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 4.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const renderIconFromId = (id, tooltip, t) => {
  const classes = {
    normal: "",
  };

  var element = null;

  if (id == "ignition") {
    element = <FlashOnOutlined fontSize="small" className={classes.normal} />;
  } else if (id == "alarm") {
    element = <AlarmOnOutlined fontSize="small" className={classes.normal} />;
  } else if (id == "lock") {
    element = <LockOpenOutlined fontSize="small" className={classes.normal} />;
  } else if (id == "driver") {
    element = <PersonOutlined fontSize="small" className={classes.normal} />;
  } else if (id == "fuel") {
    element = (
      <LocalGasStationOutlined fontSize="small" className={classes.normal} />
    );
  } else if (id == "location") {
    element = (
      <LocationOnOutlined fontSize="small" className={classes.normal} />
    );
  } else if (id == "motion") {
    element = <MovingOutlined fontSize="small" className={classes.normal} />;
  } else if (id == "course") {
    element = <NorthEast fontSize="small" className={classes.normal} />;
  }

  return (
    <Tooltip title={tooltip ? t(tooltip) : "Description"}>
      <IconButton size="small">{element}</IconButton>
    </Tooltip>
  );
};

const renderCourseIcon = (course, classes) => {
  switch (course) {
    case "N":
      return <North fontSize="small" className={classes.positive} />;
    case "NE":
      return <NorthEast fontSize="small" className={classes.positive} />;
    case "E":
      return <East fontSize="small" className={classes.positive} />;
    case "SE":
      return <SouthEast fontSize="small" className={classes.positive} />;
    case "S":
      return <South fontSize="small" className={classes.positive} />;
    case "SW":
      return <SouthWest fontSize="small" className={classes.positive} />;
    case "W":
      return <West fontSize="small" className={classes.positive} />;
    case "NW":
      return <NorthWest fontSize="small" className={classes.positive} />;

    default:
      break;
  }
};

const IgnitionCell = ({ position, classes, t }) => {
  return (
    (position && position.attributes.hasOwnProperty("ignition") && (
      <Tooltip
        title={`${t("positionIgnition")}: ${formatBoolean(
          position.attributes.ignition,
          t
        )}`}
      >
        <IconButton size="small">
          {position.attributes.ignition ? (
            <FlashOn fontSize="small" className={classes.positive} />
          ) : (
            <FlashOff fontSize="small" className={classes.neutral} />
          )}
        </IconButton>
      </Tooltip>
    )) || (
      <Tooltip title={`${t("positionIgnition")}: ${t("attributeUnavailable")}`}>
        <IconButton size="small">
          <Error fontSize="small" className={classes.negative} />
        </IconButton>
      </Tooltip>
    )
  );
};

const MotionCell = ({ position, classes, t }) => {
  return position && position.attributes.hasOwnProperty("motion") ? (
    <Tooltip
      title={`${t("eventDeviceMoving")}: ${formatBoolean(
        position.attributes.motion,
        t
      )}`}
    >
      <IconButton size="small">
        {position.attributes.motion ? (
          <Moving fontSize="small" className={classes.positive} />
        ) : (
          <Moving fontSize="small" className={classes.neutral} />
        )}
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={`${t("positionIgnition")}: ${t("attributeUnavailable")}`}>
      <IconButton size="small">
        <HorizontalRule fontSize="small" className={classes.negative} />
      </IconButton>
    </Tooltip>
  );
};

const CourseCell = ({ position, classes, t }) => {
  return (
    (position && position.hasOwnProperty("course") && (
      <Tooltip
        title={`${t("positionCourse")}: ${formatCourse(position.course, t)}`}
      >
        <IconButton size="small">
          {renderCourseIcon(formatCourse(position.course), classes)}
        </IconButton>
      </Tooltip>
    )) || (
      <Tooltip title={`${t("positionIgnition")}: ${t("attributeUnavailable")}`}>
        <IconButton size="small">
          <HorizontalRule fontSize="small" className={classes.negative} />
        </IconButton>
      </Tooltip>
    )
  );
};

const FuelLevelCell = ({ device, position, t }) => {
  const [fuelTanks, setFuelTanks] = useState([]);
  const [fuelLevel, setFuelLevel] = useState();
  const [trustValue, setTrustValue] = useState(true);

  useEffect(() => {
    if (position && position.attributes) {
      setFuelTanks(
        Object.keys(position.attributes).filter((key) => key.match(/fuelTank/))
      );
    }
  }, [position]);

  useEffect(() => {
    if (position) {
      if (position.attributes.hasOwnProperty("fuel")) {
        setFuelLevel(position.attributes.fuel);
        setTrustValue(true);
      } else if (
        position.hasFuelComputationError &&
        (position.attributes.hasOwnProperty("fuelTank0") ||
          position.attributes.hasOwnProperty("fuelTank1"))
      ) {
        setFuelLevel(
          (position.attributes.fuelTank0 || 0) +
            (position.attributes.fuelTank1 || 0)
        );
        setTrustValue(false);
      } else if (device && device.attributes.hasOwnProperty("fuel")) {
        setFuelLevel(device.attributes.fuel);
        setTrustValue(false);
      }
    }
  }, [device, position]);

  return (
    (fuelLevel && (
      <Tooltip
        title={`${t("positionFuel")}: ${formatNumber(fuelLevel, 2)} ${t(
          "positionFuelLitres"
        )}`}
      >
        <ListItemText
          sx={{ textAlign: "center" }}
          secondary={` ${
            fuelTanks.length > 1 ? `(${fuelTanks.length} Tanks)` : ""
          }`}
        >
          <Typography
            color={trustValue ? "primary" : "error"}
            variant="button"
          >{`${formatVolume(formatNumber(fuelLevel, 2), "L", t)}`}</Typography>
        </ListItemText>
      </Tooltip>
    )) || (
      <Tooltip title={`${t("positionFuel")}: ${t("attributeUnavailable")}`}>
        <IconButton size="small">
          <HorizontalRule fontSize="small" />
        </IconButton>
      </Tooltip>
    )
  );
};

const FuelTankCell = ({ tankKey, position, t }) => {
  return (
    (position && position.attributes.hasOwnProperty(tankKey) && (
      <Tooltip
        title={`${t("positionFuel")}: ${formatNumber(
          position.attributes[tankKey],
          2
        )} ${t("positionFuelLitres")}`}
      >
        <ListItemText sx={{ textAlign: "center" }}>
          <Typography variant="button">{`${formatVolume(
            formatNumber(position.attributes[tankKey], 2),
            "L",
            t
          )}`}</Typography>
        </ListItemText>
      </Tooltip>
    )) || (
      <Tooltip title={`${t("positionFuel")}: ${t("attributeUnavailable")}`}>
        <IconButton size="small">
          <HorizontalRule fontSize="small" />
        </IconButton>
      </Tooltip>
    )
  );
};

const DevicesGrid = ({ filteredItems }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const positions = useSelector((state) => state.positions.items);
  const [timeDiffLimit] = usePersistedState("timeDiffLimit", 5);

  const HEADER_WIDTH = 60;

  const columns = [
    {
      field: "avatar",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: (params) => {
        const category = params.row.category;
        const isOnline = params.row.status === "online";
        const badgeColor = isOnline ? "success" : "error";

        return (
          <Tooltip
            placement="right"
            title={
              category
                ? t(`category${capitalizeFirstLetter(category)}`)
                : t("deviceStatusUnknown")
            }
          >
            <ListItemAvatar>
              <StyledBadge
                overlap="circular"
                color={badgeColor}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar>
                  <img
                    className={classes.icon}
                    src={category ? mapIcons[category] : mapIcons["default"]}
                    alt=""
                  />
                </Avatar>
              </StyledBadge>
            </ListItemAvatar>
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 220,
      renderCell: (params) => {
        const device = params.row;

        const status = getDeviceStatus(device, timeDiffLimit);

        return (
          <ListItemText
            primary={device.name}
            primaryTypographyProps={{ noWrap: true }}
            secondary={status.message}
            secondaryTypographyProps={{ noWrap: true }}
            classes={{
              secondary: classes[status.color],
            }}
          />
        );
      },
    },
    {
      field: "fuel",
      headerName: "Fuel",
      width: HEADER_WIDTH + 30,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => {
        return renderIconFromId("fuel", "positionFuel", t);
      },
      renderCell: (params) => {
        const position = positions[params.row.id];
        const device = params.row;

        return (
          <FuelLevelCell
            position={position}
            classes={classes}
            t={t}
            device={device}
          />
        );
      },
    },
    {
      field: "fuelTank0",
      headerName: "Fuel Tank 1",
      width: HEADER_WIDTH + 40,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => {
        return <>{renderIconFromId("fuel", "positionFuel", t)} 1</>;
      },
      renderCell: (params) => {
        const position = positions[params.row.id];
        return (
          <FuelTankCell
            tankKey={"fuelTank0"}
            position={position}
            classes={classes}
            t={t}
          />
        );
      },
    },
    {
      field: "fuelTank1",
      headerName: "Fuel Tank 2",
      width: HEADER_WIDTH + 40,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => {
        return <>{renderIconFromId("fuel", "positionFuel", t)} 2</>;
      },
      renderCell: (params) => {
        const position = positions[params.row.id];
        return (
          <FuelTankCell
            tankKey={"fuelTank1"}
            position={position}
            classes={classes}
            t={t}
          />
        );
      },
    },
    {
      field: "ignition",
      headerName: "Ignition",
      width: HEADER_WIDTH,
      sortable: false,
      editable: false,
      renderHeader: () => {
        return renderIconFromId("ignition", "positionIgnition", t);
      },
      renderCell: (params) => {
        const position = positions[params.row.id];
        return <IgnitionCell position={position} classes={classes} t={t} />;
      },
    },
    {
      field: "motion",
      headerName: "Motion",
      width: HEADER_WIDTH,
      sortable: false,
      renderHeader: () => {
        return renderIconFromId("motion", "eventDeviceMoving", t);
      },
      renderCell: (params) => {
        const position = positions[params.row.id];
        return <MotionCell position={position} classes={classes} t={t} />;
      },
    },
    {
      field: "course",
      headerName: "Course",
      width: HEADER_WIDTH,
      sortable: false,
      renderHeader: () => {
        return renderIconFromId("course", "positionCourse", t);
      },
      renderCell: (params) => {
        const position = positions[params.row.id];
        return <CourseCell position={position} classes={classes} t={t} />;
      },
    },
  ];

  return (
    <GridAutoSizer>
      {({ height, width }) => (
        <div style={{ height: height, width: width }}>
          <DataGrid
            sx={{
              p: 2,
            }}
            rows={Object.values(filteredItems)}
            columns={columns}
            disableColumnMenu={true}
            disableColumnSelector={true}
            isRowSelectable={() => true}
            hideFooterSelectedRowCount={true}
            onRowClick={(params) => {
              dispatch(devicesActions.select(params.row.id));
            }}
          />
        </div>
      )}
    </GridAutoSizer>
  );
};

export default DevicesGrid;

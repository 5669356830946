import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined";
import GpsFixed from "@material-ui/icons/GpsFixed";
import LocationSearching from "@material-ui/icons/LocationSearching";
import { Box, ListItemText, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../common/components/LocalizationProvider";
import {
  formatDate,
  formatNumber,
  formatSpeed,
  formatVolume,
} from "../common/util/formatter";
import { useAttributePreference } from "../common/util/preferences";
import { prefixString } from "../common/util/stringUtils";
import useReportStyles from "../reports/common/useReportStyles";
import { eventsActions } from "../store";
import tableIcons from "./MateriaTableIcons";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  header: {
    flexGrow: 1,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: 800,
  p: 2,
};

const EventsList = ({ pageSize = 5 }) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();

  const events = useSelector((state) => state.events.items);
  const devices = useSelector((state) => state.devices.items);

  const speedUnit = useAttributePreference("speedUnit");

  const tableRef = useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [eventPosition, setEventPosition] = useState(null);
  const [enableGrouping, setEnableGrouping] = useState(false);
  const [openPopup, setOpenPopup] = useState(null);

  const secondaryTextGetter = (event) => {
    var text = ``;
    const attributes = event.attributes;
    if (event.positionId) {
      switch (event.type) {
        case "deviceMoving":
        case "deviceStopped":
        case "deviceOverspeed":
          return `Speed: ${formatSpeed(attributes.speed, speedUnit, t)}`;

        case "deviceFuelDrop":
        case "deviceFuelRefill":
        case "ignitionOn":
        case "ignitionOff":
        case "driverChanged":
          return `
          Fuel: ${formatVolume(attributes.fuel, null, t)}, 
          Fuel Used: ${formatVolume(attributes.fuelUsed, null, t)}`;

        case "alarm":
          return `
            Power: ${formatNumber(attributes.power, 2)}V , 
            Battery: ${formatNumber(attributes.battery, 2)}V`;
        case "sensorError":
          return `
          Sensor Error: ${attributes.fuelSensorError}
          Sensor Status: ${attributes.fuelSensorStatus}
          `;

        default:
          return event.type;
      }
    }

    return text;
  };

  const columns = [
    {
      field: "deviceId",
      title: "Device ID",
      type: "numeric",
      sorting: true,
      align: "right",
      hidden: true,
    },
    {
      field: "deviceName",
      title: "Device Name",
      type: "string",
      sorting: true,
      align: "left",
    },
    {
      field: "eventTime",
      title: "Time",
      type: "numeric",
      sorting: true,
      align: "left",
      defaultSort: "desc",
      render: (row) => {
        return <Typography>{formatDate(row.eventTime)}</Typography>;
      },
    },
    {
      field: "positionId",
      title: "Position ID",
      type: "numeric",
      sorting: false,
      align: "center",
      export: false,
      hidden: true,
    },
    {
      field: "attributes",
      title: "Attributes",
      type: "string",
      sorting: false,
      align: "center",
      export: false,
      hidden: true,
    },
    {
      field: "type",
      title: "Event",
      type: "string",
      sorting: false,
      align: "left",
      export: false,
      render: (row) => {
        return (
          <ListItemText
            primary={t(prefixString("event", row.type))}
            primaryTypographyProps={{ noWrap: true }}
            secondary={secondaryTextGetter(row)}
            secondaryTypographyProps={{ noWrap: true }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setItems(
      events.map((e) => ({ ...e, deviceName: devices[e.deviceId].name }))
    );
  }, [events]);

  useEffect(() => {
    if (selectedItem) {
      const event = items.find((item) => item.id === selectedItem);
      setEventPosition({
        deviceId: event.deviceId,
        latitude: event.attributes.latitude,
        longitude: event.attributes.longitude,
      });
      dispatch(eventsActions.select(selectedItem));
      const popupRef = window.open(
        `#/popup/${event.positionId}`,
        "eventPopUp",
        "popup=true,width=500,height=500,left=0,top=0,"
      );
      setOpenPopup(popupRef);
    } else {
      if (openPopup) {
        openPopup.close();
        setOpenPopup(null);
      }
      setEventPosition(null);
    }
  }, [selectedItem]);

  return (
    <div>
      <Box sx={{ p: 1 }}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={items}
          title={"Events"}
          icons={tableIcons}
          options={{
            pageSize: pageSize,
            draggable: true,
            padding: "dense",
            searchFieldVariant: "outlined",
            searchFieldStyle: {
              accentColor: theme.palette.primary,
            },
            minBodyHeight: "100%",
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: "#f2f2f2" };
              }
            },
          }}
          actions={[
            {
              icon: DeleteForeverOutlined,
              tooltip: "Clear Events",
              isFreeAction: true,
              onClick: (event) => dispatch(eventsActions.deleteAll()),
            },
            (rowData) => ({
              icon: rowData.positionId
                ? selectedItem && selectedItem == rowData.id
                  ? GpsFixed
                  : LocationSearching
                : "",
              tooltip: "Show on map",
              onClick: (event, rowData) => {
                if (selectedItem && selectedItem == rowData.id) {
                  setSelectedItem(null);
                } else {
                  setSelectedItem(rowData.id);
                }
              },
            }),
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: "",
              labelRowsPerPage: " Events per page",
            },
            toolbar: {
              nRowsSelected: "{0} row(s) selected",
            },
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage: "No Events to display",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
        />
      </Box>
    </div>
  );
};

export default EventsList;

import React, { useContext, useEffect, useState } from "react";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import {
  formatAlarm,
  formatBoolean,
  formatCoordinate,
  formatCourse,
  formatDistance,
  formatNumber,
  formatPercentage,
  formatSpeed,
  formatTime,
  formatVolume,
} from "../common/util/formatter";

import { PaginationContext } from "../common/context/PaginationContext";
import { getColumnValue } from "../common/util/converter";
import {
  useAttributePreference,
  usePreference,
} from "../common/util/preferences";
import usePersistedState from "../common/util/usePersistedState";
import MapView from "../map/core/MapView";
import MapPositions from "../map/MapPositions";
import MapRoutePath from "../map/MapRoutePath";
import { useCatch } from "../reactHelper";
import useReportStyles from "./common/useReportStyles";
import ColumnSelect from "./components/ColumnSelect";
import ReportFilter from "./components/ReportFilter";
import ReportsMenu from "./components/ReportsMenu";
import ReportTable from "./components/ReportTable";

const columnsArray = [
  ["startTime", "reportStartTime"],
  ["startOdometer", "reportStartOdometer"],
  ["startAddress", "reportStartAddress"],
  ["endTime", "reportEndTime"],
  ["endOdometer", "reportEndOdometer"],
  ["endAddress", "reportEndAddress"],
  ["distance", "sharedDistance"],
  ["averageSpeed", "reportAverageSpeed"],
  ["maxSpeed", "reportMaximumSpeed"],
  ["duration", "reportDuration"],
  ["spentFuel", "reportSpentFuel"],
  ["driverName", "sharedDriver"],
  ["type", "sharedType"],
];
const columnsMap = new Map(columnsArray);

const LogsReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const { limit, pageSize, setPagineable, setMaximumPages } =
    useContext(PaginationContext);

  const positionAttributes = usePositionAttributes(t);
  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const coordinateFormat = usePreference("coordinateFormat");
  const volumeUnit = usePreference("volumeUnit");

  const [columns, setColumns] = usePersistedState("routeColumns", [
    "fixTime",
    "latitude",
    "longitude",
    "speed",
    "address",
    "fuel",
  ]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [lastId, setLastId] = useState(0);

  const formatValue = (item, key) => {
    const value = getColumnValue(item, key);

    switch (key) {
      case "fixTime":
      case "deviceTime":
      case "serverTime":
        return formatTime(value);
      case "latitude":
        return formatCoordinate("latitude", value, coordinateFormat);
      case "longitude":
        return formatCoordinate("longitude", value, coordinateFormat);
      case "speed":
        return formatSpeed(value, speedUnit, t);
      case "course":
        return formatCourse(value);
      case "batteryLevel":
        return formatPercentage(value);
      case "alarm":
        return formatAlarm(value, t);
      case "odometer":
      case "distance":
      case "totalDistance":
        return formatDistance(value, distanceUnit, t);
      case "hours":
        return formatNumber(value / 1000);
      case "fuel":
        return formatVolume(value, volumeUnit, t);
      default:
        if (typeof value === "number") {
          return formatNumber(value);
        }
        if (typeof value === "boolean") {
          return formatBoolean(value, t);
        }
        return value;
    }
  };

  const handleSubmit = useCatch(
    async ({ deviceId, from, to, mail, headers }) => {
      setLoading(true);
      try {
        const query = new URLSearchParams({
          deviceId,
          from,
          to,
          mail,
          lastId,
          limit: limit + pageSize,
        });
        const response = await fetch(`/api/reports/route?${query.toString()}`, {
          headers,
        });
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType) {
            if (contentType === "application/json") {
              const responseData = await response.json();
              setItems([...items, ...responseData]);
            } else {
              window.location.assign(
                window.URL.createObjectURL(await response.blob())
              );
            }
          }
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  );

  setPagineable(true);

  useEffect(() => {
    if (items && items.length > 0) {
      setLastId(items[items.length - 1]?.id);
    }
  }, [items]);

  useEffect(() => {
    if (items && items.length > 0) {
      setMaximumPages(Math.ceil(items.length / pageSize));
    }
  }, [items, lastId]);

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "reportRoute"]}
      pagineable={true}
    >
      <div className={classes.container}>
        {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapRoutePath positions={items} />
              <MapPositions positions={[selectedItem]} />
            </MapView>
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter
              handleSubmit={handleSubmit}
              isFetching={loading}
              noReport={true}
            >
              <ColumnSelect
                columns={columns}
                setColumns={setColumns}
                columnsObject={positionAttributes}
              />
            </ReportFilter>
          </div>
          <ReportTable
            title={t("reportRoute")}
            columns={columns}
            columnsMap={null}
            isLoading={loading}
            items={items}
            formatValue={formatValue}
            grouping={false}
            showMapAction
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default LogsReportPage;

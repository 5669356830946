import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import { capitalizeFirstLetter } from "../common/util/formatter";
import { useEffectAsync } from "../reactHelper";
import CollectionActions from "./components/CollectionActions";
import CollectionFab from "./components/CollectionFab";
import SettingsMenu from "./components/SettingsMenu";

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const DeviceClassesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch("/api/device-classes");
    if (response.ok) {
      setItems(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, [timestamp]);

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={["settingsTitle", "sharedDeviceClasses"]}
    >
      <Card sx={{ m: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("sharedName")}</TableCell>
              <TableCell>{t("sharedAttributes")}</TableCell>
              <TableCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {Object.entries(item.attributes)
                    .map(
                      (e) =>
                        `${t(`attribute${capitalizeFirstLetter(e[0])}`)}: ${
                          e[1]
                        }`
                    )
                    .join(",")}
                </TableCell>
                <TableCell className={classes.columnAction} padding="none">
                  <CollectionActions
                    itemId={item.id}
                    editPath="/settings/device-class"
                    endpoint="device-classes"
                    setTimestamp={setTimestamp}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <CollectionFab editPath="/settings/device-class" />
    </PageLayout>
  );
};

export default DeviceClassesPage;

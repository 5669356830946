import React from "react";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import RouteIcon from "@mui/icons-material/Route";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "../../common/components/LocalizationProvider";
import { useAdministrator } from "../../common/util/permissions";
import { Leaderboard } from "@mui/icons-material";

const MenuItem = ({ title, link, icon, selected }) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const admin = useAdministrator();

  return (
    <>
      <List>
        <MenuItem
          title={t("reportLogs")}
          link="/reports/logs"
          icon={<TimelineIcon />}
          selected={location.pathname === "/reports/logs"}
        />
        <MenuItem
          title={t("reportEvents")}
          link="/reports/event"
          icon={<NotificationsActiveIcon />}
          selected={location.pathname === "/reports/event"}
        />
        <MenuItem
          title={t("reportTrips")}
          link="/reports/trip"
          icon={<PlayCircleFilledIcon />}
          selected={location.pathname === "/reports/trip"}
        />
        <MenuItem
          title={t("reportStops")}
          link="/reports/stop"
          icon={<PauseCircleFilledIcon />}
          selected={location.pathname === "/reports/stop"}
        />
        <MenuItem
          title={t("reportSummary")}
          link="/reports/summary"
          icon={<FormatListBulletedIcon />}
          selected={location.pathname === "/reports/summary"}
        />
        <MenuItem
          title={t("reportChart")}
          link="/reports/chart"
          icon={<TrendingUpIcon />}
          selected={location.pathname === "/reports/chart"}
        />
        <MenuItem
          title={t("reportTracks")}
          link="/replay"
          icon={<RouteIcon />}
        />
      </List>
      {admin && (
        <>
          <Divider />
          <List>
            <MenuItem
              title={t("sharedAssets")}
              link="/reports/assets"
              icon={<Leaderboard />}
              selected={location.pathname === "/reports/assets"}
            />
            <MenuItem
              title={t("statisticsTitle")}
              link="/reports/statistics"
              icon={<BarChartIcon />}
              selected={location.pathname === "/reports/statistics"}
            />
          </List>
        </>
      )}
    </>
  );
};

export default ReportsMenu;

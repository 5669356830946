import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../common/attributes/useQuery";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import {
  formatDistance,
  formatNumber,
  formatSpeed,
  formatTime,
  formatVolume,
} from "../common/util/formatter";
import { useAttributePreference } from "../common/util/preferences";
import { useCatch } from "../reactHelper";
import { errorsActions } from "../store";
import useReportStyles from "./common/useReportStyles";
import ColumnSelect from "./components/ColumnSelect";
import ReportFilter from "./components/ReportFilter";
import ReportsMenu from "./components/ReportsMenu";
import ReportTable from "./components/ReportTable";

const columnsArray = [
  ["deviceId", "sharedDevice"],
  ["startTime", "reportStartDate"],
  ["distance", "sharedDistance"],
  ["startOdometer", "reportStartOdometer"],
  ["endOdometer", "reportEndOdometer"],
  ["averageSpeed", "reportAverageSpeed"],
  ["maxSpeed", "reportMaximumSpeed"],
  ["engineHours", "reportEngineHours"],
  ["spentFuel", "reportSpentFuel"],
  ["refilledFuel", "reportRefilledFuel"],
  // ["numberOfRefills", "reportNumberOfRefills"],
  ["startFuel", "reportStartFuel"],
  ["endFuel", "reportEndFuel"],
];
const columnsMap = new Map(columnsArray);

const SummaryReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();

  const devices = useSelector((state) => state.devices.items);

  const distanceUnit = useAttributePreference("distanceUnit");
  const speedUnit = useAttributePreference("speedUnit");
  const volumeUnit = useAttributePreference("volumeUnit");

  const [columns, setColumns] = useState(
    /* "summaryColumns",  */ [
      "deviceId",
      "startTime",
      "distance",
      "averageSpeed",
      "engineHours",
      "spentFuel",
      "refilledFuel",
      // "numberOfRefills",
      "startFuel",
      "endFuel",
    ]
  );
  const [daily, setDaily] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const daily = query.get("daily");
    if (daily) {
      setDaily(true);
    }
  }, [query]);

  const handleSubmit = useCatch(
    async ({ deviceIds, groupIds, from, to, mail, headers }) => {
      setLoading(true);
      try {
        const query = new URLSearchParams({ from, to, daily, mail });
        deviceIds.forEach((deviceId) => {
          if (deviceId) {
            query.append("deviceId", deviceId);
          }
        });
        groupIds.forEach((groupId) => query.append("groupId", groupId));
        const response = await fetch(
          `/api/reports/v2/summary?${query.toString()}`,
          { headers }
        );
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType) {
            if (contentType === "application/json") {
              setItems(await response.json());
            } else {
              window.location.assign(
                window.URL.createObjectURL(await response.blob())
              );
            }
          }
        } else {
          throw Error(await response.text());
        }
      } catch (e) {
        dispatch(errorsActions.push(e.message));
      } finally {
        setLoading(false);
      }
    }
  );

  const formatValue = (item, key) => {
    switch (key) {
      case "deviceId":
        return devices[item[key]].name;
      case "startTime":
        return item[key] ? formatTime(item[key], "DD-MM-YYYY") : null;
      case "startOdometer":
      case "endOdometer":
      case "distance":
        return formatDistance(item[key], distanceUnit, t);
      case "averageSpeed":
      case "maxSpeed":
        return formatSpeed(item[key], speedUnit, t);
      case "engineHours":
        const duration = moment.duration(item[key]);

        if (duration.hours() > 0) {
          return `${formatNumber(duration.asHours(), 2)} hour(s)`;
        }

        if (duration.minutes() > 0) {
          return `${formatNumber(duration.asMinutes(), 2)} minutes(s)`;
        }

        return `${formatNumber(duration.asSeconds(), 2)} seconds`;

      case "spentFuel":
      case "endFuel":
      case "startFuel":
      case "refilledFuel":
        return formatVolume(item[key], volumeUnit, t);
      default:
        return item[key];
    }
  };

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "reportSummary"]}
    >
      <div className={classes.header}>
        <ReportFilter
          handleSubmit={handleSubmit}
          noReport={true}
          isFetching={loading}
          multiDevice
        >
          <div className={classes.filterItem}>
            <FormControl fullWidth>
              <InputLabel>{t("sharedType")}</InputLabel>
              <Select
                label={t("sharedType")}
                value={daily}
                onChange={(e) => setDaily(e.target.value)}
              >
                <MenuItem value={false}>{t("reportSummary")}</MenuItem>
                <MenuItem value>{t("reportDaily")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <ColumnSelect
            columns={columns}
            setColumns={setColumns}
            columnsArray={columnsArray}
          />
        </ReportFilter>
      </div>
      <ReportTable
        columns={columns}
        columnsMap={columnsMap}
        isLoading={loading}
        items={items}
        formatValue={formatValue}
        grouping={false}
        title={t("reportSummary")}
      />
      {/* <Table>
        <TableHead>
          <TableRow>
            {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={(`${item.deviceId}_${Date.parse(item.startTime)}`)}>
              {columns.map((key) => (
                <TableCell key={key}>
                  {formatValue(item, key)}
                </TableCell>
              ))}
            </TableRow>
          )) : (<TableShimmer columns={columns.length} />)}
        </TableBody>
      </Table> */}
    </PageLayout>
  );
};

export default SummaryReportPage;

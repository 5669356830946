import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  speedLimit: {
    name: t('attributeSpeedLimit'),
    type: 'number',
    subtype: 'speed',
  },
  'report.ignoreOdometer': {
    name: t('attributeReportIgnoreOdometer'),
    type: 'boolean',
  },
  fuelDropThreshold: {
    name: t('attributeFuelDropThreshold'),
    type: 'number',
    subtype: 'ltrPerHour'
  },
  fuelDropPerWithinKmThreshold: {
    name: t('attributeFuelDropThresholdKmPerLtr'),
    subtype: 'kmPerLtr'
  },
  fuelRefillThreshold: {
    name: t('attributeFuelRefillThreshold'),
    type: 'number',
    subtype: 'ltrPerHour'
  },
  fuelRefillPerWithinKmThreshold: {
    name: t('attributeFuelRefillThresholdKmPerLtr'),
    type: 'number',
    subtype: 'kmPerLtr'
  },
  isStatic: {
    name: t('attributeIsStatic'),
    type: 'boolean'
  }
}), [t]);

import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../common/util/formatter";
import ReportFilter from "./components/ReportFilter";
import { prefixString } from "../common/util/stringUtils";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import ReportsMenu from "./components/ReportsMenu";
import usePersistedState from "../common/util/usePersistedState";
import ColumnSelect from "./components/ColumnSelect";
import { useCatch } from "../reactHelper";
import useReportStyles from "./common/useReportStyles";
import TableShimmer from "../common/components/TableShimmer";
import ReportTable from "./components/ReportTable";
import MapView from "../map/core/MapView";
import MapRoutePath from "../map/MapRoutePath";
import MapPositions from "../map/MapPositions";
import { errorsActions } from "../store";
import { getColumnValue } from "../common/util/converter";

const typesArray = [
  ["allEvents", "eventAll"],
  ["sensorError", "eventSensorError"],
  ["deviceFuelRefill", "eventDeviceFuelRefill"],
  ["deviceFuelDrop", "eventDeviceFuelDrop"],
  ["deviceOnline", "eventDeviceOnline"],
  ["deviceUnknown", "eventDeviceUnknown"],
  ["deviceOffline", "eventDeviceOffline"],
  ["deviceInactive", "eventDeviceInactive"],
  ["deviceMoving", "eventDeviceMoving"],
  ["deviceStopped", "eventDeviceStopped"],
  ["deviceOverspeed", "eventDeviceOverspeed"],
  ["commandResult", "eventCommandResult"],
  ["geofenceEnter", "eventGeofenceEnter"],
  ["geofenceExit", "eventGeofenceExit"],
  ["alarm", "eventAlarm"],
  ["ignitionOn", "eventIgnitionOn"],
  ["ignitionOff", "eventIgnitionOff"],
  ["maintenance", "eventMaintenance"],
  ["textMessage", "eventTextMessage"],
  ["driverChanged", "eventDriverChanged"],
];

const columnsArray = [
  ["eventTime", "positionFixTime"],
  ["type", "sharedType"],
  ["geofenceId", "sharedGeofence"],
  ["maintenanceId", "sharedMaintenance"],
  ["alarm", "positionAlarm"],
  ["fuelSensorError", "sharedSensorErrorMessage"],
  ["fuelSensorStatus", "sharedSensorStatus"],
];
const columnsMap = new Map(columnsArray);

const EventReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const geofences = useSelector((state) => state.geofences.items);

  const [columns, setColumns] = usePersistedState("eventColumns", [
    "eventTime",
    "type",
    "alarm",
  ]);
  const [eventTypes, setEventTypes] = useState(["allEvents"]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [position, setPosition] = useState(null);

  const handleSubmit = useCatch(
    async ({ deviceId, from, to, mail, headers }) => {
      setLoading(true);
      try {
        const query = new URLSearchParams({ deviceId, from, to, mail });
        eventTypes.forEach((it) => query.append("type", it));
        const response = await fetch(
          `/api/reports/events?${query.toString()}`,
          { headers }
        );
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType) {
            if (contentType === "application/json") {
              setItems(await response.json());
            } else {
              window.location.assign(
                window.URL.createObjectURL(await response.blob())
              );
            }
          }
        } else {
          throw Error(await response.text());
        }
      } catch (e) {
        dispatch(errorsActions.push(e.message));
      } finally {
        setLoading(false);
      }
    }
  );

  const formatValue = (item, key) => {
    const value = getColumnValue(item, key);

    switch (key) {
      case "eventTime":
        return formatTime(value);
      case "type":
        return t(prefixString("event", value));
      case "geofenceId":
        if (value > 0) {
          const geofence = geofences[value];
          return geofence && geofence.name;
        }
        return null;
      case "maintenanceId":
        return value > 0 ? value > 0 : null;
      case "alarm":
        return value ? t(prefixString("alarm", value)) : null;
      default:
        return value;
    }
  };

  useEffect(() => {
    try {
      if (selectedItem) {
        const event = items.find((item) => item.id === selectedItem.id);
        if (event.attributes.latitude && event.attributes.longitude) {
          setPosition({
            deviceId: event.deviceId,
            latitude: event.attributes.latitude,
            longitude: event.attributes.longitude,
          });
        } else {
          throw Error("The event does not have position attributes");
        }
      }
    } catch (e) {
      dispatch(errorsActions.push(e.message));
    }
  }, [selectedItem]);

  return (
    <PageLayout
      menu={<ReportsMenu />}
      breadcrumbs={["reportTitle", "reportEvents"]}
    >
      <div className={classes.container}>
        {selectedItem && position && (
          <div className={classes.containerMap}>
            <MapView>
              <MapRoutePath positions={[position]} />
              <MapPositions positions={[position]} />
            </MapView>
          </div>
        )}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportFilter handleSubmit={handleSubmit} noReport>
              <div className={classes.filterItem}>
                <FormControl fullWidth>
                  <InputLabel>{t("reportEventTypes")}</InputLabel>
                  <Select
                    label={t("reportEventTypes")}
                    value={eventTypes}
                    onChange={(event, child) => {
                      let values = event.target.value;
                      const clicked = child.props.value;
                      if (values.includes("allEvents") && values.length > 1) {
                        values = [clicked];
                      }
                      setEventTypes(values);
                    }}
                    multiple
                  >
                    {typesArray.map(([key, string]) => (
                      <MenuItem key={key} value={key}>
                        {t(string)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <ColumnSelect
                columns={columns}
                setColumns={setColumns}
                columnsArray={columnsArray}
              />
            </ReportFilter>
          </div>
          <ReportTable
            columns={columns}
            columnsMap={columnsMap}
            isLoading={loading}
            items={items}
            formatValue={formatValue}
            grouping={false}
            title={t("reportEvents")}
            showMapAction
            selectedItem={selectedItem}
            setSelectedItem={(item) => {
              setSelectedItem(item);
            }}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default EventReportPage;

import React, { useEffect, useState, forwardRef } from "react";
import {
  FormControl,
  Autocomplete,
  TextField,
  Button,
  Chip,
  Avatar,
  Grid,
  Skeleton,
  Input,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import SettingsMenu from "./components/SettingsMenu";
import { useDispatch, useSelector } from "react-redux";
import { calibrationsActions, errorsActions } from "../store";
import dimensions from "../common/theme/dimensions";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { AttachFile } from "@mui/icons-material";
import { DropzoneDialog } from "material-ui-dropzone";

import Papa from "papaparse";
import { Box } from "@mui/system";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  filter: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2, 2),
  },
  item: {
    flex: `1 1 ${dimensions.filterFormWidth}`,
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    flex: `1 1 ${dimensions.filterFormWidth}`,
  },
  button: {
    flexGrow: 1,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      bottom: dimensions.bottomBarHeight + theme.spacing(2),
    },
  },
}));

const refreshCalibrations = async (dispatch, id) => {
  const response = await fetch(`/api/devices/${id}/calibrations`);
  if (response.ok) {
    console.log("updated calibrations");
    dispatch(calibrationsActions.refresh(await response.json()));
  } else {
    throw Error(await response.text());
  }
};

const saveCalibrationChange = async (
  calibration,
  dispatch,
  deleteCalibration
) => {
  const endpoint = "calibrations";
  let url = `/api/${endpoint}`;
  if (calibration.id) {
    url += `/${calibration.id}`;
  }

  const response = await fetch(url, {
    method: !calibration.id ? "POST" : deleteCalibration ? "DELETE" : "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(calibration),
  });

  try {
    if (response.ok) {
      refreshCalibrations(dispatch, calibration.deviceId);
    } else {
      console.log(
        `Failed to ${
          !calibration.id ? "POST" : deleteCalibration ? "DELETE" : "PUT"
        }: ${calibration.voltage}mV - ${calibration.fuelLevel}L`
      );
      console.log(calibration);
      throw Error(await response.text());
    }
  } catch (e) {
    dispatch(errorsActions.push(e.message));
  }
};

const importCalibrations = async (
  calibrations,
  deviceId,
  dispatch,
  onFinish
) => {
  try {
    if (deviceId > 0) {
      const url = `/api/devices/${deviceId}/calibrations`;
      console.log(calibrations);
      if (calibrations) {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(calibrations),
        });

        if (response.ok) {
          refreshCalibrations(dispatch, deviceId);
          onFinish();
        } else {
          throw Error(await response.text());
        }
      }
    }
  } catch (e) {
    dispatch(errorsActions.push(e.message));
  }
};

const FuelCalibrationsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devices.items);
  const calibrations = useSelector((state) => state.calibrations.items);
  const sensors = useSelector((state) => state.sensors.items);
  const readingTypes = useSelector((state) => state.readingTypes.items);

  const [selectedDeviceId, setSelectedDeviceId] = useState(
    useSelector((state) => state.devices.selectedId) || 0
  );
  const [readingMetric, setReadingMetric] = useState("mV");
  const [slope, setSlope] = useState(0);
  const [constant, setConstant] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(() => selectedDeviceId != 0);
  const handleModalClose = () => setModalOpen(false);
  const handleCSVUpload = (file) => {
    console.log(file[0]);
    Papa.parse(file[0], {
      complete: function (parsedData) {
        console.log(parsedData.data);
        const newCalibrations = new Array();
        var skipFirst = true;
        parsedData.data.map((data) => {
          if (!skipFirst) {
            if (!Number.isNaN(data[0]) || !Number.isNaN(data[1])) {
              var calibration = {
                id: 0,
                voltage: parseFloat(data[0]),
                fuelLevel: parseFloat(data[1]),
              };
            } else {
              throw Error(
                "Your CSV File has an error. Only header fields can be strings!"
              );
            }

            newCalibrations.push(calibration);
            return;
          }
          skipFirst = false;
        });
        importCalibrations(
          newCalibrations,
          selectedDeviceId,
          dispatch,
          handleModalClose
        );
      },
    });
  };

  useEffect(() => {
    refreshCalibrations(dispatch, selectedDeviceId);
  }, [selectedDeviceId]);

  const columns = [
    {
      field: "id",
      title: "ID",
      editable: "never",
      sorting: false,
      export: false,
    },
    {
      field: "voltage",
      title: `Voltage${readingMetric}`,
      type: "numeric",
      width: 40,
      sorting: true,
      align: "center",
    },
    {
      field: "fuelLevel",
      title: "Fuel Level",
      type: "numeric",
      width: 40,
      sorting: false,
      align: "center",
    },
  ];

  return (
    <div>
      <PageLayout
        menu={<SettingsMenu />}
        breadcrumbs={["settingsTitle", "sharedCalibrations"]}
      >
        <div className={classes.filter}>
          <Box sx={{ width: 1 }}>
            <FormControl fullWidth>
              <Autocomplete
                id="device-autocomplete"
                disablePortal
                options={Object.values(devices)}
                key={(option) => option.id}
                getOptionLabel={(option) => option.name || option.contact}
                onChange={(e, selectedDevice) => {
                  setSelectedDeviceId(selectedDevice.id || 0);
                  setReadingMetric(() => {
                    const device = devices[selectedDevice.id];
                    if (device && sensors[device.fuelSensorId]) {
                      let sensor = sensors[device.fuelSensorId];
                      const readingMetric =
                        readingTypes[sensor.readingTypeId].metricSymbol;
                      return ` (${readingMetric})`;
                    }
                    return "";
                  });
                  setSlope(
                    devices[selectedDevice.id] &&
                      (devices[selectedDevice.id].fuelSlope || 0)
                  );
                  setConstant(
                    devices[selectedDevice.id] &&
                      (devices[selectedDevice.id].fuelConstant || 0)
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("sharedSearchDevices")} />
                )}
              />
            </FormControl>
          </Box>

          <Button
            variant={"outlined"}
            startIcon={<AttachFile />}
            onClick={handleModalOpen}
          >
            {t("sharedReadFromCSVFile")}
          </Button>
          {/* {
            (devices[selectedDeviceId] && devices[selectedDeviceId].fuelSensorId && sensors[devices[selectedDeviceId].fuelSensorId].calibrated) && (

            );
          } */}
          <Chip
            color={slope != 0 ? "info" : "default"}
            avatar={<Avatar title="Fuel Slope">M</Avatar>}
            label={slope}
          />
          <Chip
            color={constant != 0 ? "info" : "default"}
            avatar={<Avatar title="Fuel Constant">C</Avatar>}
            label={constant}
          />
        </div>
        <Box sx={{ m: 2 }}>
          {
            /* ((devices[selectedDeviceId]
              && devices[selectedDeviceId].fuelSensorId)
              && ( */
            <MaterialTable
              style={{
                padding: 8,
              }}
              icons={tableIcons}
              title={t("sharedCalibrations")}
              editable={{
                onBulkUpdate: (changes) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      console.log(changes);
                      resolve();
                    }, 1000);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    const calibration = {
                      deviceId: selectedDeviceId,
                      voltage: newData.voltage,
                      fuelLevel: newData.fuelLevel,
                      attributes: {},
                    };
                    try {
                      saveCalibrationChange(calibration, dispatch, false);
                    } catch (e) {
                      console.log(e);
                      reject();
                    }
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    const calibration = calibrations[oldData.id];
                    console.log(oldData);
                    try {
                      saveCalibrationChange(calibration, dispatch, true);
                    } catch (error) {
                      console.log(error);
                      reject();
                    }
                    resolve();
                  }),
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                maxBodyHeight: 500,
              }}
              columns={columns}
              data={Object.values(
                Object.values(calibrations).map((o) => ({ ...o })) || []
              )}
            />
            /* ) || (
                <Box sx={{ height: '100%', minHeight: '400' }}>
                  <Skeleton variant="rectangular" height={400} />
                </Box>
              )) */
          }
        </Box>
      </PageLayout>

      <DropzoneDialog
        open={modalOpen}
        acceptedFiles={["text/csv"]}
        filesLimit={1}
        showPreviews={true}
        submitButtonText={t("sharedUpload")}
        onSave={handleCSVUpload}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default FuelCalibrationsPage;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "./components/EditItemView";
import EditAttributesView from "./components/EditAttributesView";
import { useTranslation } from "../common/components/LocalizationProvider";
import SettingsMenu from "./components/SettingsMenu";
import SelectField from "../common/components/SelectField";
import { portNameFormatter } from "../common/util/formatter";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const FuelSensorPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.name &&
    item.description &&
    item.readingTypeId &&
    item.fuelLevelPort

  return (
    <EditItemView
      endpoint="fuel-sensors"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={["settingsTitle", "sharedDriver"]}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                label={t("sharedName")}
              />
              <TextField
                value={item.description || ""}
                onChange={(event) =>
                  setItem({ ...item, description: event.target.value })
                }
                label={t("sharedDescription")}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedSensors")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <SelectField
                value={item.readingTypeId || 0}
                onChange={(event) =>
                  setItem({
                    ...item,
                    readingTypeId: Number(event.target.value),
                  })
                }
                endpoint="/api/fuel-sensors/readings"
                label={t("sharedReadingType")}
                titleGetter={(readingType) => `${readingType.metricSymbol}`}
              />
              <SelectField
                value={item.fuelConsumedPort || ""}
                onChange={(event) =>
                  setItem({
                    ...item,
                    fuelConsumedPort: event.target.value,
                  })
                }
                endpoint="/api/fuel-sensors/ports"
                label={t("sharedFuelConsumedPort")}
                keyGetter={(port) => port.portName}
                titleGetter={(port) => portNameFormatter(port)}
              />
              <SelectField
                value={item.fuelLevelPort || ""}
                onChange={(event) => {
                  setItem({
                    ...item,
                    fuelLevelPort: event.target.value,
                  });
                }}
                endpoint="/api/fuel-sensors/ports"
                label={t("sharedFuelLevelPort")}
                keyGetter={(port) => port.portName}
                titleGetter={(port) => portNameFormatter(port)}
              />
              <SelectField
                value={item.fuelRatePort || ""}
                onChange={(event) =>
                  setItem({
                    ...item,
                    fuelRatePort: event.target.value,
                  })
                }
                endpoint="/api/fuel-sensors/ports"
                label={t("sharedFuelRatePort")}
                keyGetter={(port) => port.portName}
                titleGetter={(port) => portNameFormatter(port)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.calibrated}
                    onChange={(event) =>
                      setItem({ ...item, calibrated: event.target.checked })
                    }
                  />
                }
                label={`${t("sensorMustCalibrate")}?`}

              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t("sharedAttributes")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <EditAttributesView
                attributes={item.attributes}
                setAttributes={(attributes) => setItem({ ...item, attributes })}
                definitions={{}}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default FuelSensorPage;

import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, TextField, FormControlLabel, Checkbox,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { portNameFormatter, sensorNameFormatter } from '../../common/util/formatter';
import SelectField from '../../common/components/SelectField';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
}));

const AddSensorDialog = ({ open, onResult, sensorCount }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [name, setName] = useState(null);
  const [typeId, setTypeId] = useState(0);
  const [readingTypeId, setReadingTypeId] = useState(0);
  const [groupNo, setGroupNo] = useState(0);
  const [isCalibrated, setIsCalibrated] = useState(false);
  const [fuelPort, setFuelPort] = useState('');
  const [canSave, setCanSave] = useState(false);

  const valid = () => (
    name
    && name.trim().length > 3
    && typeId > 0
    && readingTypeId > 0
    && groupNo >= 0
    && fuelPort
    && fuelPort.trim().length > 0
  );

  useEffect(() => setName(`Fuel Sensor #${sensorCount + 1}`), [sensorCount]);
  
  useEffect(() => {
    if (valid()) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [name, typeId, readingTypeId, groupNo, isCalibrated, fuelPort]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogContent className={classes.details}>
        <TextField
          value={name}
          onChange={(event) => setName(event.target.value)}
          label={t('sharedName')}
        />
        <SelectField
          value={typeId || 0}
          onChange={(event) =>
            setTypeId(Number(event.target.value))
          }
          endpoint="/api/fuel-sensors"
          label={t("sharedSensor")}
          titleGetter={(sensor) => sensorNameFormatter(sensor)}
        />
        <SelectField
          value={readingTypeId || 0}
          onChange={(event) =>
            setReadingTypeId(Number(event.target.value))
          }
          endpoint="/api/fuel-sensors/readings"
          label={t("sharedReadingType")}
          titleGetter={(readingType) => `${readingType.metricSymbol}`}
        />
        <TextField
          value={groupNo || 0}
          onChange={(event) => setGroupNo(Number(event.target.value))}
          label={t('sharedFuelTankNumber')}
        />
        <SelectField
          value={fuelPort || ""}
          onChange={(event) => setFuelPort(event.target.value)}
          endpoint="/api/fuel-sensors/ports"
          label={t("sharedFuelLevelPort")}
          keyGetter={(port) => port.portName}
          titleGetter={(port) => portNameFormatter(port)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCalibrated}
              onChange={(event) => setIsCalibrated(event.target.checked)} />}
          label={t('sensorMustCalibrate')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!canSave}
          onClick={() => onResult({ name, typeId, readingTypeId: readingTypeId, isCalibrated, groupNo: groupNo, fuelPort })}
        >
          {t('sharedAdd')}
        </Button>
        <Button
          autoFocus
          onClick={() => onResult(null, true)}
        >
          {t('sharedCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSensorDialog;

export default {
  inputHeight: "42px",
  borderRadius: "4px",
  sidebarWidth: "20%",
  sidebarWidthTablet: "52px",
  drawerWidthCustom: "420px",
  drawerWidthDesktop: "720px",
  drawerWidthTablet: "320px",
  drawerHeightPhone: "250px",
  filterFormWidth: "160px",
  eventsDrawerWidth: "320px",
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: 500,
  popupMinWidth: 360,
  // popupMaxWidth: 272,
  // popupMaxWidth: 800,
  popupImageHeight: 144,
  drawerWidth: 240,
};

import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const DeleteSensorDialog = ({ name, index, open, setOpen, onAccept }) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogTitle id="alert-dialog-title">
                {`Delete Sensor ${name} ?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    After deleting this sensor, you won't be able to recover any information associated with this sensor?
                    Do you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={() => setOpen(false)}>No</Button>
                <Button color='error' onClick={() => onAccept(index)} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteSensorDialog;
import { grey } from "@mui/material/colors";

export default {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeMedium: {
        height: "35px",
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: "small",
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      anchorOrigin: {
        vertical: "center",
        horizontal: "center",
      },
    },
  },
  MuiCard: {
    defaultProps: {
      classes: "rounded outlined elevation3",
    },
    styleOverrides: {
      root: {
        borderRadius: 5,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        transition: "background-color .2s",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
      head: {
        textTransform: "uppercase",
        fontSize: 13,
        fontWeight: "bold",
      },
    },
  },
};
